// Global annotations
////
/// @group journeys
/// @access private
////

$instrument-icon-background: $color-on-background-neutral !default;
$instrument-icon-font-weight: $font-weight-bold !default;

$instrument-icon-size-sm-width: $sizer-xxl !default;
$instrument-icon-size-sm-height: $sizer-xxl !default;
$instrument-icon-size-sm-line-height: $font-size-highlight !default;

$instrument-icon-size-md-width: $sizer * 3 !default;
$instrument-icon-size-md-height: $sizer * 3 !default;

$instrument-icon-size-lg-width: $sizer * 4 !default;
$instrument-icon-size-lg-height: $sizer * 4 !default;
$instrument-icon-size-lg-line-height: $sizer-lg !default;

$instrument-icon-size-xl-width: $sizer * 5 !default;
$instrument-icon-size-xl-height: $sizer * 5 !default;

.bb-instrument-icon {
  padding: 12px 2px 10px 2px;
  background: $color-background-brand-subtle;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.06);
  border-radius: $border-radius-sm;
  line-height: $font-size-base;
  font-weight: $instrument-icon-font-weight;
  color: $color-on-background-brand-subtle;
}

$bb-instrument-icon-size-map: (
  sm: (
    font-size: $font-size-base * 0.75,
    width: $instrument-icon-size-sm-width,
    height: $instrument-icon-size-sm-height,
    line-height: $instrument-icon-size-sm-line-height,
  ),
  md: (
    font-size: $font-size-base,
    width: $instrument-icon-size-md-width,
    height: $instrument-icon-size-md-height,
    line-height: 21px,
  ),
  lg: (
    font-size: $font-size-base,
    width: $instrument-icon-size-lg-width,
    height: $instrument-icon-size-lg-height,
    line-height: $instrument-icon-size-lg-line-height,
  ),

  xl: (
    font-size: $font-size-base,
    width: $instrument-icon-size-xl-width,
    height: $instrument-icon-size-xl-height,
    line-height: 27px,
  ),
);

@each $size, $map in $bb-instrument-icon-size-map {
  .bb-instrument-icon--#{$size} {
    width: map-get($map, "width");
    height: map-get($map, "height");
    font-size: map-get($map, "font-size");
    line-height: map-get($map, "line-height");
  }
}
