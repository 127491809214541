@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

:root {
  $theme-colors: theme-colors();

  @each $name, $value in $theme-colors {
    --color-#{str-replace($name, '/', '-')}: #{$value};
  }
}
