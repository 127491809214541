////
/// Colors (global)
/// @group 1-variables/semantic/color/border
/// @access public
////

/* stylelint-disable color-no-hex */

/// figma equivalent border/default
$color-border-default: color-from-semantic("border/default") !default;

/// figma equivalent border/subtle
$color-border-subtle: color-from-semantic("border/subtle") !default;

/// figma equivalent border/brand
$color-border-brand: color-from-semantic("border/brand") !default;

/// figma equivalent border/info
$color-border-info: color-from-semantic("border/info") !default;

/// figma equivalent border/success
$color-border-success: color-from-semantic("border/success") !default;

/// figma equivalent border/warning
$color-border-warning: color-from-semantic("border/warning") !default;

/// figma equivalent border/danger
$color-border-danger: color-from-semantic("border/danger") !default;

/// figma equivalent border/on-color
$color-border-on-color: color-from-semantic("border/on-color") !default;

/// figma equivalent border/none
$color-border-none: color-from-semantic("border/none") !default;
