// Global annotations
////
/// @group widgets
/// @access private
////

.bb-instrument-base-font {
  font-size: $font-size-default;
  line-height: $line-height-base;
  color: $color-foreground-default;
}

.bb-text-color-foreground-support {
  color: $color-foreground-support;
}

.bb-ins-text-semi-bold {
  font-weight: $font-weight-semi-bold;
}

.bb-ins-md-font {
  font-size: $font-size-subtitle;
  line-height: $line-height-base;
}

.bb-ins-small-font span.badge {
  font-size: $font-size-subheader;
  line-height: $line-height-base;
  padding: 0.125rem $spacer-sm;
  color: $color-foreground-default;
}
