// Global annotations
////
/// @group navigation
/// @access private
////

$nav-tabs-link-color: $color-foreground-brand !default;
$nav-tabs-link-line-height: $sizer-lg !default;
$nav-tabs-link-border-radius: $sizer-xs * 0.5 !default;
$nav-tabs-link-hover-bg: $color-background-none !default;
$nav-tabs-link-focus-width: 3px !default;
$nav-tabs-link-focus-color: rgba($color-focus-outline, 0.2) !default;
$nav-tabs-link-active-border-bottom-height: 4px !default;
$nav-tabs-link-focus-border-bottom-height: 6px !default;
$nav-tabs-link-active-font-weight: $font-weight-semi-bold !default;
$nav-tabs-link-focus-border-primary: 3px solid $color-focus-outline !default;
$nav-tabs-link-active-hover-border-color: $color-background-brand-hovered !default;

@import "bootstrap/scss/nav";

.nav-tabs {
  flex-wrap: nowrap;
  align-items: stretch;
  overflow-x: auto;
  overflow-y: hidden;

  .nav-link {
    position: relative;
    height: 100%;
    padding: ($sizer-sm + $sizer-xs) $sizer-lg $sizer-sm $sizer-lg;
    color: $nav-tabs-link-color;
    line-height: $nav-tabs-link-line-height;
    text-align: center;
    border: 0;
    border-radius: 0 0 $nav-tabs-link-border-radius;

    background-color: $color-background-none;

    // rounded tab underline
    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: $nav-tabs-link-active-border-bottom-height;
      border-radius: $nav-tabs-link-border-radius;
      content: "";
    }

    &:focus {
      outline: 0;

      &:before {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: $nav-tabs-link-border-radius;
        border: $nav-tabs-link-focus-border-primary;
        content: "";
      }

      &:after {
        height: $nav-tabs-link-focus-border-bottom-height;
      }
    }
    //removed box-shadow as it adds along with normal focus
    &:focus-visible {
      box-shadow: none;
    }

    &:hover {
      &:after {
        background-color: $nav-tabs-link-hover-border-color;
      }
    }

    &:disabled {
      opacity: 1;
      color: $nav-link-disabled-color;
    }
  }

  .nav-link.active {
    font-weight: $nav-tabs-link-active-font-weight;

    &:after {
      background-color: $nav-tabs-link-active-border-color;
    }

    &:hover {
      background-color: $nav-tabs-link-hover-bg;
      &:after {
        background-color: $nav-tabs-link-active-hover-border-color;
      }
    }
  }

  .nav-item {
    margin-block-start: $spacer-xs;
  }
}
