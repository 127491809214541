.loan-offer-main-info__image {
  height: 180px;
  border-radius: $border-radius-sm;
  object-fit: cover;

  @include media-breakpoint-only(sm) {
    height: 160px;
  }

  @include media-breakpoint-only(xs) {
    height: 130px;
  }
}
