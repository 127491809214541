:root {
  --opacity-ease: #{$opacity-ease};
  --opacity-ease-appears: #{$opacity-ease-appears};
  --opacity-ease-leaves: #{$opacity-ease-leaves};

  --morphing-ease: #{$morphing-ease};
  --morphing-ease-appears: #{$morphing-ease-appears};
  --morphing-ease-leaves: #{$morphing-ease-leaves};

  --translation-ease: #{$translation-ease};
  --translation-ease-appears: #{$translation-ease-appears};
  --translation-ease-leaves: #{$translation-ease-leaves};

  --duration-short: #{$motion-duration-short};
  --duration-medium: #{$motion-duration-medium};
  --duration-long: #{$motion-duration-long};

  --fade-start: #{$motion-fade-start};
  --scale-start: #{$motion-scale-start};
  --short-translation-offset: #{$short-translation-offset};
  --long-translation-offset: #{$long-translation-offset};

  --duration-set-short-opacity: #{$duration-set-short-opacity};
  --duration-set-short-morphing: #{$duration-set-short-morphing};
  --duration-set-short-translation: #{$duration-set-short-translation};

  --duration-set-long-opacity: #{$duration-set-long-opacity};
  --duration-set-long-morphing: #{$duration-set-long-morphing};
  --duration-set-long-translation: #{$duration-set-long-translation};

  --duration-transition-complement: #{$duration-transition-complement};

  --nested-delay: #{$nested-delay};
  --root-delay: #{$root-delay};
  --fadeout-delay-long: #{$fadeout-delay-long};
  --fadeout-delay-short: #{$fadeout-delay-short};
}
