////
/// Colors (global)
/// @group 1-variables/semantic/color/theme/dark
/// @access private
////

/* stylelint-disable color-no-hex */

$semantic-colors-dark: (
  "background/none": color-from-primitive("dark/neutral/transparent"),
  "background/page": color-from-primitive("dark/neutral/darkest"),
  "background/surface-1": color-from-primitive("dark/neutral/dark"),
  "background/surface-1-hovered": color-from-primitive("dark/neutral/greyer"),
  "background/surface-1-pressed": color-from-primitive("dark/neutral/grey"),
  "background/surface-2": color-from-primitive("dark/neutral/greyest"),
  "background/surface-2-hovered": color-from-primitive("dark/neutral/greyer"),
  "background/surface-2-pressed": color-from-primitive("dark/neutral/grey"),
  "background/brand-subtle": color-from-primitive("dark/primary/darkest"),
  "background/brand": color-from-primitive("dark/primary/default"),
  "background/brand-pressed": color-from-primitive("dark/primary/darkest"),
  "background/brand-hovered": color-from-primitive("dark/primary/darker"),
  "background/neutral-subtle": color-from-primitive("dark/neutral/grey"),
  "background/neutral": color-from-primitive("dark/neutral/light"),
  "background/neutral-pressed": color-from-primitive("dark/neutral/greyer"),
  "background/neutral-hovered": color-from-primitive("dark/neutral/grey"),
  "background/info-subtle": color-from-primitive("dark/info/darkest"),
  "background/info": color-from-primitive("dark/info/default"),
  "background/info-pressed": color-from-primitive("dark/info/darkest"),
  "background/info-hovered": color-from-primitive("dark/info/darker"),
  "background/success-subtle": color-from-primitive("dark/success/darkest"),
  "background/success": color-from-primitive("dark/success/default"),
  "background/success-pressed": color-from-primitive("dark/success/darkest"),
  "background/success-hovered": color-from-primitive("dark/success/darker"),
  "background/warning-subtle": color-from-primitive("dark/warning/darkest"),
  "background/warning": color-from-primitive("dark/warning/default"),
  "background/warning-pressed": color-from-primitive("dark/warning/darkest"),
  "background/warning-hovered": color-from-primitive("dark/warning/darker"),
  "background/danger-subtle": color-from-primitive("dark/danger/darkest"),
  "background/danger": color-from-primitive("dark/danger/default"),
  "background/danger-pressed": color-from-primitive("dark/danger/darkest"),
  "background/danger-hovered": color-from-primitive("dark/danger/darker"),
  "background/category-1-subtle": color-from-primitive("dark/red/darkest"),
  "background/category-1": color-from-primitive("dark/red/default"),
  "background/category-2-subtle": color-from-primitive("dark/orange/darkest"),
  "background/category-2": color-from-primitive("dark/orange/default"),
  "background/category-3-subtle": color-from-primitive("dark/pink/darkest"),
  "background/category-3": color-from-primitive("dark/pink/default"),
  "background/category-4-subtle": color-from-primitive("dark/blue/darkest"),
  "background/category-4": color-from-primitive("dark/blue/default"),
  "background/category-5-subtle": color-from-primitive("dark/purple/darkest"),
  "background/category-5": color-from-primitive("dark/purple/default"),
  "background/category-6-subtle": color-from-primitive("dark/turquoise/darkest"),
  "background/category-6": color-from-primitive("dark/turquoise/default"),
  "background/category-7-subtle": color-from-primitive("dark/green/darkest"),
  "background/category-7": color-from-primitive("dark/green/default"),
  "background/category-8-subtle": color-from-primitive("dark/lime/darkest"),
  "background/category-8": color-from-primitive("dark/lime/default"),
  "background/category-9-subtle": color-from-primitive("dark/yellow/darkest"),
  "background/category-9": color-from-primitive("dark/yellow/default"),
  "background/category-10-subtle": color-from-primitive("dark/grey/darkest"),
  "background/category-10": color-from-primitive("dark/grey/default"),
  "background/overlay": color-from-primitive("dark/white-alpha/10"),
  "background/overlay-pressed": color-from-primitive("dark/white-alpha/30"),
  "background/overlay-hovered": color-from-primitive("dark/white-alpha/20"),
  "background/on-color": color-from-primitive("dark/white-alpha/100"),
  "background/on-color-pressed": color-from-primitive("dark/white-alpha/50"),
  "background/on-color-hovered": color-from-primitive("dark/white-alpha/70"),
  "background/inverted": color-from-primitive("dark/neutral/lightest"),
  "background/selected": color-from-primitive("dark/primary/darkest"),
  "background/disabled": color-from-primitive("dark/neutral/greyer"),
  "background/disabled-on-color": color-from-primitive("dark/white-alpha/30"),
  "on-background/brand-subtle": color-from-primitive("dark/primary/lightest"),
  "on-background/brand": color-from-primitive("dark/primary/on-color"),
  "on-background/neutral-subtle": color-from-primitive("dark/neutral/white"),
  "on-background/neutral": color-from-primitive("dark/neutral/white"),
  "on-background/info-subtle": color-from-primitive("dark/info/lightest"),
  "on-background/info": color-from-primitive("dark/info/on-color"),
  "on-background/success-subtle": color-from-primitive("dark/success/lightest"),
  "on-background/success": color-from-primitive("dark/success/on-color"),
  "on-background/warning-subtle": color-from-primitive("dark/warning/lightest"),
  "on-background/warning": color-from-primitive("dark/warning/on-color"),
  "on-background/danger-subtle": color-from-primitive("dark/danger/lightest"),
  "on-background/danger": color-from-primitive("dark/danger/on-color"),
  "on-background/category-1-subtle": color-from-primitive("dark/red/lightest"),
  "on-background/category-1": color-from-primitive("dark/red/on-color"),
  "on-background/category-2-subtle": color-from-primitive("dark/orange/lightest"),
  "on-background/category-2": color-from-primitive("dark/orange/on-color"),
  "on-background/category-3-subtle": color-from-primitive("dark/pink/lightest"),
  "on-background/category-3": color-from-primitive("dark/pink/on-color"),
  "on-background/category-4-subtle": color-from-primitive("dark/blue/lightest"),
  "on-background/category-4": color-from-primitive("dark/blue/on-color"),
  "on-background/category-5-subtle": color-from-primitive("dark/purple/lightest"),
  "on-background/category-5": color-from-primitive("dark/purple/on-color"),
  "on-background/category-6-subtle": color-from-primitive("dark/turquoise/lightest"),
  "on-background/category-6": color-from-primitive("dark/turquoise/on-color"),
  "on-background/category-7-subtle": color-from-primitive("dark/green/lightest"),
  "on-background/category-7": color-from-primitive("dark/green/on-color"),
  "on-background/category-8-subtle": color-from-primitive("dark/lime/lightest"),
  "on-background/category-8": color-from-primitive("dark/lime/on-color"),
  "on-background/category-9-subtle": color-from-primitive("dark/yellow/lightest"),
  "on-background/category-9": color-from-primitive("dark/yellow/on-color"),
  "on-background/category-10-subtle": color-from-primitive("dark/grey/lightest"),
  "on-background/category-10": color-from-primitive("dark/grey/on-color"),
  "on-background/on-color": color-from-primitive("dark/neutral/black"),
  "on-background/inverted": color-from-primitive("dark/neutral/black"),
  "foreground/default": color-from-primitive("dark/neutral/white"),
  "foreground/support": color-from-primitive("dark/neutral/lighter"),
  "foreground/disabled": color-from-primitive("dark/neutral/light"),
  "foreground/disabled-on-color": color-from-primitive("dark/white-alpha/70"),
  "foreground/brand": color-from-primitive("dark/primary/default"),
  "foreground/info": color-from-primitive("dark/info/default"),
  "foreground/success": color-from-primitive("dark/success/default"),
  "foreground/warning": color-from-primitive("dark/warning/default"),
  "foreground/danger": color-from-primitive("dark/danger/default"),
  "foreground/on-color": color-from-primitive("dark/neutral/white"),
  "border/default": color-from-primitive("dark/neutral/light"),
  "border/subtle": color-from-primitive("dark/neutral/grey"),
  "border/brand": color-from-primitive("dark/primary/default"),
  "border/info": color-from-primitive("dark/info/default"),
  "border/success": color-from-primitive("dark/success/default"),
  "border/warning": color-from-primitive("dark/warning/default"),
  "border/danger": color-from-primitive("dark/danger/default"),
  "border/none": color-from-primitive("dark/neutral/transparent"),
  "foreground/category-1": color-from-primitive("dark/red/lighter"),
  "foreground/category-2": color-from-primitive("dark/orange/lighter"),
  "foreground/category-3": color-from-primitive("dark/pink/lighter"),
  "foreground/category-4": color-from-primitive("dark/blue/lighter"),
  "foreground/category-5": color-from-primitive("dark/purple/lighter"),
  "foreground/category-6": color-from-primitive("dark/turquoise/lighter"),
  "foreground/category-7": color-from-primitive("dark/green/lighter"),
  "foreground/category-8": color-from-primitive("dark/lime/lighter"),
  "foreground/category-9": color-from-primitive("dark/yellow/lighter"),
  "foreground/category-10": color-from-primitive("dark/grey/lighter"),
  "border/on-color": color-from-primitive("dark/neutral/white"),
  "link/default": color-from-primitive("dark/primary/default"),
  "link/hovered": color-from-primitive("dark/primary/darker"),
  "link/pressed": color-from-primitive("dark/primary/darkest"),
  "link/disabled": color-from-primitive("dark/neutral/light"),
  "link/on-color/default": color-from-primitive("dark/neutral/white"),
  "link/on-color/hovered": color-from-primitive("dark/neutral/white"),
  "link/on-color/pressed": color-from-primitive("dark/neutral/white"),
  "link/on-color/disabled": color-from-primitive("dark/white-alpha/60"),
  "focus/outline": color-from-primitive("dark/primary/default"),
  "focus/on-color/outline": color-from-primitive("dark/neutral/white"),
);
