.header {
  position: fixed;
  z-index: $zindex-sticky;

  width: 100%;
  padding: calc($sizer * 2.4375) $sizer-lg;
  background-color: #081c35;

  &__logo-wrapper {
    width: 300px;

    display: flex;
    align-items: center;
  }

  &__logo {
    cursor: pointer;
    width: 10.237rem;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__logo-and-menu {
    width: $responsive-xl;

    display: flex;
    justify-content: space-between;
  }

  &__toggle-menu {
    color: $color-neutral-white;
  }
}
