// Global annotations
////
/// @group structure
/// @access private
////

@import "bootstrap/scss/tooltip";

.tooltip-inner {
  box-shadow: $box-shadow-sm;
}
