// Global annotations
////
/// @group widgets
/// @access private
////

:root {
  --bb-carousel-offset: 0;
  --bb-cursor-value: grab;
}

.bb-carousel__header {
  padding-inline-end: $spacer-md;
}

.bb-carousel__title {
  line-height: $line-height-base;
  margin-block-end: 0;
}

.bb-carousel__actions {
  margin-inline-start: $spacer-md;
}

.bb-carousel__container {
  width: 100%;
  overflow-x: hidden;
}

.bb-carousel__items {
  display: flex;
  flex-wrap: nowrap;
  width: fit-content;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  padding: $spacer-md 0 $spacer-xl;
}

.bb-carousel__item {
  flex-basis: 100%;
  cursor: var(--bb-cursor-value, grab);
  margin-inline-end: var(--bb-carousel-offset, 50px);
  user-select: none;
}

.bb-carousel [data-role^="carousel-btn-action-"] {
  &.btn-link-text-light {
    color: $color-foreground-default;
  }

  &:disabled {
    color: $color-foreground-disabled;
  }
}

.bb-carousel__state-card {
  margin: $spacer-md 0 $spacer-xl;
}
