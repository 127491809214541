// Global annotations
////
/// @group journey-specific
/// @access public
////

.bb-ac-fullscreen-modal-backdrop {
  background-color: $modal-backdrop-bg;
  opacity: $modal-backdrop-opacity !important;
}

ac-fullscreen-container {
  display: flex;
  flex-direction: column;
  background-color: $color-background-page;
  border-radius: $border-radius-sm;
  overflow: hidden;
  height: 100%;
}

.bb-ac-fullscreen-container-header {
  box-shadow: $box-shadow-md;
  background-color: $color-background-surface-1;
  min-height: 3rem;
  box-sizing: content-box;

  button {
    margin-left: auto;
  }
}

.bb-ac-fullscreen-container-body {
  overflow-y: auto;
}

.bb-ac-fullscreen-container-footer {
  background-color: $color-background-surface-1;
  border-top: 1px solid $color-border-subtle;
}

.bb-ac-fullscreen-container-close-confirmation {
  background-color: $color-background-selected;
  border-top: 1px solid $color-border-subtle;
}
