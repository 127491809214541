// Global annotations
////
/// @group widgets
/// @access private
////

/// Styles for Approval Log List Widget
///
/// @name bb-approval-log-list-widget
///
/// @example html - Load Button
/// <div class="bb-block bb-block--md bb-approval-log-list-footer">
///   <bb-load-button-ui
///     color="link"
///    Load more...
///   </bb-load-button-ui>
/// </div>

.bb-approval-log-list-table {
  padding: 0 $sizer-lg;
}

.bb-approval-log-list-footer {
  margin: 0 auto;
  text-align: center;
}
