$cclaCapabilityViewContainerWidth: 568px;

bb-ccla-capability-view {
  display: block;

  .bb-ccla-view {
    .bb-ccla-view-content-width {
      width: $cclaCapabilityViewContainerWidth;
    }

    @include media-breakpoint-only(xs) {
      &__button-bar {
        padding-top: $sizer-xl;
        padding-bottom: $sizer-md;
      }
    }
  }
}
