////
/// @group 1-variables/brand-extended/border
/// @access public
////

/// Global border color
/// @require {variable} $color-border-subtle
$border-color: $color-border-subtle !default;

/// Global border (read-only)
/// @require {variable} $border-width
/// @require {variable} $border-style
/// @require {variable} $border-color
$border: $border-width $border-style $border-color;
