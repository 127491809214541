// Global annotations
////
/// @group journeys
/// @access private
////
$sticky-footer-content-max-width: 840px !default;

$sticky-footer-content-breakpoint-min-width: 992px !default;
$sticky-footer-content-breakpoint-max-width: 1200px !default;
$sticky-footer-wrapper-breakpoint-max-width: 557px !default;

.bb-order-initiation__sticky-footer-wrapper {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: $color-background-page;
  border-top: $border-width $border-style $color-background-neutral-subtle;
  padding: $sizer * 1.75 $sizer-xl;
}

.bb-order-initiation__sticky-footer-content {
  margin: auto;
  max-width: $sticky-footer-content-max-width;
}

@media (min-width: $sticky-footer-content-breakpoint-min-width) and (max-width: $sticky-footer-content-breakpoint-max-width) {
  .bb-order-initiation__sticky-footer-content {
    max-width: calc($sticky-footer-content-max-width + $spacer-xl);
  }
}

@media (max-width: $sticky-footer-wrapper-breakpoint-max-width) {
  .bb-order-initiation__sticky-footer-wrapper {
    padding: $sizer $sizer-sm;
  }
}
