// Global annotations
////
/// @group journey-specific
/// @access private
////
$direct-debit-modal-content-max-width: 900px !default;

@include media-breakpoint-down(md) {
  .direct-debit__dropdown-max-size {
    max-height: 40vh;
    max-width: 90vw;
  }
}

.direct-debit-modal-content {
  max-width: $direct-debit-modal-content-max-width;
  margin: 0 auto;
}
