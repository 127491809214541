// Global annotations
////
/// @group banking
/// @access private
////

/// Greys out a payment card and displays an icon on top of it, indicating its status.
///
/// @name bb-payment-card-state
///
/// @example html
///   <div class="bb-payment-card-state">
///     <div class="bb-payment-card-state__container">
///       <i aria-hidden="true" class="bb-payment-card-state__icon bb-icon bb-icon-not-interested bb-icon--xxl bb-icon--white"></i>
///       <span class="bb-payment-card-state__label badge">Deactivated</span>
///     </div>
///     <div class="bb-payment-card-state__overlay">
///       <div class="bb-payment-card">
///         <div class="bb-payment-card__container"></div>
///       </div>
///     </div>
///   </div>
.bb-payment-card-state {
  position: relative;
}

.bb-payment-card-state--sm {
  .bb-payment-card-state__container,
  .bb-payment-card-state__overlay::after {
    min-width: $payment-card-min-width-sm;
    max-width: $payment-card-max-width-sm;
  }
}

.bb-payment-card-state--sm.bb-payment-card-state--vertical {
  .bb-payment-card-state__container,
  .bb-payment-card-state__overlay::after {
    min-width: $payment-card-min-width-sm-vertical;
    max-width: $payment-card-max-width-sm-vertical;
  }
}

.bb-payment-card-state--vertical {
  .bb-payment-card-state__container,
  .bb-payment-card-state__overlay::after {
    min-width: $payment-card-min-width-vertical;
    max-width: $payment-card-max-width-vertical;
  }
}

.bb-payment-card-state__container {
  .bb-icon {
    line-height: initial;
    color: $color-foreground-on-color;
  }
  .badge {
    background-color: $color-background-on-color;
    color: $color-on-background-on-color;
  }
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: $payment-card-min-width;
  max-width: $payment-card-max-width;
  height: 100%;
  color: $color-foreground-on-color;
}

.bb-payment-card-state__overlay::after {
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  display: block;
  width: 100%;
  min-width: $payment-card-min-width;
  max-width: $payment-card-max-width;
  height: 100%;
  background-color: rgba(#000, 0.5);
  border-radius: $payment-card-border-radius;
  content: "";
}

.bb-payment-card-state__icon {
  margin-bottom: $spacer-sm;
}

.bb-payment-card-state__label {
  background-color: $color-background-on-color;
  color: $color-on-background-on-color;
}
