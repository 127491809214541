// Global annotations
////
/// @group journey-specific
/// @access private
////

bb-ds-business-verification-task-view-ang {
  .outcome-label {
    flex-basis: 50%;
  }
}
