// Global annotations
////
/// @group journey-specific
/// @access private
////

$direct-debit-list-container-max-height: 44vh !default;
$direct-debit-collapse-action-width: 40px !default;

.direct-debit-list {
  .bb-infinite-scroll-container {
    max-height: $direct-debit-list-container-max-height;
    overflow-y: auto;
  }
}

.direct-debit-collapse-list__row {
  width: calc(100% - $direct-debit-collapse-action-width);
}

.direct-debit-collapse-list__row-checkboxes {
  width: calc(100% - ($direct-debit-collapse-action-width * 2));
}

.direct-debit-collapse-list__action {
  max-width: $direct-debit-collapse-action-width;
}

.direct-debit-pre-wrap__text {
  white-space: pre-wrap;
}

// Rejection codes filter
.direct-debit-rejection-codes__options-list .dropdown-option::before {
  vertical-align: top;
}
