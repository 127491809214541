// Global annotations
////
/// @group widgets
/// @access private
////

$a2a-accounts-list-item-hover-color: $color-background-surface-1-hovered !default;
$a2a-accounts-list-item-padding: $sizer-lg !default;
$a2a-accounts-list-item-block-end-margin: 0 !default;

.bb-a2a-accounts-list-item {
  margin-block-end: $a2a-accounts-list-item-block-end-margin;
  padding-inline-start: $a2a-accounts-list-item-padding;
  padding-inline-end: $a2a-accounts-list-item-padding;
  padding-block-start: $a2a-accounts-list-item-padding;
  padding-block-end: $a2a-accounts-list-item-padding;
}

.bb-a2a-accounts-list-item:hover {
  background-color: $a2a-accounts-list-item-hover-color;
}

.bb-a2a-accounts-list-item:first-of-type {
  border-top-left-radius: $card-border-radius;
  border-top-right-radius: $card-border-radius;
}

.bb-a2a-accounts-list-item:last-of-type {
  border-bottom-left-radius: $card-border-radius;
  border-bottom-right-radius: $card-border-radius;
}
