// Global annotations
////
/// @group forms
/// @access private
////

$form-control-disabled-color: $input-disabled-color;
$form-control-disabled-placeholder-color: $input-disabled-placeholder-color;

.form-control:disabled {
  color: $input-disabled-color;

  &::-webkit-input-placeholder {
    color: $form-control-disabled-placeholder-color;
  }

  &:-moz-placeholder {
    color: $form-control-disabled-placeholder-color;
  }

  &::-moz-placeholder {
    color: $form-control-disabled-placeholder-color;
  }

  &::-ms-input-placeholder {
    color: $form-control-disabled-placeholder-color;
  }
}

.form-control[readonly] {
  background-color: $color-background-surface-2;
  color: $color-foreground-support;
}
