.bb-credit-score-ranges__value {
  top: -35px;
  background: $color-background-surface-1;
  box-shadow: $box-shadow-sm;
  width: 2.5rem;
}

.bb-credit-score-ranges__value--with-arrow {
  top: -45px;

  &::after {
    content: "";
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid $color-background-surface-1;

    position: absolute;
    bottom: -10px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}

.bb-credit-score-ranges__value--inverted {
  background: $color-background-inverted;
  color: $color-on-background-inverted;

  &::after {
    border-top: 10px solid $color-background-inverted;
  }
}

.bb-credit-score-ranges__color-sections {
  height: 1rem;
  margin-bottom: $spacer-xs;
}

.bb-credit-score-ranges__color-sections--poor {
  background-color: $color-background-category-1;
}

.bb-credit-score-ranges__color-sections--fair {
  background-color: $color-background-category-2;
}

.bb-credit-score-ranges__color-sections--good {
  background-color: $color-background-category-9;
}

.bb-credit-score-ranges__color-sections--very-good {
  background-color: $color-background-category-8;
}

.bb-credit-score-ranges__color-sections--excellent {
  background-color: $color-background-category-7;
}
