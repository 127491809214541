// Global annotations
////
/// @group journey-specific
/// @access public
////

.bb-ac-border-8 {
  border: 0.5rem solid $color-background-surface-1;
}

.bb-ac-background-page {
  background-color: $color-background-page;
}

.bb-ac-margin-start-sm {
  margin-inline-start: $spacer-sm;
}

.bb-ac-margin-end-xs {
  margin-inline-end: $spacer-xs;
}

.bb-ac-margin-end-sm {
  margin-inline-end: $spacer-sm;
}

.bb-ac-margin-end-default {
  margin-inline-end: $spacer;
}
