////
/// Colors (global)
/// @group 1-variables/primitive/color/colors
/// @access private
////

/* stylelint-disable color-no-hex */

$primitive-colors: (
  "light/neutral/transparent": transparent,
  "light/neutral/white": $color-neutral-white,
  "light/neutral/lightest": $color-neutral-lighter,
  "light/neutral/lighter": #ebf0f5,
  "light/neutral/light": $color-neutral-grey-light,
  "light/neutral/grey": $color-neutral-grey,
  "light/neutral/greyer": $color-neutral-greyer,
  "light/neutral/greyest": $color-neutral-greyest,
  "light/neutral/dark": $color-neutral-dark,
  "light/neutral/darker": $color-neutral-darker,
  "light/neutral/darkest": $color-neutral-darkest,
  "light/neutral/black": $color-neutral-black,

  "light/primary/lightest": $color-primary-lightest,
  "light/primary/lighter": $color-primary-lighter,
  "light/primary/default": $color-primary,
  "light/primary/darker": $color-primary-darker,
  "light/primary/darkest": $color-primary-darkest,
  "light/primary/on-color": $color-neutral-white,

  "light/secondary/lightest": $color-secondary-lightest,
  "light/secondary/lighter": $color-secondary-lighter,
  "light/secondary/default": $color-secondary,
  "light/secondary/darker": $color-secondary-darker,
  "light/secondary/darkest": $color-secondary-darkest,
  "light/secondary/on-color": $color-neutral-white,

  "light/accent/lightest": $color-accent-lightest,
  "light/accent/lighter": $color-accent-lighter,
  "light/accent/default": $color-accent,
  "light/accent/darker": $color-accent-darker,
  "light/accent/darkest": $color-accent-darkest,
  "light/accent/on-color": $color-neutral-black,

  "light/info/lightest": $color-info-lightest,
  "light/info/lighter": $color-info-lighter,
  "light/info/default": $color-info,
  "light/info/darker": $color-info-darker,
  "light/info/darkest": $color-info-darkest,
  "light/info/on-color": $color-neutral-white,

  "light/success/lightest": $color-success-lightest,
  "light/success/lighter": $color-success-lighter,
  "light/success/default": $color-success,
  "light/success/darker": $color-success-darker,
  "light/success/darkest": $color-success-darkest,
  "light/success/on-color": $color-neutral-white,

  "light/warning/lightest": $color-warning-lightest,
  "light/warning/lighter": $color-warning-lighter,
  "light/warning/default": $color-warning,
  "light/warning/darker": $color-warning-darker,
  "light/warning/darkest": $color-warning-darkest,
  "light/warning/on-color": $color-neutral-black,

  "light/danger/lightest": $color-danger-lightest,
  "light/danger/lighter": $color-danger-lighter,
  "light/danger/default": $color-danger,
  "light/danger/darker": $color-danger-darker,
  "light/danger/darkest": $color-danger-darkest,
  "light/danger/on-color": $color-neutral-white,

  "light/red/lightest": $chart-color-1-lightest,
  "light/red/lighter": $chart-color-1-lighter,
  "light/red/default": $chart-color-1,
  "light/red/darker": $chart-color-1-darker,
  "light/red/darkest": $chart-color-1-darkest,
  "light/red/on-color": $color-neutral-white,

  "light/orange/lightest": $chart-color-2-lightest,
  "light/orange/lighter": $chart-color-2-lighter,
  "light/orange/default": $chart-color-2,
  "light/orange/darker": $chart-color-2-darker,
  "light/orange/darkest": $chart-color-2-darkest,
  "light/orange/on-color": $color-neutral-white,

  "light/pink/lightest": $chart-color-3-lightest,
  "light/pink/lighter": $chart-color-3-lighter,
  "light/pink/default": $chart-color-3,
  "light/pink/darker": $chart-color-3-darker,
  "light/pink/darkest": $chart-color-3-darkest,
  "light/pink/on-color": $color-neutral-white,

  "light/blue/lightest": $chart-color-4-lightest,
  "light/blue/lighter": $chart-color-4-lighter,
  "light/blue/default": $chart-color-4,
  "light/blue/darker": $chart-color-4-darker,
  "light/blue/darkest": $chart-color-4-darkest,
  "light/blue/on-color": $color-neutral-white,

  "light/purple/lightest": $chart-color-5-lightest,
  "light/purple/lighter": $chart-color-5-lighter,
  "light/purple/default": $chart-color-5,
  "light/purple/darker": $chart-color-5-darker,
  "light/purple/darkest": $chart-color-5-darkest,
  "light/purple/on-color": $color-neutral-white,

  "light/turquoise/lightest": $chart-color-6-lightest,
  "light/turquoise/lighter": $chart-color-6-lighter,
  "light/turquoise/default": $chart-color-6,
  "light/turquoise/darker": $chart-color-6-darker,
  "light/turquoise/darkest": $chart-color-6-darkest,
  "light/turquoise/on-color": $color-neutral-white,

  "light/green/lightest": $chart-color-7-lightest,
  "light/green/lighter": $chart-color-7-lighter,
  "light/green/default": $chart-color-7,
  "light/green/darker": $chart-color-7-darker,
  "light/green/darkest": $chart-color-7-darkest,
  "light/green/on-color": $color-neutral-white,

  "light/lime/lightest": $chart-color-8-lightest,
  "light/lime/lighter": $chart-color-8-lighter,
  "light/lime/default": $chart-color-8,
  "light/lime/darker": $chart-color-8-darker,
  "light/lime/darkest": $chart-color-8-darkest,
  "light/lime/on-color": $color-neutral-white,

  "light/yellow/lightest": $chart-color-9-lightest,
  "light/yellow/lighter": $chart-color-9-lighter,
  "light/yellow/default": $chart-color-9,
  "light/yellow/darker": $chart-color-9-darker,
  "light/yellow/darkest": $chart-color-9-darkest,
  "light/yellow/on-color": $color-neutral-black,

  "light/grey/lightest": $chart-color-10-lightest,
  "light/grey/lighter": $chart-color-10-lighter,
  "light/grey/default": $chart-color-10,
  "light/grey/darker": $chart-color-10-darker,
  "light/grey/darkest": $chart-color-10-darkest,
  "light/grey/on-color": $color-neutral-white,

  "light/white-alpha/10": rgba(255, 255, 255, 0.1),
  "light/white-alpha/20": rgba(255, 255, 255, 0.2),
  "light/white-alpha/30": rgba(255, 255, 255, 0.3),
  "light/white-alpha/40": rgba(255, 255, 255, 0.4),
  "light/white-alpha/50": rgba(255, 255, 255, 0.5),
  "light/white-alpha/60": rgba(255, 255, 255, 0.6),
  "light/white-alpha/70": rgba(255, 255, 255, 0.7),
  "light/white-alpha/80": rgba(255, 255, 255, 0.8),
  "light/white-alpha/90": rgba(255, 255, 255, 0.9),
  "light/white-alpha/100": $color-neutral-white,

  "light/black-alpha/10": rgba(0, 0, 0, 0.1),
  "light/black-alpha/20": rgba(0, 0, 0, 0.2),
  "light/black-alpha/30": rgba(0, 0, 0, 0.3),
  "light/black-alpha/40": rgba(0, 0, 0, 0.4),
  "light/black-alpha/50": rgba(0, 0, 0, 0.5),
  "light/black-alpha/60": rgba(0, 0, 0, 0.6),
  "light/black-alpha/70": rgba(0, 0, 0, 0.7),
  "light/black-alpha/80": rgba(0, 0, 0, 0.8),
  "light/black-alpha/90": rgba(0, 0, 0, 0.9),
  "light/black-alpha/100": $color-neutral-black,

  "dark/neutral/transparent": rgba(255, 255, 255, 0),
  "dark/neutral/white": #ffffff,
  "dark/neutral/lightest": #c4cdd9,
  "dark/neutral/lighter": #a1a8b2,
  "dark/neutral/light": #7e848c,
  "dark/neutral/grey": #676c73,
  "dark/neutral/greyer": #565a60,
  "dark/neutral/greyest": #393c40,
  "dark/neutral/dark": #292b2e,
  "dark/neutral/darker": #202124,
  "dark/neutral/darkest": #18191b,
  "dark/neutral/black": #000000,

  "dark/primary/lightest": #edf1ff,
  "dark/primary/lighter": #aabfff,
  "dark/primary/default": #85a3ff,
  "dark/primary/darker": #5d72b2,
  "dark/primary/darkest": #495a8c,
  "dark/primary/on-color": #000000,

  "dark/secondary/lightest": #dcdddd,
  "dark/secondary/lighter": #5d5e5f,
  "dark/secondary/default": #18191b,
  "dark/secondary/darker": #111213,
  "dark/secondary/darkest": #0d0e0f,
  "dark/secondary/on-color": #ffffff,

  "dark/accent/lightest": #f6ffff,
  "dark/accent/lighter": #d5ffff,
  "dark/accent/default": #c3ffff,
  "dark/accent/darker": #88b2b2,
  "dark/accent/darkest": #6b8c8c,
  "dark/accent/on-color": #000000,

  "dark/info/lightest": #edf1ff,
  "dark/info/lighter": #aabfff,
  "dark/info/default": #85a3ff,
  "dark/info/darker": #5d72b2,
  "dark/info/darkest": #495a8c,
  "dark/info/on-color": #000000,

  "dark/success/lightest": #f0f6ea,
  "dark/success/lighter": #cadeb3,
  "dark/success/default": #9ec374,
  "dark/success/darker": #6f8851,
  "dark/success/darkest": #576b40,
  "dark/success/on-color": #000000,

  "dark/warning/lightest": #fff6e8,
  "dark/warning/lighter": #fed695,
  "dark/warning/default": #fdc468,
  "dark/warning/darker": #b18949,
  "dark/warning/darkest": #8b6c39,
  "dark/warning/on-color": #000000,

  "dark/danger/lightest": #fbe8e4,
  "dark/danger/lighter": #ed9282,
  "dark/danger/default": #e5644d,
  "dark/danger/darker": #a04636,
  "dark/danger/darkest": #7e372a,
  "dark/danger/on-color": #000000,

  "dark/red/lightest": #fbe8e4,
  "dark/red/lighter": #ed9282,
  "dark/red/default": #e5644d,
  "dark/red/darker": #a04636,
  "dark/red/darkest": #7e372a,
  "dark/red/on-color": #000000,

  "dark/orange/lightest": #fff0e6,
  "dark/orange/lighter": #ffba89,
  "dark/orange/default": #ff9c57,
  "dark/orange/darker": #b26d3d,
  "dark/orange/darkest": #8c5630,
  "dark/orange/on-color": #000000,

  "dark/pink/lightest": #fceff5,
  "dark/pink/lighter": #f2b3d2,
  "dark/pink/default": #ec93bf,
  "dark/pink/darker": #a56786,
  "dark/pink/darkest": #825169,
  "dark/pink/on-color": #000000,

  "dark/blue/lightest": #edf1ff,
  "dark/blue/lighter": #aabfff,
  "dark/blue/default": #85a3ff,
  "dark/blue/darker": #5d72b2,
  "dark/blue/darkest": #495a8c,
  "dark/blue/on-color": #000000,

  "dark/purple/lightest": #f0e8fb,
  "dark/purple/lighter": #bb95ec,
  "dark/purple/default": #9e67e4,
  "dark/purple/darker": #6f48a0,
  "dark/purple/darkest": #57397d,
  "dark/purple/on-color": #000000,

  "dark/turquoise/lightest": #e7f8f8,
  "dark/turquoise/lighter": #90dfdf,
  "dark/turquoise/default": #61d1d1,
  "dark/turquoise/darker": #449292,
  "dark/turquoise/darkest": #357373,
  "dark/turquoise/on-color": #000000,

  "dark/green/lightest": #eafaf2,
  "dark/green/lighter": #64d8a2,
  "dark/green/default": #64d8a2,
  "dark/green/darker": #469771,
  "dark/green/darkest": #377759,
  "dark/green/on-color": #000000,

  "dark/lime/lightest": #f1f9e7,
  "dark/lime/lighter": #bde28d,
  "dark/lime/default": #a0d65c,
  "dark/lime/darker": #709640,
  "dark/lime/darkest": #587633,
  "dark/lime/on-color": #000000,

  "dark/yellow/lightest": #fff5e6,
  "dark/yellow/lighter": #f6d056,
  "dark/yellow/default": #f2bc0d,
  "dark/yellow/darker": #b2863d,
  "dark/yellow/darkest": #8c6930,
  "dark/yellow/on-color": #000000,

  "dark/grey/lightest": #e1e4e7,
  "dark/grey/lighter": #75808e,
  "dark/grey/default": #3a495d,
  "dark/grey/darker": #293341,
  "dark/grey/darkest": #202833,
  "dark/grey/on-color": #000000,

  "dark/white-alpha/10": rgba(255, 255, 255, 0.1),
  "dark/white-alpha/20": rgba(255, 255, 255, 0.2),
  "dark/white-alpha/30": rgba(255, 255, 255, 0.3),
  "dark/white-alpha/40": rgba(255, 255, 255, 0.4),
  "dark/white-alpha/50": rgba(255, 255, 255, 0.5),
  "dark/white-alpha/60": rgba(255, 255, 255, 0.6),
  "dark/white-alpha/70": rgba(255, 255, 255, 0.7),
  "dark/white-alpha/80": rgba(255, 255, 255, 0.8),
  "dark/white-alpha/90": rgba(255, 255, 255, 0.9),
  "dark/white-alpha/100": rgba(255, 255, 255, 1),
  "dark/black-alpha/10": rgba(0, 0, 0, 0.1),
  "dark/black-alpha/20": rgba(0, 0, 0, 0.2),
  "dark/black-alpha/30": rgba(0, 0, 0, 0.3),
  "dark/black-alpha/40": rgba(0, 0, 0, 0.4),
  "dark/black-alpha/50": rgba(0, 0, 0, 0.5),
  "dark/black-alpha/60": rgba(0, 0, 0, 0.6),
  "dark/black-alpha/70": rgba(0, 0, 0, 0.7),
  "dark/black-alpha/80": rgba(0, 0, 0, 0.8),
  "dark/black-alpha/90": rgba(0, 0, 0, 0.9),
  "dark/black-alpha/100": rgba(0, 0, 0, 1),
);
