// Global annotations
////
/// @group ui-components
/// @access private
////

.bb-cashflow-legend__item {
  width: $spacer-md;
  height: $spacer-md;
  margin-right: $spacer-sm;
  border-radius: $spacer-xs;
}

.bb-cashflow-chart__hr {
  position: relative;
  bottom: 2.875 * $spacer;
}

:root {
  --chart-budget-donut-color-base: #{$color-background-neutral-subtle};
  --chart-budget-donut-color-primary: #{$color-foreground-brand};
  --chart-budget-donut-color-danger: #{$color-foreground-danger};
}
