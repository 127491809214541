// Global annotations
////
/// @group buttons
/// @access private
////

/// Class to hide button text in xs screens. Useful in button with icons.
///
/// @example html
/// <button class="btn btn-primary bb-button--xs-hide-text">
///  <i class="bb-icon bb-icon-settings"></i>
///  <span>Primary</span>
/// </button>
@include media-breakpoint-down(sm) {
  .bb-button--xs-hide-text {
    span {
      display: none;
    }
  }
}

/// Class to show whole button's outline if located in right-top corner
.bb-button--outline-spacing-top-right {
  margin-block-start: $spacer-xs;
  margin-inline-end: $spacer-xs;
}
