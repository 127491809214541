bb-upload-input {
  .drop-area {
    height: 70px;
    border: 1px dashed $color-border-subtle;
    box-sizing: content-box;

    &.drop-area-highlight {
      border-color: $color-border-brand;
    }
  }

  bb-icon-ui {
    margin-inline-end: $spacer-sm;
  }
}
