@mixin slider-thumb {
  cursor: pointer;
  width: $spacer-lg;
  height: $spacer-lg;
  border-radius: $border-radius;
  -webkit-appearance: none;
  background: $color-background-brand-hovered;
  transform: translateY(-$spacer-md);
}

@mixin disabled-slider-thumb {
  background: $color-foreground-disabled;
  cursor: not-allowed;
}

bb-questionnaire-formly-range-slider {
  .bb-questionnaire-capability-error {
    bb-amount-input-ui {
      input {
        border-color: $color-border-danger !important;
      }
    }
  }

  .bb-questionnaire-capability-min-max-value {
    color: $color-foreground-support;
  }

  input[type="range"] {
    background: $color-background-none;
    -webkit-appearance: none;
  }

  input[type="range"] {
    &::-webkit-slider-thumb {
      @include slider-thumb();
    }
    &::-moz-range-thumb {
      @include slider-thumb();
    }
  }

  input[type="range"]:disabled {
    &::-webkit-slider-thumb {
      @include disabled-slider-thumb();
    }
    &::-moz-range-thumb {
      @include disabled-slider-thumb();
    }
  }

  .bb-questionnaire-capability-custom-track {
    height: $spacer-sm;
    background: $color-background-overlay;

    &.disabled {
      background: $color-background-disabled;
    }

    .bb-questionnaire-capability-usage-slider {
      height: inherit;
      z-index: 5;

      &.disabled {
        background: $color-background-disabled;
        cursor: not-allowed;
      }
    }
  }
}
