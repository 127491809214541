// Global annotations
////
/// @group widgets
/// @access private
////

$digital-investing-component-border: $border !default;

.bb-digital-investing__trade-input-wrapper {
  border-radius: $border-radius-sm;
  border: $digital-investing-component-border;
  padding: $sizer;
}
