// Global annotations
////
/// @group elements
/// @access private
////

@import "bootstrap/scss/type";

strong {
  font-weight: $font-weight-semi-bold;
}

blockquote {
  margin-block-end: $spacer;
  font-size: $font-size-base * 1.5;
}
