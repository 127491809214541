[dir="rtl"]bb-file-upload-progress {
  right: auto;
  left: 0;
}

bb-file-upload-progress {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 500px;
  z-index: $zindex-popover;

  @include media-breakpoint-down(sm) {
    max-width: 100%;
  }

  .file-upload-progress__header {
    height: 56px;
    width: 100%;
    background: $color-background-surface-2;
  }

  .file-upload-progress__body {
    max-height: 350px;
    overflow-y: auto;
    background-color: $color-background-surface-1;
  }

  .file-upload-progress__header-status {
    margin-inline-start: $spacer;
    small {
      margin-inline-start: $spacer-sm;
    }
  }
}
