// Global annotations
////
/// @group journey-specific
/// @access private
////

.bb-cra-group-component {
  &__collapsible-footer {
    // UX re-brand: bs gap-4 (1.5rem) or gap-5 (3rem)
    row-gap: 2.5rem;
  }

  bb-collapsible-card-ui {
    .bb-card__header,
    .bb-card__body {
      padding: 0 !important;
    }
  }
}

.bb-cra-risk-score-entity__range,
.bb-cra-risk-score-entity__emptyScore {
  width: 65%;
}

.bb-cra-range-section {
  flex: 20%;
}

.bb-cra-score-value {
  top: 25px;
  width: 41px;
  right: 0;
  display: flex;
  justify-content: center;
  background: $color-background-inverted;
  color: $color-on-background-inverted;
  border-radius: $border-radius-xs;
  box-shadow:
    0 0 5px 1px rgba(68, 67, 64, 0.1),
    0 2px 16px 0 rgba(68, 67, 64, 0.08);
  font-size: $font-size-subheader;
  padding: calc($sizer / 5.3);

  &::after {
    content: "";
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 10px solid $color-background-inverted;
    position: absolute;
    bottom: 23px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}

.bb-cra-score-colors {
  .colors {
    height: 8px;
    opacity: 0.15;
  }

  .valid-measure {
    height: 8px;
  }
}

.bb-cra-section {
  // UX re-brand: bootstrap m-4 == 24px and m-5 == 48px. $spacer-xl == 32px
  margin: $spacer-xl;
}

.bb-cra-task-header {
  background: $color-background-brand;
  color: $color-on-background-brand;

  h2 {
    color: $color-on-background-brand;
  }
}
