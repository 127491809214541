////
/// Colors (global)
/// @group 1-variables/semantic/color/on-background
/// @access public
////

/* stylelint-disable color-no-hex */

/// figma equivalent on-background/brand-subtle
$color-on-background-brand-subtle: color-from-semantic("on-background/brand-subtle") !default;

/// figma equivalent on-background/brand
$color-on-background-brand: color-from-semantic("on-background/brand") !default;

/// figma equivalent on-background/neutral-subtle
$color-on-background-neutral-subtle: color-from-semantic("on-background/neutral-subtle") !default;

/// figma equivalent on-background/neutral
$color-on-background-neutral: color-from-semantic("on-background/neutral") !default;

/// figma equivalent on-background/info-subtle
$color-on-background-info-subtle: color-from-semantic("on-background/info-subtle") !default;

/// figma equivalent on-background/info
$color-on-background-info: color-from-semantic("on-background/info") !default;

/// figma equivalent on-background/success-subtle
$color-on-background-success-subtle: color-from-semantic("on-background/success-subtle") !default;

/// figma equivalent on-background/success
$color-on-background-success: color-from-semantic("on-background/success") !default;

/// figma equivalent on-background/warning-subtle
$color-on-background-warning-subtle: color-from-semantic("on-background/warning-subtle") !default;

/// figma equivalent on-background/warning
$color-on-background-warning: color-from-semantic("on-background/warning") !default;

/// figma equivalent on-background/danger-subtle
$color-on-background-danger-subtle: color-from-semantic("on-background/danger-subtle") !default;

/// figma equivalent on-background/danger
$color-on-background-danger: color-from-semantic("on-background/danger") !default;

/// figma equivalent on-background/category-1-subtle
$color-on-background-category-1-subtle: color-from-semantic(
  "on-background/category-1-subtle"
) !default;

/// figma equivalent on-background/category-1
$color-on-background-category-1: color-from-semantic("on-background/category-1") !default;

/// figma equivalent on-background/category-2-subtle
$color-on-background-category-2-subtle: color-from-semantic(
  "on-background/category-2-subtle"
) !default;

/// figma equivalent on-background/category-2
$color-on-background-category-2: color-from-semantic("on-background/category-2") !default;

/// figma equivalent on-background/category-3-subtle
$color-on-background-category-3-subtle: color-from-semantic(
  "on-background/category-3-subtle"
) !default;

/// figma equivalent on-background/category-3
$color-on-background-category-3: color-from-semantic("on-background/category-3") !default;

/// figma equivalent on-background/category-4-subtle
$color-on-background-category-4-subtle: color-from-semantic(
  "on-background/category-4-subtle"
) !default;

/// figma equivalent on-background/category-4
$color-on-background-category-4: color-from-semantic("on-background/category-4") !default;

/// figma equivalent on-background/category-5-subtle
$color-on-background-category-5-subtle: color-from-semantic(
  "on-background/category-5-subtle"
) !default;

/// figma equivalent on-background/category-5
$color-on-background-category-5: color-from-semantic("on-background/category-5") !default;

/// figma equivalent on-background/category-6-subtle
$color-on-background-category-6-subtle: color-from-semantic(
  "on-background/category-6-subtle"
) !default;

/// figma equivalent on-background/category-6
$color-on-background-category-6: color-from-semantic("on-background/category-6") !default;

/// figma equivalent on-background/category-7-subtle
$color-on-background-category-7-subtle: color-from-semantic(
  "on-background/category-7-subtle"
) !default;

/// figma equivalent on-background/category-7
$color-on-background-category-7: color-from-semantic("on-background/category-7") !default;

/// figma equivalent on-background/category-8-subtle
$color-on-background-category-8-subtle: color-from-semantic(
  "on-background/category-8-subtle"
) !default;

/// figma equivalent on-background/category-8
$color-on-background-category-8: color-from-semantic("on-background/category-8") !default;

/// figma equivalent on-background/category-9-subtle
$color-on-background-category-9-subtle: color-from-semantic(
  "on-background/category-9-subtle"
) !default;

/// figma equivalent on-background/category-9
$color-on-background-category-9: color-from-semantic("on-background/category-9") !default;

/// figma equivalent on-background/category-10-subtle
$color-on-background-category-10-subtle: color-from-semantic(
  "on-background/category-10-subtle"
) !default;

/// figma equivalent on-background/category-10
$color-on-background-category-10: color-from-semantic("on-background/category-10") !default;

/// figma equivalent on-background/on-color
$color-on-background-on-color: color-from-semantic("on-background/on-color") !default;

/// figma equivalent on-background/inverted
$color-on-background-inverted: color-from-semantic("on-background/inverted") !default;
