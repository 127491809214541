bb-us-formly-dropdown-multi-select-with-search {
  .ng-select.ng-select-multiple {
    bb-input-checkbox-ui {
      pointer-events: none;
    }

    bb-loading-indicator-ui {
      margin-inline-end: $spacer-sm;

      .bb-state-container {
        padding: 0;
      }
    }

    .ng-select-container {
      align-items: center;
      min-height: $input-height;
      border-radius: $border-radius-sm;
      border-color: $color-border-default;

      .ng-value-container {
        padding-block-start: $sizer-sm;
        padding-inline-start: $sizer-sm;
        margin-inline-end: $sizer-sm;

        .ng-placeholder {
          color: $color-foreground-support;
          position: initial;
        }

        .ng-value {
          display: flex;
          align-items: center;
          background-color: $color-background-selected;
          color: $color-foreground-default;
          border-radius: $border-radius-xs;
          margin-inline-end: $sizer-sm;
          margin-block-end: $sizer-sm;

          .ng-value-label {
            white-space: break-spaces;
            font-size: $font-size-subtitle;
            line-height: $line-height-base;
          }

          .ng-value-icon:hover {
            background: none;
          }
        }
      }

      .ng-clear-wrapper,
      .ng-arrow-wrapper {
        width: auto;
        color: $color-foreground-support;
        margin-inline-end: $spacer-sm;
        font-family: "Material Icons Outlined", sans-serif;
        font-feature-settings: "liga";
        position: initial;

        .ng-clear,
        .ng-arrow {
          display: none;
        }

        &:after {
          width: $spacer;
          display: flex;
          justify-content: center;
        }
      }

      .ng-clear-wrapper {
        font-size: $font-size-base;

        &:after {
          content: "cancel";
        }
      }

      .ng-arrow-wrapper {
        font-size: $font-size-h2;

        &:after {
          content: "expand_more";
        }
      }
    }

    .ng-dropdown-panel {
      border: 0;
      background-color: $color-background-surface-1;
      border-radius: $border-radius-md;
      box-shadow: $box-shadow-md;
      padding: $spacer-sm 0;
      overflow: hidden;

      .ng-dropdown-header {
        border: 0;
        padding: $spacer-sm $spacer-md $spacer-xs $spacer-md;
      }

      .ng-option {
        padding: $spacer-sm $spacer-md;
        color: $color-foreground-default;

        &.ng-option-disabled {
          padding-top: 0;
        }

        &.ng-option-marked {
          &,
          &.ng-option-selected {
            background-color: $color-background-surface-1-hovered;
          }
        }
      }
    }
  }

  .ng-select.ng-select-opened {
    &.ng-select-top,
    &.ng-select-bottom {
      .ng-select-container {
        border-radius: $border-radius-sm;
      }
    }

    .ng-select-container {
      .ng-arrow-wrapper:after {
        content: "expand_less";
      }
    }

    &.ng-select-top {
      ng-dropdown-panel {
        margin-bottom: $spacer-xs;
      }
    }

    &.ng-select-bottom {
      ng-dropdown-panel {
        margin-top: $spacer-xs;
      }
    }
  }

  .ng-select.ng-select-focused {
    > .ng-select-container {
      outline: $focus-border-primary;
      outline-offset: $spacer-xs;
      border-color: $color-border-default;
      box-shadow: none;
    }
  }

  .ng-select.ng-touched {
    &:not(.ng-valid):not(.ng-select-focused):not(.ng-select-disabled) {
      > .ng-select-container:not(.ng-has-value) {
        border-color: $color-border-danger;
      }
    }
  }

  .ng-select.ng-select-multiple.ng-select-disabled {
    .ng-select-container {
      .ng-value-container {
        .ng-value {
          background-color: $color-background-disabled;
          color: $color-foreground-disabled;

          .ng-value-icon {
            pointer-events: none;
          }
        }
      }

      .ng-arrow-wrapper {
        color: $color-foreground-disabled;
      }
    }
  }
}
