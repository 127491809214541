.tabs {
  @extend .list-unstyled;

  align-items: flex-start;
  display: flex;
  flex-direction: row;

  border-radius: $border-radius-xs;
  margin-block-end: 0;
  padding-block-end: $sizer-xl;

  &__tab {
    display: flex;
    flex: 1 0 0;
    height: 66px;
  }

  &__tab-link {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 1 0 0;
    gap: $spacer-sm;
    justify-content: center;

    color: $color-neutral-dark;
    line-height: 1.5rem; // 24px

    border: $border-width $border-style $color-neutral-greyer;
    padding: $sizer;

    &--active {
      background: $color-neutral-darker;
      border: $border-width $border-style $color-neutral-darker;
      color: $color-neutral-white;
      font-weight: $font-weight-semi-bold;

      &:hover {
        color: $color-neutral-white;
      }
    }
  }
}
