.cm-direct-insights {
  --chart-color-1: #{$color-background-category-1};
  --chart-color-2: #{$color-background-category-2};
  --chart-color-2-darkest: #{$color-background-category-2};
  --chart-color-3: #{$color-background-category-3};
  --chart-color-4: #{$color-background-category-4};
  --chart-color-4-darkest: #{$color-background-category-4};
  --chart-color-6: #{$color-background-category-6};
  --border-radius: #{$border-radius};
  --body-color: #{$color-foreground-default};
  --font-size-highlight: #{$font-size-highlight};
}
