// Global annotations
////
/// @group journey-specific
/// @access private
////

.bb-approval-policy-dropdown {
  border: none;
  background: none;
}

.policy-dropdown-template {
  display: grid;
  grid-template-columns: minmax(0, min-content) minmax(0, auto);
  gap: $spacer;
}
