[dir="rtl"]bb-ds-sidebar-overlay-container {
  .sidebar-overlay {
    left: 0;
    right: auto;
  }

  div.close-overlay-btn {
    right: auto;
    left: 28px;
  }
}

bb-ds-sidebar-overlay-container {
  .sidebar-overlay {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1031;
    height: 100vh;
    width: 608px;
    background-color: $color-background-surface-1;
    overscroll-behavior: contain;
    outline: none;

    .btn-focusable {
      &:focus {
        color: $color-on-background-brand;
        background-color: $color-background-brand;
        outline: $color-focus-outline auto 1px;
      }
    }
  }

  .close-overlay-btn {
    position: fixed;
    right: 28px;
    top: 13px;
    z-index: 1000;
  }

  .rounded-start-up {
    border-start-start-radius: $border-radius;
    border-end-start-radius: $border-radius;
  }

  @include media-breakpoint-down(md) {
    .sidebar-overlay {
      width: 100vw;
    }
    .rounded-start-down-md-0 {
      border-end-start-radius: 0;
      border-start-start-radius: 0;
    }
  }

  .sidebar-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0.5;
    height: 100vh;
    width: 100vw;
    z-index: 1030;
  }
}
