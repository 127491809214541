// Global annotations
////
/// @group structure
/// @access private
////

/// @deprecated Not used. Marked deprecated at ui-ang@12, will be removed in version 14
$btn-link-hover-decoration: none !default;
$btn-box-shadow-focus: none !default;
$btn-circle-sm-size: $btn-min-height-sm !default;
$btn-circle-md-size: $btn-min-height !default;
/// @deprecated Not used. Marked deprecated at ui-ang@12, will be removed in version 14
$btn-link-text-light-background-color: rgba(255, 255, 255, 0) !default;
$btn-small-circle-font-size: 1.25rem !default;
$btn-small-circle-line-height: 1 !default;

@import "bootstrap/scss/buttons";

.btn {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: $button-font-weight;
  white-space: nowrap;
  min-height: $btn-min-height;

  &:focus {
    box-shadow: $btn-box-shadow-focus;
  }

  &:not(.btn-unstyled) {
    &:focus::before {
      position: absolute;
      inset: $btn-focus-outline-distance;
      border: $btn-focus-border-primary;
      // here it is 20rem
      border-radius: $radius-button-default;
      content: "";
    }

    &.rounded-outline {
      &:focus::before {
        border-radius: $border-radius-sm;
      }
    }
  }

  &:is(.btn-link-text) {
    min-height: inherit;

    &:focus::before {
      inset-block: $btn-focus-outline-distance * 0.25;
      inset-inline: $btn-focus-outline-distance * 0.5;
      border-radius: $btn-focus-border-radius-link-text;
    }
  }

  i + span,
  span + i {
    margin-inline-start: $btn-padding-x * 0.33;
  }

  &.btn-sm {
    min-height: $btn-min-height-sm;
  }

  &.btn-circle {
    padding: 0;

    &.btn-sm {
      min-width: $btn-circle-sm-size;
      min-height: $btn-circle-sm-size;

      .bb-icon,
      .bicon {
        font-size: $btn-small-circle-font-size;
        line-height: $btn-small-circle-line-height;
      }
    }

    &.btn-md {
      width: $btn-circle-md-size;
      height: $btn-circle-md-size;
    }
  }

  bb-icon-ui {
    &:last-child {
      .bb-icon--cropped {
        margin-inline-start: $spacer-sm;
      }
    }

    &:first-child {
      .bb-icon--cropped {
        margin-inline-end: $spacer-sm;
      }
    }
  }
}

.btn-on-color {
  .btn {
    &:not(.btn-unstyled) {
      &:focus::before {
        border: $focus-border-width-primary solid $color-focus-on-color-outline;
      }
    }
  }
}

@mixin button-style($color, $button-color-map) {
  /*
   We are passing the focus background and focus border as background to the mixin then manually overriding the border and background just after invoking the mixing.
   The reason for this is that the focus rules are harder to override than the border and background ones, and they are using the background and border color
  */
  @include button-variant(
    map-get($button-color-map, "default-bg"),
    map-get($button-color-map, "default-border"),
    map-get($button-color-map, "color"),
    map-get($button-color-map, "hover-bg"),
    map-get($button-color-map, "hover-border"),
    map-get($button-color-map, "hover-color"),
    map-get($button-color-map, "active-bg"),
    map-get($button-color-map, "active-border"),
    map-get($button-color-map, "active-color"),
    map-get($button-color-map, "disabled-bg"),
    map-get($button-color-map, "disabled-border"),
    map-get($button-color-map, "disabled-color")
  );

  &:focus:not(:hover):not(:active):not(:disabled):not(.disabled) {
    color: map-get($button-color-map, "color");
    background-color: map-get($button-color-map, "focus-bg");
    border-color: map-get($button-color-map, "focus-border");
  }

  &:focus::before {
    border-color: map-get($button-color-map, "focus-border");
  }

  &:not(:disabled):not(.disabled) {
    &:hover {
      background-color: map-get($button-color-map, "hover-bg");
      border-color: map-get($button-color-map, "hover-border");
      color: map-get($button-color-map, "hover-color");
    }

    &:active,
    &.active,
    &:active:focus,
    &.active:focus {
      background-color: map-get($button-color-map, "active-bg");
      border-color: map-get($button-color-map, "active-border");
      color: map-get($button-color-map, "active-color");
    }

    &:focus,
    &.active:focus {
      box-shadow: $btn-box-shadow-focus;
    }
  }
}

.btn-link-text {
  padding: 0;
  border-radius: 0;
  opacity: 1;

  &:focus,
  &.focus,
  &:hover,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    text-decoration: underline;

    .bb-icon {
      text-decoration: none;

      &::before {
        display: inline-block;
      }

      .btn-on-color & {
        color: unset;
      }
    }
  }
}

// For each of Bootstrap's buttons, define text, background, and border color.
@each $color, $button-color-map in $theme-button-colors {
  .btn-#{$color} {
    @include button-style($color, $button-color-map);
  }
}

// For buttons that support being displayed on the color background, define text, background, and border color.
@each $color, $button-color-map in $theme-button-on-color-colors {
  .btn-on-color .btn-#{$color} {
    @include button-style($color, $button-color-map);
  }
}

.btn-link-text-light {
  @extend .btn-link-text;

  // TODO: verify with UX on semantic color;
  color: $color-foreground-on-color;

  &:not(:disabled):not(.disabled) {
    &:focus,
    &.focus,
    &:hover,
    &:active,
    &.active {
      // TODO: verify with UX on semantic color;
      // TODO: define box shadow when the global focus state is ready
      color: $color-foreground-on-color;
    }
  }
}

.btn-unstyled {
  padding: 0 0;
  color: inherit;
  font-weight: normal;
  text-align: start;
  background: transparent;
  border: 0;
  border-radius: $border-radius-none;

  &:not(:disabled):not(.disabled) {
    &:hover {
      background-color: transparent;
    }

    &.active,
    &:active {
      background-color: transparent;
    }
  }
}

.btn-unstyled--focusable {
  &:focus::before {
    position: absolute;
    inset: $btn-focus-outline-distance * 0.5;
    border: $btn-focus-border-primary;
    content: "";
  }
}

button,
[type="submit"],
[type="button"],
[type="reset"] {
  @include appearance-toggle();
}

// .btn-block` was copied from node_modules/bootstrap/scss/_buttons.scss of bootstrap@4.6
// The reason is as follows. Bootstrap 5 offers a grid system and its utility classes.
// But those come with `!important` which we cannot use in the `theme`
.btn-block {
  display: block;
  width: 100%;

  // Vertically space out multiple block buttons
  + .btn-block {
    margin-top: $btn-block-spacing-y;
  }
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn-block {
    width: 100%;
  }
}
