// Global annotations
////
/// @group journey-specific
/// @access private
////

/// Accounts management - account UI
///
/// @example html
/// <div class="bb-at-manage-accounts-item">
///   <div class="bb-at-manage-accounts-item__primary-info">
///     <div><div>
///     <div>Name</div>
///     <div>Logo</div>
///     <div>Primary info</div>
///   </div>
///   <div class="bb-at-manage-accounts-item__secondary-info">
///     <div>Secondary info</div>
///   </div>
/// </div>

.bb-at-manage-accounts-item__primary-info {
  display: grid;
  grid-template-columns: min-content auto;
}
