// Global annotations
////
/// @group journey-specific
/// @access private
////

@use "sass:math";

// Custom Forex styles
$forex-pairs-width: $sizer-md * 2 !default;
$forex-pairs-height: $sizer-md * 1.5 !default;
$forex-card-border: $border-width $border-style $color-border-subtle;
$forex-card-border-hovered: $border-width $border-style $color-border-default;
$forex-card-border-active: 2px $border-style $color-border-brand;
$forex-card-spread-indicator-background: $color-background-surface-1;
$forex-card-spread-indicator-border: $border-width $border-style $color-background-surface-2;
$forex-card-background-success: $color-background-success-subtle;
$forex-card-background-danger: $color-background-danger-subtle;
$forex-card-color-success: $color-on-background-success-subtle;
$forex-card-color-danger: $color-on-background-danger-subtle;

$forex-progressbar-color-brand: $color-background-brand;
$forex-progressbar-color-warning: $color-background-warning;
$forex-progressbar-color: $color-background-overlay;

$forex-indicative-rate-border: $border-width $border-style $color-border-subtle;
$forex-sticky-title-background: $color-background-surface-1;

$forex-overview-card-min-width: 19.5rem !default;
$forex-overview-card-layout-sm: 100% !default;
$forex-overview-card-layout-md: ($forex-overview-card-layout-sm * 0.5) !default;
$forex-overview-card-layout-xl: math.div($forex-overview-card-layout-sm, 3) !default;

.bb-forex-pairs {
  width: $forex-pairs-width;
  height: $forex-pairs-height;
  position: relative;
}

.bb-forex-flag {
  position: absolute;
  border-radius: 2px;
  box-sizing: content-box;

  &--left {
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  &--right {
    right: 0;
    top: 0;
  }
}

.bb-forex-card:not(.bb-card--ignore) {
  border: $forex-card-border;
  height: 100%;
  box-sizing: content-box;

  &:hover {
    border: $forex-card-border-hovered;
  }

  &:active {
    border: $forex-card-border-active;
  }

  &.bb-forex-selected {
    border: $forex-card-border-active;
  }

  .spread-indicator {
    position: absolute;
    background: $forex-card-spread-indicator-background;
    border: $forex-card-spread-indicator-border;
    border-radius: $border-radius-sm;
    padding: $sizer-xs;
    bottom: -1rem;
    font-size: $font-size-table-header;
  }

  .header {
    font-size: $font-size-h3;
  }

  .option {
    &--header {
      font-size: $font-size-table-header;
    }

    &--buy {
      border-top-left-radius: $border-radius-sm;
      border-bottom-left-radius: $border-radius-sm;
      transition: transform 2s ease;
    }

    &--sell {
      border-top-right-radius: $border-radius-sm;
      border-bottom-right-radius: $border-radius-sm;
      transition: transform 2s ease;
    }
  }

  .blink-success {
    display: inline-block;
    background-color: $forex-card-background-success;
    color: $forex-card-color-success;
  }

  .blink-danger {
    display: inline-block;
    background-color: $forex-card-background-danger;
    color: $forex-card-color-danger;
  }
}

// Due to bb-card class override this one we need to have an !important
.bb-forex-compare-rates {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.bb-forex-forwards-details-line {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.bb-forex-forward-details-legend-dot {
  width: 8px;
  height: 8px;
  border-radius: 2px;
  background-color: $forex-progressbar-color;

  &.active {
    background-color: $forex-progressbar-color-brand;
  }

  &.expired {
    background-color: $forex-progressbar-color-warning;
  }
}

.bb-forex-forward-details-summary-item {
  flex-basis: content;
}

.bb-progress-bar .progress-bar {
  border-radius: 0;
}

.bb-compare-rates-indicative-rate {
  border-inline-start: $forex-indicative-rate-border;
  padding-inline-start: $sizer-lg;
}

@include media-breakpoint-up(lg) {
  .bb-forex-search-container {
    border-inline-start: $border;
  }

  .bb-forex-settings-container {
    max-height: calc(100vh - #{$page-header-padding-block-end});
  }

  .bb-forex-scroll-container {
    overflow: auto;
    max-height: inherit;
  }
}

.bb-forex-sticky-title {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: $forex-sticky-title-background;
  padding-bottom: $sizer-md;
}

.bb-forex-carousel-container {
  position: relative;
  width: 100%;
}

.bb-forex-carousel-button {
  position: absolute;
  z-index: 10;

  &__next {
    right: 0;
    transform: translate(-40%, 0%);
  }

  &__prev {
    left: 0;
    transform: translate(40%, 0%);
  }
}

.bb-forex-overview__card {
  min-width: $forex-overview-card-min-width;

  @include media-breakpoint-up(md) {
    flex: 0 0 $forex-overview-card-layout-md;
    min-width: $forex-overview-card-min-width;
  }

  @include media-breakpoint-up(xl) {
    flex: 0 0 $forex-overview-card-layout-xl;
    min-width: $forex-overview-card-min-width;
  }
}

.bb-forex-carousel {
  &--item {
    padding: 0 $sizer-sm;
    transition: transform 0.3s ease;
    flex-shrink: 0;
  }
}
