$universal-header-height: $page-layout-topbar-height !default;
$universal-header-white-background: $color-background-surface-1 !default;
$universal-header-gray-background: $color-background-page !default;
$universal-header-shadow: $box-shadow-md-size $box-shadow-md-color !default;
$universal-header-vertical-padding: $sizer-md !default;
$universal-header-vertical-left-padding: $sizer-lg !default;
$universal-header-vertical-right-padding: $spacer-md !default;
$universal-header-gap-between-elements: $spacer-md !default;
$universal-header-full-logo-width: 7.5rem !default;
$universal-header-emblem-logo-height: $sizer-xl !default;
$universal-header-emblem-logo-width: $sizer-xl !default;
$universal-header-title-color: $color-foreground-support !default;
$universal-header-title-font-size: $font-size-h3 !default;

.bb-universal-header {
  width: 100%;
  max-width: 100%;
  height: $universal-header-height;
  padding: $universal-header-vertical-padding 0;
  padding-inline-start: $universal-header-vertical-left-padding;
  padding-inline-end: $universal-header-vertical-right-padding;
  display: flex;
  justify-content: space-between;

  &:has(.bb-universal-header__close-action),
  &:has(.bb-universal-header__additional-action:not(:empty)) {
    gap: $universal-header-gap-between-elements;
  }
}

.bb-universal-header--white {
  background-color: $universal-header-white-background;
  box-shadow: $universal-header-shadow;
}

.bb-universal-header--no-background {
  background-color: unset;
}

.bb-universal-header__static-content,
.bb-universal-header__actions-content {
  display: flex;
  flex-shrink: 1;
  min-width: 0;
  gap: $universal-header-gap-between-elements;
}

.bb-universal-header__static-content {
  flex-grow: 1;

  &:not(:has(.bb-logo--emblem)) {
    align-items: baseline;
  }

  &:has(.bb-universal-header__logo--has-adaptive-logo) {
    align-items: baseline;

    @include media-breakpoint-down(md) {
      align-items: center;
    }
  }
}

.bb-universal-header__actions-content {
  justify-content: flex-end;

  &:has(.bb-universal-header__additional-action:empty) {
    gap: 0;
  }
}

.bb-universal-header__logo,
.bb-universal-header__close-action,
.bb-universal-header__additional-action {
  display: flex;
}

.bb-universal-header__title {
  display: inline-flex;
  flex-grow: 1;
  color: $universal-header-title-color;
  margin-bottom: 0;
  font-size: $universal-header-title-font-size;
}

.bb-universal-header__title--hidden-for-small-screen {
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.bb-universal-header__logo,
.bb-universal-header__title,
.bb-universal-header__close-action,
.bb-universal-header__additional-action {
  height: 100%;
  align-items: center;
  flex-shrink: 1;
  min-width: 0;
}

.bb-universal-header__logo {
  width: auto;

  .bb-logo:not(.bb-logo--emblem) {
    width: $universal-header-full-logo-width;
  }

  .bb-logo--emblem {
    height: $universal-header-emblem-logo-height;
    width: $universal-header-emblem-logo-width;
    padding-block-start: 0;
  }
}

.bb-universal-header__close-action,
.bb-universal-header__additional-action {
  width: auto;
}
