// Variables provided by Bootstrap
$font-size-lg: $font-size-h3 !default; // 20px
$font-size-sm: $font-size-subtitle !default; // 14px
$h1-font-size: $font-size-h1 !default; // 34px
$h2-font-size: $font-size-h2 !default; // 24px
$h3-font-size: $font-size-h3 !default; // 20px
$h4-font-size: $font-size-highlight !default; // 18px
$h5-font-size: $font-size-base !default; // 16px
$h6-font-size: $font-size-subtitle !default; // 14px
$small-font-size: $font-size-subtitle !default; // 14px
$lead-font-size: $font-size-h3 !default; // 20px
$headings-margin-bottom: ($spacer * 0.5) !default;
$headings-font-weight: $font-weight-heading !default;
/// @deprecated - use $body-secondary-color. Will be removed with Bootstrap 6 upgrade.
$text-muted: $color-foreground-support !default;
$lead-font-weight: $font-weight-regular !default;
$font-family-base: $font-family !default;
$headings-font-family: $font-family-heading !default;
$headings-color: $color-on-background-neutral-subtle !default;
