// Global annotations
////
/// @group journeys
/// @access private
////

.bb-job-role-table-list__heading {
  line-height: 2rem;
  padding-block-start: $sizer;
  padding-block-end: $sizer;
}
