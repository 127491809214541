////
/// Colors (global)
/// @group 1-variables/semantic/color/theme
/// @access private
////

/* stylelint-disable color-no-hex */

@import "dark";
@import "light";

$theme: light;

@function color-from-semantic($key) {
  @return map-get(theme-colors(), $key);
}

@function theme-colors() {
  @if ($theme == light) {
    @return $semantic-colors-light;
  } @else if($theme == dark) {
    @return $semantic-colors-dark;
  }
}
