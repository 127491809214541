////
/// @group 1-variables/components/page-layout
/// @access public
////

/// Configures the height of the page-layout-topbar
$page-layout-topbar-height: 4.5rem !default;

/// Configures the height of the layout-topbar
$layout-topbar-height: 4.5rem !default;
