/* VARIABLES */
$side-menu-width: 240px;
$side-menu-indent-offset: 1rem;
$selected-color: #1281ac;
$darker-text: #111112;
$lighter-text: #55585a;
$muted-text: #8c9194;
$bgd-grey: #f1f6fb;

$responsive-sm: 540px;
$responsive-md: 720px;
$responsive-lg: 960px;
$responsive-xl: 1128px;

$header-offset-xs: 121.2px; // approximate height of the header
$header-offset-lg: 98px; // approximate height of the header

$footer-offset: 90px; // approximate height of the footer

$sidebar-inline-offset-xl: 280px;
$sidebar-inline-offset-xxl: 360px;

$mini-menu-inline-offset: 210px;

$main-body-padding: $sizer-lg;
