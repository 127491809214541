// Global annotations
////
/// @group journey-specific
/// @access private
////

bb-ds-collapsible-row {
  .collapsible-row {
    .bb-card__header {
      border-bottom: $table-border-width solid $color-border-subtle !important;
      border-radius: 0 !important;
      min-height: $sizer-xxl;

      &:hover {
        background: $color-background-surface-1-hovered;
      }
    }

    .bb-card:not(.bb-card--ignore).bb-card--md.bb-card > .bb-card__header--only-child {
      padding-left: $sizer-md !important;
      padding-right: $sizer-md !important;
    }

    // By default when a collapsible body is collapsed and the header is focused,
    // the bottom border is hidden behind the next collapsible header.
    .bb-card:not(.bb-card--ignore) .bb-card__header:focus::before {
      z-index: 1;
    }
  }
}

bb-ds-edit-data-card {
  .edit-data-card {
    display: flex;

    &__info {
      flex-grow: 2.5;
      flex-basis: 0;
    }

    &__img {
      flex-basis: 0;
      background-size: 15rem 20rem !important;
    }
  }
}

.financial-spreading-table-row-value__size {
  // Setting for accommodate value such as $100,000,000.00
  // and make all icons of different expandable rows aligned horizontally
  min-width: 7.5rem;
}

bb-ds-year-selector {
  bb-tab-group-ui .nav-tabs {
    margin-bottom: 0;
  }
}

bb-ds-collapsible {
  .bb-card__header {
    padding: $sizer-sm !important;
  }

  .bb-card__body {
    padding: 0 !important;
  }
}
