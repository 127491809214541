// Global annotations
////
/// @group widgets
/// @access private
////

.bb-manage-payments-table--loading {
  visibility: collapse;
}
.bb-manage-payments-table--checkbox {
  width: 4rem;
}

.bb-payments-filter-chips {
  .bb-chip-item {
    margin-inline-end: $spacer-sm;
  }
}
