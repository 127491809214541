////
/// Chart colors (global)
/// @group 1-variables/brand/chart-color
/// @access public
////

/// Configures additional palette color 1: Used in the custom icon colors and part of the data-visualisation palette
$chart-color-1: #e01700 !default;

/// Configures additional palette color 2: Used in the custom icon colors and part of the data-visualisation palette
$chart-color-2: #ff8000 !default;

/// Configures additional palette color 3: Used in the custom icon colors and part of the data-visualisation palette
$chart-color-3: #ed5ea6 !default;

/// Configures additional palette color 4: Used in the custom icon colors and part of the data-visualisation palette
$chart-color-4: #295eff !default;

/// Configures additional palette color 5: Used in the custom icon colors and part of the data-visualisation palette
$chart-color-5: #6817cf !default;

/// Configures additional palette color 6: Used in the custom icon colors and part of the data-visualisation palette
$chart-color-6: #1ed2d2 !default;

/// Configures additional palette color 7: Used in the custom icon colors and part of the data-visualisation palette
$chart-color-7: #40bf84 !default;

/// Configures additional palette color 8: Used in the custom icon colors and part of the data-visualisation palette
$chart-color-8: #79c716 !default;

/// Configures additional palette color 9: Used in the custom icon colors and part of the data-visualisation palette
$chart-color-9: #f2bc0d !default;

/// Configures additional palette color 10: Used in the custom icon colors and part of the data-visualisation palette
$chart-color-10: #3a495d !default;

/// Configures the primary color used in the data-visualisation palette
$chart-primary-color: #295eff !default;

/// Configures the primary light color used in the data-visualisation palette
/// @require {variable} $chart-primary-color
/// @require {variable} $color-neutral-white
$chart-primary-light-color: mix($chart-primary-color, $color-neutral-white, 15%);

/// Configures the color used for negative amounts in data-visualisation
$chart-negative-color: #bf2310 !default;

/// Configures the negative light color used in the data-visualisation palette
/// @require {variable} $chart-negative-color
/// @require {variable} $color-neutral-white
$chart-negative-light-color: mix($chart-negative-color, $color-neutral-white, 15%);

/// Configures the color used for positive amounts in data-visualisation
$chart-positive-color: #0e813e !default;

/// Configures the positive light color used in the data-visualisation palette
/// @require {variable} $chart-positive-color
/// @require {variable} $color-neutral-white
$chart-positive-color-light-color: mix($chart-positive-color, $color-neutral-white, 15%);

/// Configures the secondary color used in data-visualisation
/// @require {variable} $chart-color-8
$chart-secondary-color: $chart-color-8 !default;

/// Configures the neutral color used in data-visualisation
$chart-neutral-color: #3a495d !default;

/// Configures the neutral grey color used in data-visualisation
/// @require {variable} $color-neutral-grey
$chart-neutral-grey-color: #ccd5df !default;

/// Configures the alert color used in data-visualisation
/// @require {variable} $chart-negative-color
$chart-alert-color: #fbc02d !default;
