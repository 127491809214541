// Shortcut card
.shortcut {
  display: flex;
  width: 100%;
  @include media-breakpoint-down(sm) {
    margin-bottom: 2rem;
  }
}

.shortcut__header {
  display: flex;
  height: 220px;
  background-color: $color-neutral-grey;
  align-items: center;
  justify-content: center;

  img:not(.noresize) {
    max-width: 100%;
    max-height: 100%;
  }
}

.shortcut--styling {
  background-color: $color-primary-darker;
  overflow: hidden;
}

.shortcut--components {
  background-color: #081c35; // Hack! This is the same color as the header
}

.shortcut--start {
  background-color: $color-primary-lightest;
}

.shortcut__body {
  display: flex;
  flex-direction: column;

  .highlight {
    flex-grow: 1;
    color: $color-neutral-greyest;
    font-size: $font-size-subtitle;
    margin-bottom: $spacer-sm;
  }

  .card-title {
    font-size: $font-size-h3;
    font-weight: $font-weight-bold;
  }
}

.shortcut__links {
  margin-top: 20px;
  display: flex;
}

.shortcut__link {
  margin-right: 20px;
}
