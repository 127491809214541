// Global annotations
////
/// @group journey-specific
/// @access public
////

$auth-server-frame-interior-padding: $spacer !default;
$auth-server-frame-exterior-margin-horizontal: -1 * $spacer !default;
$auth-server-frame-background-color: $identity-journey-background-color !default;
$auth-server-frame-button-bar-md-margin-x: $button-bar-button-md-margin-x !default;

.bb-auth-server-frame-exterior {
  margin: 0 $auth-server-frame-exterior-margin-horizontal;
}

.bb-auth-server-frame-interior {
  overflow: auto;
  background-color: $auth-server-frame-background-color;
  padding: $auth-server-frame-interior-padding;

  &:not(.bb-auth-server-frame-parent-width--xs) {
    .bb-button-bar {
      flex-direction: row-reverse;
    }

    .bb-button-bar__button {
      width: auto;
      margin-block-end: auto;
      margin-inline-end: $auth-server-frame-button-bar-md-margin-x;

      &:first-child {
        margin-block-end: auto;
        margin-inline-end: 0;
      }
    }
  }
}
