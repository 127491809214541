$level-dropdown-border-width: 3px;
$level-dropdown-outline-offset: 4px;
$level-dropdown-body-width: 14rem;
$level-dropdown-search-width: 30rem;

.level-scope-dropdown {
  border-radius: $sizer-sm;
  border: 1px solid $color-border-default;
  background-color: $color-background-surface-1;
  color: $color-foreground-support;
  padding: $spacer-xs $spacer;
  min-height: $sizer-xxl;
  justify-content: space-between;

  &:focus {
    outline: solid $level-dropdown-border-width $color-border-brand !important;
    outline-offset: $level-dropdown-outline-offset;
  }
}

.level-scope-dropdown--disabled {
  background-color: $color-background-page;
  color: $color-foreground-disabled;
}

.level-scope-dropdown--title {
  display: flex;
  justify-content: space-between;
}

.level-scope-dropdown--item {
  &:hover {
    background-color: $color-background-brand-subtle;
    cursor: pointer;
  }

  &:focus {
    border: $level-dropdown-border-width solid $color-border-brand;
    border-radius: $border-radius;
  }
}

.level-scope-dropdown--body {
  width: $level-dropdown-body-width;
}

.level-search {
  width: $level-dropdown-search-width;
}
