@font-face {
  font-family: "Roboto";
  src: url("../../assets/fonts/Roboto/Roboto-Bold.eot");
  src: url("../../assets/fonts/Roboto/Roboto-Bold.eot?#iefix") format("embedded-opentype"),
    url("../../assets/fonts/Roboto/Roboto-Bold.woff2") format("woff2"),
    url("../../assets/fonts/Roboto/Roboto-Bold.woff") format("woff"),
    url("../../assets/fonts/Roboto/Roboto-Bold.ttf") format("truetype"),
    url("../../assets/fonts/Roboto/Roboto-Bold.svg#Roboto-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../../assets/fonts/Roboto/Roboto-BoldItalic.eot");
  src: url("../../assets/fonts/Roboto/Roboto-BoldItalic.eot?#iefix") format("embedded-opentype"),
    url("../../assets/fonts/Roboto/Roboto-BoldItalic.woff2") format("woff2"),
    url("../../assets/fonts/Roboto/Roboto-BoldItalic.woff") format("woff"),
    url("../../assets/fonts/Roboto/Roboto-BoldItalic.ttf") format("truetype"),
    url("../../assets/fonts/Roboto/Roboto-BoldItalic.svg#Roboto-BoldItalic") format("svg");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Roboto";
  src: url("../../assets/fonts/Roboto/Roboto-Light.eot");
  src: url("../../assets/fonts/Roboto/Roboto-Light.eot?#iefix") format("embedded-opentype"),
    url("../../assets/fonts/Roboto/Roboto-Light.woff2") format("woff2"),
    url("../../assets/fonts/Roboto/Roboto-Light.woff") format("woff"),
    url("../../assets/fonts/Roboto/Roboto-Light.ttf") format("truetype"),
    url("../../assets/fonts/Roboto/Roboto-Light.svg#Roboto-Light") format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../../assets/fonts/Roboto/Roboto-Medium.eot");
  src: url("../../assets/fonts/Roboto/Roboto-Medium.eot?#iefix") format("embedded-opentype"),
    url("../../assets/fonts/Roboto/Roboto-Medium.woff2") format("woff2"),
    url("../../assets/fonts/Roboto/Roboto-Medium.woff") format("woff"),
    url("../../assets/fonts/Roboto/Roboto-Medium.ttf") format("truetype"),
    url("../../assets/fonts/Roboto/Roboto-Medium.svg#Roboto-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../../assets/fonts/Roboto/Roboto-Regular.eot");
  src: url("../../assets/fonts/Roboto/Roboto-Regular.eot?#iefix") format("embedded-opentype"),
    url("../../assets/fonts/Roboto/Roboto-Regular.woff2") format("woff2"),
    url("../../assets/fonts/Roboto/Roboto-Regular.woff") format("woff"),
    url("../../assets/fonts/Roboto/Roboto-Regular.ttf") format("truetype"),
    url("../../assets/fonts/Roboto/Roboto-Regular.svg#Roboto-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../../assets/fonts/Roboto/Roboto-LightItalic.eot");
  src: url("../../assets/fonts/Roboto/Roboto-LightItalic.eot?#iefix") format("embedded-opentype"),
    url("../../assets/fonts/Roboto/Roboto-LightItalic.woff2") format("woff2"),
    url("../../assets/fonts/Roboto/Roboto-LightItalic.woff") format("woff"),
    url("../../assets/fonts/Roboto/Roboto-LightItalic.ttf") format("truetype"),
    url("../../assets/fonts/Roboto/Roboto-LightItalic.svg#Roboto-LightItalic") format("svg");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Roboto";
  src: url("../../assets/fonts/Roboto/Roboto-Italic.eot");
  src: url("../../assets/fonts/Roboto/Roboto-Italic.eot?#iefix") format("embedded-opentype"),
    url("../../assets/fonts/Roboto/Roboto-Italic.woff2") format("woff2"),
    url("../../assets/fonts/Roboto/Roboto-Italic.woff") format("woff"),
    url("../../assets/fonts/Roboto/Roboto-Italic.ttf") format("truetype"),
    url("../../assets/fonts/Roboto/Roboto-Italic.svg#Roboto-Italic") format("svg");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Roboto";
  src: url("../../assets/fonts/Roboto/Roboto-MediumItalic.eot");
  src: url("../../assets/fonts/Roboto/Roboto-MediumItalic.eot?#iefix") format("embedded-opentype"),
    url("../../assets/fonts/Roboto/Roboto-MediumItalic.woff2") format("woff2"),
    url("../../assets/fonts/Roboto/Roboto-MediumItalic.woff") format("woff"),
    url("../../assets/fonts/Roboto/Roboto-MediumItalic.ttf") format("truetype"),
    url("../../assets/fonts/Roboto/Roboto-MediumItalic.svg#Roboto-MediumItalic") format("svg");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Roboto Mono";
  src: url("../../assets/fonts/Roboto/RobotoMono-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
