// Global annotations
////
/// @group journeys
/// @access private
////

$legal-entity-search-card-body-width: 31.25rem !default;
$legal-entity-search-box-min-width: 16rem !default;
$legal-entity-search-switch-min-width: 8.75rem !default;

.legal-entity-list {
  .bb-card__body {
    @include media-breakpoint-down(md) {
      width: 100%;
    }

    width: $legal-entity-search-card-body-width;
  }

  bb-search-box-ui {
    min-width: $legal-entity-search-box-min-width;
    width: 100%;
  }

  bb-switch-ui {
    min-width: $legal-entity-search-switch-min-width;
  }
}
