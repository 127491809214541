// Global annotations
////
/// @group deprecated
/// @access private
////

// TODO: moved here from _card.scss, find a better solution for this
.card-account-balance {
  flex: 3;
}
