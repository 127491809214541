.dso-area-logo {
  .dso-logo-image {
    padding-top: 1.6rem;
    padding-bottom: 1.4rem;

    @media (min-width: $responsive-md + 1) {
      padding-top: 2.4rem;
      padding-bottom: 2rem;
    }
  }
}

bb-search-box-ui {
  display: block;
  padding-left: 0;
  padding-right: 1rem;
  flex-grow: 1;
  margin-top: 0.2rem;

  @media (min-width: $responsive-md + 1) {
    margin-bottom: 1rem;
    margin-top: 0;
    flex-grow: initial;
  }

  .bb-search-box--has-search {
    border: none;
  }

  .dropdown-menu {
    left: 0 !important;
    overflow: scroll;
    min-width: 100%;
  }
}

bb-sidebar-search {
  input {
    text-transform: capitalize;
  }
}
