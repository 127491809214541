// Global annotations
////
/// @group journeys
/// @access private
////

$legal-entities-cag-list-empty-state-height: 10 * $spacer !default;
$legal-entities-cag-list-empty-state-background-color: $color-background-surface-2 !default;
$legal-entities-cag-list-header-margin-end: $spacer-lg !default;
$legal-entities-cag-list-width: 40% !default;
$legal-entities-cag-search-header-margin-block: 0 $spacer-lg !default;

$legal-entities-cag-table-padding-start: $sizer-md !default;
$legal-entities-cag-table-padding-vertical: $sizer-md !default;
$legal-entities-cag-table-padding-font-size: $subtitle-font-size !default;
$legal-entities-cag-table-name-min-width: $spacer-xl !default;
$legal-entities-cag-table-info-max-width: 80% !default;

.bb-legal-entities-cag-list__header {
  justify-content: space-between;
  align-items: center;
  .bb-legal-entities-cag-list__header--margin-end {
    margin-inline-end: $legal-entities-cag-list-header-margin-end;
  }
}

.bb-legal-entity-cag__search-input {
  display: block;
  width: $legal-entities-cag-list-width;
}

.bb-legal-entity-cag__search-header {
  justify-content: space-between;
  align-items: center;
  margin-block: $legal-entities-cag-search-header-margin-block;
}

.bb-legal-entities-cag-list__empty-state {
  width: 100%;
  height: $legal-entities-cag-list-empty-state-height;
  background-color: $legal-entities-cag-list-empty-state-background-color;
}

.bb-legal-entities-table__item {
  padding-inline-start: $legal-entities-cag-table-padding-start;
  padding-block: $legal-entities-cag-table-padding-vertical;
  font-size: $legal-entities-cag-table-padding-font-size;
  line-height: $subtitle-line-height;
  .bb-legal-entities-table__name {
    padding-inline-end: $sizer-sm;
    min-width: $legal-entities-cag-table-name-min-width;
  }
  .bb-legal-entities-table__info {
    max-width: $legal-entities-cag-table-info-max-width;
  }
}
