// Variables provided by Bootstrap
$alert-padding-x: $sizer-md !default;
$alert-padding-y: $sizer-md !default;
$alert-border-radius: $border-radius !default;
$alert-margin-bottom: $spacer-md !default;
$alert-border-width: 1px !default;

// Variables provided by Backbase
$alert-box-shadow: $box-shadow-sm !default;
$alert-dismissible-font-weight: normal !default;
$alert-dismissible-padding-right: 3.5rem !default;
$alert-close-padding: $alert-padding-y $alert-padding-x !default;
$alert-icon-margin-right: $spacer-sm !default;
$alert-description-margin-top: $spacer-xs !default;
