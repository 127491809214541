// Global annotations
////
/// @group journey-specific
/// @access private
////

$bucket-spacing-lg: $spacer-xl !default;
$bucket-spacing: $spacer-lg !default;

/// Nested Accounts - accounts list UI
///
/// @example html
/// <div
///  class="bb-stack bb-accounts-bucket" >
/// <div class="bb-stack__item"> </div>
/// <div class="bb-stack__item"> </div>
/// </div>

.bb-accounts-bucket {
  position: relative;
  margin-bottom: $bucket-spacing;
  padding-bottom: $bucket-spacing;
  margin-top: $bucket-spacing-lg;
  border-bottom: $list-separator-line-width solid $list-separator-color;
}
