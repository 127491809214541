// Global annotations
////
/// @group widgets
/// @access private
////
/// @name bb-product-summary-account-logo
///
/// @example html - Image logo:
/// <img class="bb-product-summary-account-logo__img"/>
/// @example html - Card provider logo:
/// <bb-card-vendor-ui class="bb-product-summary-account-logo__card-provider"></bb-card-vendor-ui>

.bb-product-summary-account-logo__img {
  max-width: 100%;
  max-height: 100%;
}

.bb-product-summary-account-logo__card-provider .bb-card-vendor {
  @extend .bb-product-summary-account-logo__img;
  height: auto;
}
