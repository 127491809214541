// Global annotations
////
/// @group widgets
/// @access private
////

$bb-instrument-table-spacer: 0.9375rem;

.bb-instrument-table .bb-instrument__mobile-table thead th.bb-instrument-table-font {
  padding-block-start: 0;
  padding-block-end: $spacer-sm;
  font-size: $font-size-table-header;
  line-height: $line-height-base;
  font-weight: $font-weight-semi-bold;
  color: $color-foreground-default;

  &:first-child {
    padding-inline-start: $spacer-xs;
  }

  &:last-child {
    padding-inline-end: $spacer-xs;
  }
}

.bb-wealth-shared-instrument-table {
  .bb-stack__item--align-center {
    align-items: center;
  }

  .bb-instrument-default-gap {
    gap: $spacer-md;
  }

  .bb-instrument-sm-gap {
    gap: $spacer-xs;
  }

  .bb-instrument-base-font {
    font-size: $font-size-base;
    line-height: $font-size-highlight;
    color: $color-foreground-default;
  }

  .bb-instrument__desktop-view {
    padding: $bb-instrument-table-spacer $spacer-md;

    &:first-child {
      display: table-cell;
      padding-inline-start: $spacer-xs;
    }

    &:last-child {
      padding-inline-end: $spacer-xs;
    }
  }

  .bb-w-60 {
    width: 60%;
  }

  .bb-w-40 {
    width: 40%;
  }

  .-bb-mt-2 {
    margin-block-start: -$spacer-sm;
  }
}

.bb-instrument-table-responsive
  .bb-instrument-table
  .bb-instrument__mobile-table.table-pagination
  tr:last-child {
  border-block-end: 1px solid $color-border-subtle;
}

@include media-breakpoint-down(md) {
  .bb-instrument__desktop-view {
    display: none;
  }

  .bb-instrument__mobile-table-view {
    display: table-cell;
  }

  .bb-instrument-table-responsive
    .bb-instrument__mobile-table-view
    .bb-instrument-table
    .bb-instrument__mobile-table
    tbody
    td::before {
    width: calc(100vw - 5.1rem);
  }
}

@include media-breakpoint-up(md) {
  .bb-instrument__mobile-table-view {
    display: none;
  }
}

.bb-instrument-table-responsive {
  &.right {
    thead tr th,
    tbody tr td {
      text-align: end;

      &:first-child {
        text-align: start;
      }

      &:not(:first-child) {
        white-space: nowrap;
      }
    }
  }

  &.left {
    thead tr th,
    tbody tr td {
      text-align: start;
    }
  }

  .bb-instrument-table-row {
    cursor: pointer;

    &:hover {
      background-color: $color-background-surface-1-hovered;
    }

    &.bb-instrument__disable-hover {
      &:hover {
        cursor: default;
        background-color: $color-background-on-color;
      }
    }
  }

  td.bb-instrument__mobile-table-view {
    tbody tr,
    tbody th,
    tbody td {
      padding: $bb-instrument-table-spacer $spacer-xs;
    }

    tbody tr {
      border-block-start: 0;
      border-block-end: 0;
    }

    &.bb-px-0 {
      padding: 0;
    }
  }
}

.bb-instrument-mobile-table-alignment {
  .instrument {
    width: 100%;
    text-align: start;
  }

  &:has(.bb-instrument__watch-list-icon) {
    .instrument {
      width: 80%;
    }

    .bb-instrument__watch-list-icon {
      width: 20%;
      text-align: end;
      margin: auto;
    }
  }
}

.bb-instrument-table .bb-instrument__mobile-table tbody > tr > th {
  border: 0;
}

.bb-instrument-table .bb-instrument__mobile-table tbody > tr > td {
  vertical-align: top;
}

// Styles for digital-investing
.bb-digital-investing-asset__item-name-column {
  max-width: 220px;
}

.bb-instrument__asset-column-max-width {
  max-width: 300px;
}

.bb-table-content-height {
  height: 2.625rem;
}
