// Global annotations
////
/// @group widgets
/// @access private
////

$cards-travel-notice-sizer: $sizer-sm !default;
$cards-travel-notice-create-review-label-font-size: $small-font-size !default;
$cards-travel-notice-create-contact-disclaimer-label-font-size: $small-font-size !default;
$cards-travel-notice-create-contact-disclaimer-label-color: $color-foreground-support !default;
$cards-travel-notice-list-empty-state-label-color: $color-foreground-support !default;
$cards-travel-notice-create-card-group-min-width: 45rem !default; // IE compatibility
$cards-travel-notice-count-label-font-size: $font-size-subtitle;
$cards-travel-notice-list-expired-color: $color-foreground-support !default;

.bb-create-travel-notice__cards-count {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-inline-end: $sizer;
  font-size: $cards-travel-notice-count-label-font-size;
}

.bb-create-travel-notice__cards-count--xs-top {
  @include media-breakpoint-down(sm) {
    order: -1;
    padding-inline-end: 0;
  }
}

.bb-create-travel-notice-card-group__payment-card {
  padding-inline-start: $cards-travel-notice-sizer;
}
