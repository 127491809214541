.bb-ccla-outcome-page {
  top: $layout-topbar-height;
  left: 0;
  height: calc(100% - #{$layout-topbar-height});

  &__side-background {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  @include media-breakpoint-down(sm) {
    &__action-container {
      width: auto;
      margin: 0 -#{$spacer} -#{$spacer-lg} -#{$spacer};
      padding: $spacer-xl $spacer;
      background: linear-gradient($gradient-angle-5, #fff0, $color-background-surface-1 14.55%);
    }
  }
}
