// Global annotations
////
/// @group widgets
/// @access private
////

.bb-consent-list__item {
  padding-right: $spacer;
  padding-left: $spacer;
}

.bb-consent-item__date-header {
  font-weight: $font-weight-semi-bold;
}
