// Global annotations
////
/// @group widgets
/// @access private
////

.bb-trading-periods .bb-button-bar__button {
  font-size: $font-size-subheader;
  border-radius: $border-radius-xs;

  &:last-of-type {
    margin-inline-start: 0;
  }

  &:focus::before {
    border-radius: $border-radius-xs;
  }
}
