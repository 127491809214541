// Global annotations
////
/// @group widgets
/// @access private
////

.batch-upload__container {
  margin: $spacer-xl 0;
}

.batch-upload__title {
  margin-bottom: $spacer-xl;
}

.batch-upload__type-item-label {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  cursor: pointer;
}

@include media-breakpoint-down(sm) {
  .batch-upload__dropdown-max-size {
    max-height: 40vh;
    max-width: 90vw;
  }
}
