bb-explore-products {
  display: flex;
  flex-flow: column;
  align-items: center;

  .explore-products__container {
    @include media-breakpoint-up(xl) {
      // container width for one and two cards
      &:has(.product-cards > :only-child),
      &:has(.product-cards > :last-child:nth-child(2)) {
        max-width: 770px;
      }

      // one and two cards are displayed in two columns
      .product-cards:has(> :only-child),
      .product-cards:has(> :last-child:nth-child(2)) {
        @include row-cols(2);
      }

      // three cards and more are displayed in three columns
      .product-cards:has(> :last-child:nth-child(n + 3)) {
        @include row-cols(3);
      }
    }

    @include media-breakpoint-up(md) {
      &:has(.product-cards > :only-child) {
        .explore-products__card {
          max-width: 370px;
          box-sizing: content-box;
        }
      }
    }

    @include media-breakpoint-only(lg) {
      max-width: 770px;
    }

    @include media-breakpoint-only(md) {
      max-width: 700px;
    }

    @include media-breakpoint-only(sm) {
      max-width: 370px;
    }

    @include media-breakpoint-down(sm) {
      .product-cards {
        width: 100%;
      }
    }
  }

  .explore-products__container--fullwidth {
    &:has(.product-cards > :only-child) {
      .explore-products__card {
        max-width: 504px;
        width: auto;
      }
    }

    @include media-breakpoint-only(xxl) {
      & .explore-products__card {
        max-width: 504px;
      }
    }

    @include media-breakpoint-only(sm) {
      & .product-cards-container {
        max-width: 504px;
      }
    }
  }
}
