// Global annotations
////
/// @group widgets
/// @access private
////

$cards-management-journey-heading-padding-x: 0 !default;
$cards-management-journey-card-product-container-gap: $spacer-xl !default;
$cards-management-journey-card-overview-container-gap: $spacer * 3 !default;
$cards-management-journey-card-overview-card-reserved-space: 20rem !default;
$cards-management-journey-card-info-container-gap: $spacer-xl !default;

.bb-cards-management-journey-card-product__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
  gap: $cards-management-journey-card-product-container-gap;

  @include media-breakpoint-down(md) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.bb-manage-cards-details__card-overview-container {
  display: grid;
  grid-template-columns: $cards-management-journey-card-overview-card-reserved-space 1fr;
  gap: $cards-management-journey-card-overview-container-gap;

  @include media-breakpoint-down(md) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.bb-manage-cards-details__card-info {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: $cards-management-journey-card-info-container-gap;
}

.bb-manage-cards-details__card-info-item--align-bottom {
  align-self: end;
}
