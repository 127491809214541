@include media-breakpoint-down(md) {
  .bb-financial-data {
    &.table-responsive .table tbody {
      td::before,
      td::after {
        content: unset;
      }
    }

    .bb-visually-hidden-md-down {
      @include visually-hidden();
    }
  }
}
