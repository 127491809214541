@mixin text-emphasis-variant($parent, $color, $ignore-warning: false) {
  #{$parent} {
    color: $color !important;
  }
  @if $emphasized-link-hover-darken-percentage != 0 {
    a#{$parent} {
      &:hover,
      &:focus {
        color: darken($color, $emphasized-link-hover-darken-percentage) !important;
      }
    }
  }
  @include deprecate("`text-emphasis-variant()`", "v4.4.0", "v5", $ignore-warning);
}

@mixin bg-variant($parent, $color, $ignore-warning: false) {
  #{$parent} {
    background-color: $color !important;
  }
  a#{$parent},
  button#{$parent} {
    &:hover,
    &:focus {
      background-color: darken($color, 10%) !important;
    }
  }
  @include deprecate("The `bg-variant` mixin", "v4.4.0", "v5", $ignore-warning);
}

.position-relative {
  position: relative !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
