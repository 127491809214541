.example-ui {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: $sizer-sm;
  background: $color-neutral-white;

  &__component-col {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 0.75 0 0;
    align-self: stretch;
    background: $color-neutral-lighter;
    min-height: 250px;
  }

  &__configuration-col {
    display: flex;
    flex-direction: column;
    flex: 0.75 0 0;
    align-self: stretch;
    background: $color-neutral-lighter;
  }

  @include media-breakpoint-up(sm) {
    flex-direction: row;
  }
}

.example-nav {
  .nav-item {
    padding: $sizer-sm;
    margin: 0;

    .nav-link {
      color: $color-neutral-greyest !important;

      &.active {
        color: $color-neutral-darker !important;
      }
    }
  }
  .nav-tabs > .nav-item > .nav-link.active:after {
    background-color: $color-neutral-darker;
  }

  .nav-tabs {
    border-bottom-width: 0;
  }
}

.example-usage {
  background: $color-neutral-lighter;
  border: $border;
  border-radius: $border-radius * 0.5;
  min-height: 50px;
  padding: $sizer-lg;
}
