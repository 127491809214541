// Global annotations
////
/// @group forms
/// @access private
////

$input-validation-spacer: $spacer-sm !default;

.bb-input-validation-message {
  display: block;
  margin-top: $input-validation-spacer;
  color: $color-foreground-danger;
  font-size: $font-size-subtitle;
  @include clearfix;
}

.bb-input-validation-message__icon {
  margin-inline-end: $spacer-xs;
  float: left;

  [dir="rtl"] & {
    float: right;
  }
}
