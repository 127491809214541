.home {
  &--colored {
    background-color: $color-neutral-lighter;
    padding: $sizer-xxl $sizer;

    @include media-breakpoint-up(sm) {
      padding-inline: unset;
    }

    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(2, 1fr);
      padding-block: $sizer-xxl;
    }

    @include media-breakpoint-up(xl) {
      grid-template-columns: repeat(3, 1fr);
      padding-block: $sizer * 5;
    }
  }

  &--dark {
    background-color: $color-neutral-darker;
    overflow: hidden;

    color: $color-neutral-white;

    @include media-breakpoint-up(sm) {
      border-end-end-radius: $border-radius * 11.25;
    }
  }

  // reusable utility classes
  &__panel {
    box-shadow: $box-shadow-sm;
    border-radius: calc($border-radius / 8);
  }

  &__title {
    font-size: $font-size-base * 1.875; // 30px
    font-weight: $font-weight-semi-bold;
    line-height: 2.375rem;
    margin-block-end: $spacer * 2.5;
  }

  // hero section
  &__hero {
    display: grid;
    padding: $spacer-lg;

    @include media-breakpoint-up(lg) {
      grid-template-columns: 460px auto;
      padding: unset;
    }
  }

  &__hero-image {
    align-self: flex-end;

    @include media-breakpoint-only(xs) {
      grid-row: 3 / 4;
      margin-block-end: $spacer-xl;
    }

    @include media-breakpoint-up(lg) {
      grid-column: 2 / 3;
      grid-row: 1 / 4;
      margin-block-start: $spacer-xl;
    }
  }

  &__hero-title {
    color: $color-neutral-white;
    font-size: $font-size-hero;

    display: flex;
    flex-direction: column;
    margin-block-end: $spacer;

    @include media-breakpoint-up(sm) {
      font-size: $font-size-base * 3.75;
    }

    @include media-breakpoint-up(lg) {
      margin-block-start: $spacer * 2.5;
    }

    &--small {
      color: $color-neutral-greyer;
      font-size: $font-size-h2;
      font-weight: 500;
    }
  }

  &__hero-text {
    font-size: $font-size-subtitle;
    line-height: 1.5rem;
    margin-block-end: $spacer-xl;
  }

  &__hero-action {
    border-radius: unset; // revert bbbutton radius
    font-size: $font-size-subtitle;
    width: 100%;

    &:not(.btn-unstyled):focus::before {
      border-radius: unset;
    }

    @include media-breakpoint-up(sm) {
      width: initial;
    }

    @include media-breakpoint-up(lg) {
      margin-block-end: $spacer * 5;
    }
  }

  // main section
  &__main {
    display: grid;
    gap: $spacer-lg;
    padding: $sizer-xxl $sizer;

    @include media-breakpoint-up(sm) {
      padding-inline: unset;
    }

    @include media-breakpoint-up(md) {
      padding-block: $sizer-xxl;
    }

    @include media-breakpoint-up(xl) {
      grid-template-columns: repeat(2, 1fr);
      padding-block: $sizer * 5;
    }
  }

  &__main-panel {
    @extend .home__panel;

    color: $color-neutral-white;
    background-color: $color-neutral-darker;
    background-size: cover;

    padding: $sizer-xxl $sizer-xl;

    @include media-breakpoint-up(sm) {
      padding: $sizer * 3.5;
    }

    &:first-child {
      @include media-breakpoint-up(xl) {
        border-start-end-radius: $border-radius * 2.5;
      }
    }

    &--design-tokens {
      background-image: url("../../../assets/images/pages/home-design-tokens.png");
    }

    &--components {
      background-image: url("../../../assets/images/pages/home-components.png");
    }

    & > * {
      max-width: 300px;
    }
  }

  &__main-panel-title {
    color: $color-neutral-white;
    margin-block-end: $spacer-lg;
  }

  &__main-panel-text {
    color: $color-neutral-white;
    font-size: $font-size-subtitle;
    margin-block-end: $spacer * 2.5; // 40px
  }

  &__main-panel-action {
    font-size: $font-size-subtitle;
    border-radius: unset; // revert bbbutton radius

    &:not(.btn-unstyled):focus::before {
      border-radius: unset;
    }
  }

  // sub-section
  &__sub-section {
    display: grid;
    gap: $spacer-lg;

    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media-breakpoint-up(xl) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__sub-section-panel {
    @extend .home__panel;

    background-color: $color-neutral-white;
    padding: $sizer-xl;

    &:nth-child(3n) {
      border-start-end-radius: $border-radius * 2.5;

      @include media-breakpoint-between(md, lg) {
        grid-column: span 2;
      }
    }
  }

  &__sub-section-panel-title {
    font-size: $font-size-highlight;
    font-weight: $font-weight-semi-bold;
    line-height: 2.375rem;
    margin-block: $spacer $spacer-sm;
  }

  &__sub-section-panel-text {
    color: $color-neutral-dark;
    font-size: $font-size-subtitle;
    line-height: 1.5rem;
    margin-block-end: $spacer-xl;
  }

  &__sub-section-panel-link {
    display: block;

    padding-block: $sizer-sm;
    padding-inline-end: $sizer-lg;

    font-size: $font-size-subtitle;
    font-weight: $font-weight-semi-bold;
    line-height: 1.5rem;
  }
}
