// Global annotations
////
/// @group widgets
/// @access private
////

.bb-valuation-line-chart {
  --valuation-line-chart-color-start: color-mix(
    in srgb,
    #{$color-background-brand},
    transparent 7%
  );
  --valuation-line-chart-color-end: color-mix(in srgb, #{$color-background-brand}, transparent 70%);
  --valuation-line-chart-tooltip-area-fill: #{$color-background-brand};
}

.bb-valuation-line-chart .area-chart .vertical-grid-line {
  stroke: $color-border-default;
}

.bb-valuation-line-chart .area-series .area {
  fill: url("#valuationLineChartFill");
}

.bb-valuation-line-chart .circle-series circle {
  fill: $color-on-background-brand-subtle;
}

.bb-valuation-line-chart .line-series .line {
  stroke: $color-background-brand;
  stroke-width: 2px;
}

.bb-valuation-line-chart .y.axis .gridline-path {
  stroke: $color-border-subtle;
}
