// Global annotations
////
/// @group widgets
/// @access private
////

.tooltip-area {
  opacity: 0;
  cursor: auto;
}
