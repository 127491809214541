// Global annotations
////
/// @group journey-specific
/// @access private
////

bb-ds-risk-assessment-results-task-view-ang {
  .cm-risk-assessment-info-box {
    background-color: $color-background-surface-2;
  }

  .cm-risk-assessment-results-tile {
    background-color: $color-background-surface-2;
    border-radius: $border-radius-sm;

    .cm-risk-assessment-results-tile__label {
      font-size: 0.75rem;
    }

    bb-collapsible-card-ui {
      .bb-card__header {
        padding: $spacer-md 0 !important;
      }

      .bb-card__body {
        padding: $spacer-sm 0 !important;
      }

      .bb-icon-expand-more,
      .bb-icon-expand-less {
        color: $color-foreground-disabled;
      }
    }
  }

  .cm-risk-assessment-results-expand-icon {
    color: $color-foreground-disabled;
  }

  .dot {
    width: $sizer-sm;
    height: $sizer-sm;
    border-radius: $sizer-md;
    background-color: $color-foreground-disabled;

    &.dot-low {
      background-color: $color-foreground-success;
    }

    &.dot-high {
      background-color: $color-foreground-danger;
    }

    &.dot-medium {
      background-color: $color-foreground-warning;
    }
  }
}
