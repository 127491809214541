// Global annotations
////
/// @group widgets
/// @access private
////

.bb-segmented-chart__bar {
  height: $sizer-sm;
  border-radius: 1.25rem;
  width: 0%;
  transition: width 0.5s ease-in-out;
  transition-delay: 0.5s;
}

.bb-segmented-chart__dot {
  width: $sizer-sm;
  height: $sizer-sm;
  border-radius: 0.125rem;
}

/* default colors */
.color-background-category-1 {
  background-color: $color-background-category-1;
}

.color-background-category-2 {
  background-color: $color-background-category-2;
}

.color-background-category-3 {
  background-color: $color-background-category-3;
}

.color-background-category-4 {
  background-color: $color-background-category-4;
}

.color-background-category-5 {
  background-color: $color-background-category-5;
}

.color-background-category-6 {
  background-color: $color-background-category-6;
}

.color-background-category-7 {
  background-color: $color-background-category-7;
}

.color-background-category-8 {
  background-color: $color-background-category-8;
}

.color-background-category-9 {
  background-color: $color-background-category-9;
}

.color-background-category-10 {
  background-color: $color-background-category-10;
}

/* asset_class */
.color-background-alternative {
  background-color: $color-background-category-7;
}

.color-background-fixed-income {
  background-color: $color-background-category-1;
}

.color-background-equity {
  background-color: $color-background-category-2;
}

.color-background-real-state {
  background-color: $color-background-category-6;
}

.color-background-cash {
  background-color: $color-background-category-9;
}

.color-background-others {
  background-color: $color-background-category-10;
}
