// Global annotations
////
/// @group journeys
/// @access private
////

$bb-market-order-sticky-footer-margin-bottom: $sizer * 8.75 !default; // 140px

.bb-market-order-sticky-footer-padding-bottom {
  padding-bottom: $bb-market-order-sticky-footer-margin-bottom;
}
