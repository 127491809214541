// Global annotations
////
/// @group utilities
/// @access private
////

.highlight {
  font-weight: $font-weight-semi-bold;
}

.break-word {
  white-space: normal;
  word-wrap: break-word;
  word-break: break-all; // IE does not support break-word :(
  word-break: break-word;
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

.rb-sidebar a:hover {
  text-decoration: none;
}

//Convenience template-driven text classes smaller/larger sizing (for example: business widgets)
.text-small {
  font-size: $font-size-sm;
}

.text-large {
  font-size: $font-size-lg;
}

.contextual-alternates-off {
  font-feature-settings: "calt" 0;
}
