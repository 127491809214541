// Global annotations
////
/// @group journeys
/// @access private
///

$entitlements-approval-level-select-container-border-radius: $border-radius * 0.5 !default;
$entitlements-approval-level-select-dropdown-panel-border-radius: $border-radius !default;

// invalid state of form control
bb-approval-level-select-input.ng-invalid {
  .bb-approval-level-select-input {
    &.ng-select {
      &.ng-select-focused > .ng-select-container,
      .ng-select-container {
        border-color: $input-invalid-border-color;
      }
    }
  }
}

// default state of form control
.bb-approval-level-select-input {
  .ng-value {
    width: 100%;
  }

  &.ng-select {
    .ng-select-container {
      @extend .form-control;
      display: flex;
      padding: 0;
      border-radius: $entitlements-approval-level-select-container-border-radius;
    }

    .ng-arrow-wrapper {
      display: flex;
      width: $sizer * 1.875;

      .ng-arrow {
        display: none;
      }

      &:before {
        @include material-icons();
        content: "keyboard_arrow_down";
      }
    }

    &.ng-select-disabled {
      & > .ng-select-container {
        background-color: $color-background-page;
        color: $color-foreground-disabled;
        cursor: not-allowed;
      }
    }

    &.ng-select-opened {
      &.ng-select-bottom > .ng-select-container {
        border-end-end-radius: $entitlements-approval-level-select-container-border-radius;
        border-end-start-radius: $entitlements-approval-level-select-container-border-radius;
      }

      .ng-arrow-wrapper:before {
        @include material-icons();
        content: "keyboard_arrow_up";
      }
    }

    .ng-dropdown-panel {
      box-shadow: $box-shadow-md;

      &.ng-select-bottom {
        border: none;
        box-shadow: $box-shadow-md $box-shadow-md-color;
        border-radius: $entitlements-approval-level-select-dropdown-panel-border-radius;
        margin-top: $sizer-xs;

        .ng-dropdown-header + .ng-dropdown-panel-items {
          border-start-start-radius: 0;
          border-start-end-radius: 0;
        }

        .ng-dropdown-panel-items {
          border-start-start-radius: $entitlements-approval-level-select-dropdown-panel-border-radius;
          border-start-end-radius: $entitlements-approval-level-select-dropdown-panel-border-radius;
          border-end-start-radius: $entitlements-approval-level-select-dropdown-panel-border-radius;
          border-end-end-radius: $entitlements-approval-level-select-dropdown-panel-border-radius;
          padding: $sizer-sm 0;
          max-height: 13.875rem;

          .ng-option {
            &.ng-option-marked {
              background-color: $color-background-surface-1-hovered;
            }

            &.ng-option-selected {
              background-color: $color-background-selected;
            }

            &.ng-option-disabled {
              color: $input-disabled-color;
              display: flex;
              justify-content: center;
            }
          }

          &:has(+ .ng-dropdown-footer) {
            border-end-end-radius: 0;
            border-end-start-radius: 0;
          }
        }
      }
    }

    // overwrite ng-select focus state
    &.ng-select-focused {
      &:not(.ng-select-opened) > .ng-select-container {
        overflow: visible;
        box-shadow: $account-selector-focus-box-shadow;
        border-color: $color-border-brand;

        > .ng-value-container::before {
          position: absolute;
          inset: $account-focus-outline-distance;
          border: $account-focus-border-primary;
          border-radius: $account-selector-border-radius;
          content: "";
        }
      }
    }

    .ng-dropdown-header {
      height: $sizer * 2.5;
      border-start-start-radius: $entitlements-approval-level-select-dropdown-panel-border-radius;
      border-start-end-radius: $entitlements-approval-level-select-dropdown-panel-border-radius;
      display: flex;
      align-items: center;
      justify-content: center;
      border-block-end: none;
    }

    .ng-dropdown-footer {
      height: $sizer * 1.5;
      border-end-end-radius: $entitlements-approval-level-select-container-border-radius;
      border-end-start-radius: $entitlements-approval-level-select-container-border-radius;
      display: flex;
      align-items: center;
      justify-content: center;
      border-block-start: none;
    }
  }

  // dropdown option styles
  .dropdown-option-item {
    position: relative;
  }

  .approval-item-alias {
    margin-right: $sizer * 0.375;
  }
}

// loading state
.approval-level-loading-state-container {
  @extend .form-control;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
