// Global annotations
////
/// @group components
/// @access private
////

/// Adds classes for login journey in business productised applications.
/// This is still a wip and we need to discuss with retail how we will implement.

$login-card-wrapper-padding: $sizer-xxl !default;
$login-card-offset: 13vw !default;
$login-card-width: 37rem !default;

.bb-login__background-container--fill {
  display: block;
  height: 100vh;
}

@media only screen and (min-width: 600px) {
  .bb-login__user {
    top: 25vh;
    left: $login-card-offset;
    width: $login-card-width;
  }

  .bb-login__select-context {
    top: 10vh;
    left: $login-card-offset;
    width: $login-card-width;
  }
}

@media only screen and (max-width: 600px) {
  .bb-login__user {
    transform: translate(0%, 30%);
  }
  .bb-login__select-context {
    transform: translate(0%, 20%);
  }
}

.bb-login__user-body {
  padding: $login-card-wrapper-padding * 2;
}
