// Global annotations
////
/// @group ui-components
/// @access private
////

.bb-input-timepicker {
  .btn-outline-primary {
    $button-color-map: map-get($theme-button-colors, "secondary");

    @include button-variant(
      map-get($button-color-map, "default-bg"),
      map-get($button-color-map, "default-border"),
      map-get($button-color-map, "color"),
      map-get($button-color-map, "hover-bg"),
      map-get($button-color-map, "hover-border"),
      map-get($button-color-map, "hover-color"),
      map-get($button-color-map, "active-bg"),
      map-get($button-color-map, "active-border"),
      map-get($button-color-map, "active-color"),
      map-get($button-color-map, "disabled-bg"),
      map-get($button-color-map, "disabled-border"),
      map-get($button-color-map, "disabled-color")
    );
  }
}
