// Global annotations
////
/// @group journey-specific
/// @access public
////

ac-application-overview {
  @include media-breakpoint-up(lg) {
    .col-lg-custom-left {
      flex: 0 0 auto;
      width: 37.4%;
    }

    .col-lg-custom-right {
      flex: 0 0 auto;
      width: 62.6%;
    }
  }
}

ac-action-item .btn:not(.btn-unstyled):focus::before {
  border-radius: $border-radius * 1.5;
}

[dir="rtl"]bb-icon-ui.bb-ac-icon-flip-dir-rtl {
  transform: scaleX(-1);
}
