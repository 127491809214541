// Global annotations
////
/// @group forms
/// @access private
////

/// Set the box-shadow for focused search box
$search-box-focus-box-shadow: $input-focus-box-shadow !default;

/// Set the border for focused search box
$search-box-focus-border: $input-focus-border !default;

$search-box-small-search-icon-vertical-adjustment: 2px !default;

$search-box-button-hover-color: $color-link-hovered !default;

.bb-search-box-visible {
  .bb-search-box--has-search .bb-search-box--has-clear input {
    &[type="search"] {
      &::-webkit-search-decoration,
      &::-webkit-search-results-button,
      &::-webkit-search-results-decoration {
        -webkit-appearance: none;
      }
    }
  }
  &.input-group {
    > button {
      &:nth-child(n):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(
          .invalid-tooltip
        ):not(.invalid-feedback) {
        color: $search-box-button-color;

        bb-icon-ui {
          vertical-align: bottom;
        }
      }
    }
  }

  &:not(.bb-search-box--has-clear) .bb-search-box__input {
    @include border-inline-end-radius($search-box-border-radius);
    &.open {
      @include border-inline-end-radius($search-box-border-radius);
    }
  }

  &:not(.bb-search-box--has-search) .bb-search-box__input {
    @include border-inline-start-radius($search-box-border-radius);
  }
}

.bb-search-box--has-search.input-group {
  input {
    padding-inline-start: $search-box-padding-horizontal;
  }

  button:not(:last-child):not(.dropdown-menu):not(.dropdown-item):not(.valid-tooltip):not(
      .valid-feedback
    ):not(.invalid-tooltip):not(.invalid-feedback) {
    @include border-inline-start-radius($search-box-border-radius);
    padding-inline: $search-box-button-padding-horizontal;

    &.btn-tertiary:not(:disabled):not(.disabled):hover {
      color: $search-box-button-hover-color;
      background: $search-box-button-background-color;
    }

    &.btn-tertiary:not(:disabled):not(.disabled):focus {
      color: $search-box-button-hover-color;
      background: $search-box-button-background-color;
    }

    &.btn:focus::before {
      @include border-inline-end-radius(0);
      inset-inline-end: $search-focus-outline-distance * 0.25;
    }
  }

  &.bb-search-box--readonly {
    button:not(.dropdown-menu):not(.dropdown-item):not(.valid-tooltip):not(.valid-feedback):not(
        .invalid-tooltip
      ):not(.invalid-feedback) {
      background-color: $input-disabled-background-color;

      &.btn-tertiary:not(:disabled):not(.disabled):hover {
        background-color: $input-disabled-background-color;
      }

      &.btn-tertiary:not(:disabled):not(.disabled):focus {
        background-color: $input-disabled-background-color;
      }
    }
  }

  // Bootstrap 5 overrides
  // These overrides styles in https://github.com/twbs/bootstrap/blob/v5.3.0-alpha1/scss/forms/_input-group.scss#L92
  // which causes rounded corner mid-input box for RTL
  input:last-child:not(.dropdown-menu):not(.dropdown-item):not(.valid-tooltip):not(
      .valid-feedback
    ):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: 0;
    @include border-inline-end-radius($search-box-border-radius);
    @include border-inline-start-radius(0);
  }

  &.squared {
    button:not(:last-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(
        .invalid-tooltip
      ):not(.invalid-feedback) {
      @include border-inline-start-radius($border-radius-sm);
    }

    input:last-child:not(.dropdown-menu):not(.dropdown-item):not(.valid-tooltip):not(
        .valid-feedback
      ):not(.invalid-tooltip):not(.invalid-feedback) {
      @include border-inline-end-radius($border-radius-sm);
      @include border-inline-start-radius(0);
    }
  }
}

.bb-search-box--has-clear.input-group {
  .bb-search-box__input {
    padding-inline-end: $search-box-padding-horizontal;
  }

  button:not(.dropdown-menu):not(.dropdown-item):not(.valid-tooltip):not(.valid-feedback):not(
      .invalid-tooltip
    ):not(.invalid-feedback) {
    padding-inline: $search-box-button-padding-horizontal;

    &.btn-tertiary:not(:disabled):not(.disabled):hover {
      color: $search-box-button-hover-color;
      background: $search-box-button-background-color;
    }

    &.btn-tertiary:not(:disabled):not(.disabled):focus {
      color: $search-box-button-hover-color;
      background: $search-box-button-background-color;
    }

    &.btn:last-child::before {
      @include border-inline-start-radius(0);
      inset-inline-start: $search-focus-outline-distance * 0.25;
    }

    &:not(:last-child):not(:first-child) {
      @include border-inline-end-radius(0);
      @include border-inline-start-radius(0);
    }
    &:not(:first-child) {
      @include border-inline-end-radius($search-box-border-radius);
    }
  }

  // Bootstrap 5 overrides
  // These overrides styles in https://github.com/twbs/bootstrap/blob/v5.3.0-alpha1/scss/forms/_input-group.scss#L92
  // which causes rounded corner mid-input box for RTL
  input:first-child:not(.dropdown-menu):not(.dropdown-item):not(.valid-tooltip):not(
      .valid-feedback
    ):not(.invalid-tooltip):not(.invalid-feedback) {
    @include border-inline-start-radius($search-box-border-radius);
    @include border-inline-end-radius(0);
  }

  &.squared {
    button:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(
        .invalid-tooltip
      ):not(.invalid-feedback) {
      @include border-inline-end-radius($border-radius-sm);
    }

    input:first-child:not(.dropdown-menu):not(.dropdown-item):not(.valid-tooltip):not(
        .valid-feedback
      ):not(.invalid-tooltip):not(.invalid-feedback) {
      @include border-inline-start-radius($border-radius-sm);
      @include border-inline-end-radius(0);
    }
  }
}

.bb-search-box__search-button {
  margin-block-end: 0;
}

.bb-search-box {
  input[type="search"] {
    &::-ms-clear,
    &::-ms-reveal {
      display: none;
      width: 0;
      height: 0;
    }

    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
    }
  }

  &:not(.bb-search-box--has-clear) {
    .bb-search-box__input {
      &.open {
        border-inline-end-width: $input-border-width;
        @include border-inline-end-radius($input-border-radius);
      }
    }
  }
}
