// Global annotations
////
/// @group widgets
/// @access private
////

.bb-cost-card {
  container: card / inline-size;
}

.bb-cost-card__grid {
  display: grid;
  gap: $spacer-lg;
}

@container card (inline-size >= 840px) {
  .bb-cost-card__grid {
    grid-template-columns: 1fr 1fr;
    margin-right: 16.6667%;
  }
}
