// Global annotations
////
/// @group widgets
/// @access private
////

$bb-wealth-shared-splitted-layout-header-and-footer-height: 4.3rem !default;
$bb-wealth-shared-splitted-layout-buttons-bar-height: 10rem !default;

.bb-wealth-splitted-layout {
  height: calc(100vh - $bb-wealth-shared-splitted-layout-header-and-footer-height);
}

.bb-wealth-splitted-layout--full-height {
  height: 100vh;
}

.bb-wealth-splitted-layout__sidebar {
  background-size: cover;
  background-position: center;

  @include media-breakpoint-up(md) {
    width: 50%;
  }
}

.bb-wealth-splitted-layout__content {
  overflow: auto;
  padding-top: $bb-wealth-shared-splitted-layout-header-and-footer-height;
  padding-bottom: $bb-wealth-shared-splitted-layout-buttons-bar-height;

  @include media-breakpoint-up(md) {
    width: 50%;
    padding: $bb-wealth-shared-splitted-layout-header-and-footer-height 0;
  }
}

.bb-wealth-splitted-layout__content-wrapper {
  margin: 0;

  @include media-breakpoint-up(md) {
    margin: auto;
  }
}

.bb-wealth-splitted-layout__content-column {
  margin: 0 auto;
}

.bb-splitted-layout__buttons:not(:empty) {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  gap: $spacer-md;
  width: 100%;
  padding: $spacer-xl $spacer-md;
  background-color: $color-background-surface-1;

  @include media-breakpoint-up(md) {
    position: initial;
    flex-direction: row;
    padding: 0;
    background-color: transparent;
  }
}
