// Global annotations
////
/// @group journey-specific
/// @access public
////

.bb-ac-product-information {
  &.max-height {
    max-height: 643px;
  }

  .image {
    flex: 1;
    background-repeat: no-repeat;
    background-position: center;
    background-origin: content-box;
    background-color: $color-background-surface-1;
    min-width: max(260px, 35%);
    min-height: 200px;

    &-size__contain {
      background-size: contain;
    }
    &-size__cover {
      background-size: cover;
    }
  }

  @include media-breakpoint-between(sm, lg) {
    flex-direction: row;
  }

  .reduce-spacing {
    margin-bottom: -0.5rem;
    @include media-breakpoint-between(sm, lg) {
      margin-bottom: 0;
      margin-right: -0.5rem;
    }
  }
}
