.overview-list {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 1.5rem;

  &__header {
    grid-column: 1 / 13;
    margin-block-end: $spacer;

    font-size: $font-size-subtitle;
    color: $color-neutral-dark;
    line-height: calc(1rem * 1.5); // 24px
  }

  &__body-card {
    grid-column-end: span 6;

    @include media-breakpoint-between(md, xl) {
      grid-column-end: span 4;
    }

    @include media-breakpoint-between(xl, xxl) {
      grid-column-end: span 6;
    }

    @include media-breakpoint-up(xxl) {
      grid-column-end: span 4;
    }

    &:nth-of-type(2) > a {
      border-start-end-radius: 3.75rem; // 60px

      @include media-breakpoint-between(md, xl) {
        border-start-end-radius: unset;
      }

      @include media-breakpoint-between(xl, xxl) {
        border-start-end-radius: 3.75rem; // 60px
      }

      @include media-breakpoint-up(xxl) {
        border-start-end-radius: unset;
      }
    }

    &:nth-of-type(3) > a {
      @include media-breakpoint-between(md, xl) {
        border-start-end-radius: 3.75rem; // 60px
      }

      @include media-breakpoint-between(xl, xxl) {
        border-start-end-radius: unset;
      }

      @include media-breakpoint-up(xxl) {
        border-start-end-radius: 3.75rem; // 60px
      }
    }
  }

  &__footer {
    grid-column: 1 / 13;
  }
}
