// Global annotations
////
/// @group forms
/// @access private
////

$dp-border-radius: $sizer-md !default;
$dp-dropdown-padding: $sizer-sm !default;
$dp-dropdown-shadow: $box-shadow !default;
$dp-bg: $color-background-surface-1 !default;
$dp-navigation-chevron-color: $color-foreground-default !default;
$dp-month-name-color: $color-foreground-brand !default;
$dp-month-name-font-size: $font-size-subtitle !default;
$dp-month-name-font-weight: $font-weight-regular !default;
$dp-weekday-color: $color-foreground-default !default;
$dp-weekday-font-weight: $font-weight-regular !default;

$dp-color: $color-foreground-default !default;
$dp-hover-bg: $color-background-brand-subtle !default;
$dp-hover-color: $color-foreground-default !default;
$dp-selected-bg: $color-background-brand !default;
$dp-selected-color: $color-on-background-brand !default;
$dp-range-selected: $color-background-brand !default;
$dp-range-in-selection-bg: $color-background-brand-hovered !default;
$dp-range-in-selection-color: $color-on-background-brand !default;
$dp-to-date-selection-bg: $color-background-brand !default;
$dp-to-date-selection-color: $color-on-background-brand !default;
$dp-range-dates-selected-bg: $color-background-brand !default;
$dp-range-dates-selected-color: $color-on-background-brand !default;
$dp-focus-width: 3px !default;
$dp-focus-box-shadow: 0 0 0 $dp-focus-width rgba($color-focus-outline, 0.2) !default;
$dp-disabled-color: $color-foreground-disabled !default;

// @deprecated - no longer used in ui-ang in v12. To be removed in v14.
$dp-disabled-bg: $color-background-disabled !default;

/* stylelint-disable declaration-no-important*/

ngb-datepicker {
  &.dropdown-menu.show {
    padding: $dp-dropdown-padding;
    border: 0;
    border-radius: $dp-border-radius;
    box-shadow: $dp-dropdown-shadow;
  }

  ngb-datepicker-navigation {
    [dir="rtl"] & {
      flex-direction: row-reverse;
    }
  }

  ngb-datepicker-navigation-select {
    .form-select {
      [dir="rtl"] & {
        background-position: left 0.25rem center;
      }
    }
  }

  .ngb-dp-week {
    padding-inline: 0;
    border-radius: 0;
  }

  .ngb-dp-day {
    width: 2.5rem;
    height: 2.5rem;

    &:focus {
      position: relative;
      border-radius: 50%;
      outline: 0;
      box-shadow: none;
      &::before {
        position: absolute;
        inset: $input-focus-outline-distance - 1px;
        border: $input-focus-border-primary;
        border-radius: inherit;
        content: "";
      }
    }

    &.hidden {
      background-color: $dp-bg;
    }
  }

  .ngb-dp-day.disabled {
    color: $dp-disabled-color;
    border-radius: 50%;
  }

  .ngb-dp-day.disabled .text-muted,
  .ngb-dp-day.disabled .text-body-secondary {
    color: $dp-disabled-color !important; // override text-muted utility class
  }

  .disabled.ngb-dp-today:last-child {
    border-radius: unset;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
  }

  .ngb-dp-day.disabled + .ngb-dp-day.disabled:not(.ngb-dp-today) {
    position: relative;

    &:not(:last-child) {
      &:has(+ .disabled.ngb-dp-today) {
        border-radius: unset;
      }
    }

    :after {
      position: absolute;
      inset-block-start: 0;
      inset-inline-start: 0;
      z-index: -1;
      display: block;
      width: 2.5rem;
      height: 2.5rem;
      left: -50%;
      right: -50%;
      content: "";
    }
  }

  .ngb-dp-weekdays {
    background-color: $dp-bg !important; // override ngbDatepicker utility class
    border: 0;
  }

  .ngb-dp-header {
    padding-block: 0.5rem;
    background-color: $dp-bg !important; // override ngbDatepicker utility class
  }

  .ngb-dp-week.ngb-dp-weekdays {
    padding-block: 0.5rem;
  }

  .ngb-dp-month-name {
    height: 1.25rem;
    color: $dp-month-name-color;
    font-weight: $dp-month-name-font-weight;
    font-size: $dp-month-name-font-size;
    line-height: 1.25rem;
    letter-spacing: 0;
    text-align: center;
    background-color: $dp-bg;
  }

  .ngb-dp-arrow {
    height: auto;

    &.ngb-dp-arrow-prev {
      [dir="rtl"] & {
        justify-content: flex-end;
      }
    }

    &.ngb-dp-arrow-next {
      [dir="rtl"] & {
        justify-content: flex-start;
      }
    }
  }

  .ngb-dp-navigation-chevron {
    width: 0.7em;
    height: 0.7em;
    color: $dp-navigation-chevron-color;
  }

  .ngb-dp-arrow-btn {
    margin-block: 0;
    margin-inline: 1rem;

    &:focus {
      outline: 0;
      box-shadow: $dp-focus-box-shadow;
    }

    &:disabled {
      background-color: inherit;

      .ngb-dp-navigation-chevron {
        color: $dp-disabled-color;
      }
    }
  }

  .ngb-dp-weekday.small {
    width: 2.5rem;
    height: 1.25rem;
    color: $dp-weekday-color;
    font-weight: $dp-weekday-font-weight;
    font-size: 0.875rem;
    font-style: normal;
    line-height: 1.25rem;
    text-align: center;
  }

  .custom-day {
    position: relative;
    display: block;
    width: 2.5rem;
    height: 2.5rem;
    color: $dp-color;
    line-height: 2.5rem;
    font-size: $font-size-subtitle;
    border-radius: 50%;
    box-shadow: none;

    &:focus {
      outline: 0;
    }

    &:hover {
      color: $dp-hover-color;
      background-color: $dp-hover-bg;
    }

    &.text-muted,
    &.text-body-secondary {
      color: $dp-color !important; // override text-muted/text-body-secondary utility class
    }

    // range selection - dates in between
    &.is-faded {
      color: $dp-range-in-selection-color;
      background-color: $dp-range-in-selection-bg;
      border-radius: 0;

      &.to-date {
        border-start-start-radius: 0;
        border-start-end-radius: 50%;
        border-end-end-radius: 50%;
        border-end-start-radius: 0;

        &::before {
          inset-inline-end: 0;
          inset-inline-start: auto;
          background-color: $dp-range-in-selection-bg;
        }
      }
    }

    // range selection - to-date
    &.is-faded.is-hovered {
      color: $dp-to-date-selection-color;
      background-color: $dp-to-date-selection-bg;
      border-radius: 50%;

      &::before {
        position: absolute;
        inset-block-start: 0;
        inset-inline-start: 0;
        z-index: -1;
        display: block;
        width: 1.25rem;
        height: 2.5rem;
        background-color: $dp-range-in-selection-bg;
        content: "";
      }

      &.to-date {
        &::before {
          background-color: $dp-range-dates-selected-bg;
        }
      }

      &.from-date {
        &::before {
          background-color: unset;
        }
      }

      &.is-range-selected {
        &::before {
          background-color: $dp-range-dates-selected-bg;
        }
      }
    }

    &.is-faded-from-date {
      &.is-hovered {
        &::before {
          inset-inline-end: 0;
          inset-inline-start: auto;
        }
      }

      &.from-date.is-faded {
        border-start-start-radius: 50%;
        border-start-end-radius: 0;
        border-end-end-radius: 0;
        border-end-start-radius: 50%;

        &::after {
          inset-inline-end: auto;
          inset-inline-start: 0;
          background-color: $dp-range-in-selection-bg;
        }

        &.is-hovered {
          &:after {
            background-color: unset;
          }
        }
      }

      &.is-range-selected {
        &::before {
          background-color: $dp-range-dates-selected-bg;
        }
      }
    }

    &.to-date {
      &::before {
        position: absolute;
        inset-block-start: 0;
        inset-inline-start: 0;
        z-index: -1;
        display: block;
        width: 1.25rem;
        height: 2.5rem;
        background-color: $dp-range-dates-selected-bg;
        content: "";
      }
      &.from-date {
        &::before {
          display: none;
        }
        &::after {
          display: none;
        }
      }
    }

    &.from-date {
      &::after {
        position: absolute;
        inset-block-start: 0;
        inset-inline-end: 0;
        z-index: -1;
        display: block;
        width: 1.25rem;
        height: 2.5rem;
        background-color: $dp-range-dates-selected-bg;
        content: "";
      }

      &.is-faded {
        border-radius: 50%;

        &::after {
          background-color: $dp-range-in-selection-bg;
        }
      }

      &.is-selected {
        &::after {
          display: none;
        }
      }

      &.is-selected.is-faded {
        &::after {
          display: block;
        }
      }

      &.is-selected.is-hovered:hover {
        &::after,
        &::before {
          display: none;
          background-color: transparent;
        }
      }
    }

    // range selected
    &.from-date,
    &.to-date {
      color: $dp-range-dates-selected-color;
      background-color: $dp-range-dates-selected-bg;

      &:hover {
        background-color: $dp-range-dates-selected-bg;
      }
    }

    // range selected - dates in between
    &.is-selected:not(.to-date),
    &.is-range-selected {
      color: $dp-selected-color;
      background-color: $dp-selected-bg;
    }

    // range selected - dates in between
    &.is-range-selected {
      background-color: $dp-range-selected;
      border-radius: 0;

      &.to-date {
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
      }
    }

    // range selection - from-date and to-date on the same date
    &.from-date.is-faded.is-selected.is-hovered {
      &:after,
      &:before {
        background-color: $dp-bg;
      }
    }
  }
}

ngb-timepicker {
  .ngb-tp {
    [dir="rtl"] & {
      flex-direction: row-reverse;
      justify-content: flex-end;
    }
  }
}
