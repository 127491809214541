$bb-upcoming-payments-item-hover-color: $color-background-surface-1-hovered !default;
$bb-upcoming-payments-item-block-end-margin: 0 !default;
$bb-upcoming-payments-item-paddings: $sizer-lg !default;

$bb-upcoming-payments-item-block-end-margin: 0 !default;
$bb-upcoming-payments-item-block-end-padding: 0 !default;

$bb-upcoming-payments-item-group-header-paddings: $sizer-lg !default;

.bb-upcoming-payments-item {
  margin-block-end: $bb-upcoming-payments-item-block-end-margin;
  padding-block-start: $bb-upcoming-payments-item-paddings;
  padding-block-end: $bb-upcoming-payments-item-paddings;
  padding-inline-start: $bb-upcoming-payments-item-paddings;
  padding-inline-end: $bb-upcoming-payments-item-paddings;
}

.bb-upcoming-payments-item:hover {
  background-color: $bb-upcoming-payments-item-hover-color;
}

.bb-upcoming-payments-item--last {
  border-bottom-left-radius: $card-border-radius;
  border-bottom-right-radius: $card-border-radius;
}

.bb-upcoming-payments-item__group-wrapper {
  margin-bottom: $bb-upcoming-payments-item-block-end-margin;
  padding-bottom: $bb-upcoming-payments-item-block-end-padding;
}

.bb-upcoming-payments-item__group-header {
  padding-block-start: $bb-upcoming-payments-item-group-header-paddings;
  padding-inline-start: $bb-upcoming-payments-item-group-header-paddings;
  padding-inline-end: $bb-upcoming-payments-item-group-header-paddings;
}
