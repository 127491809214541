// Variables provided by Bootstrap
$input-padding-y: $input-padding-vertical * 0.5 !default;
$input-padding-x: $input-padding-horizontal !default;

$input-font-family: $font-family !default;

$input-line-height: 1.5 !default;
$input-padding-y-sm: $sizer-xs * 0.5 !default;
$input-padding-x-sm: $sizer-sm !default;
$input-font-size-sm: $font-size-subtitle !default;
$input-line-height-sm: 1.5 !default;
$input-padding-y-lg: $sizer-sm !default;
$input-padding-x-lg: $sizer-md !default;
$input-font-size-lg: $font-size-default !default;
$input-line-height-lg: 1.5 !default;
$input-bg: $input-background-color !default;
$input-disabled-bg: $input-disabled-background-color !default;

$input-border-radius-lg: $input-border-radius !default;
$input-border-radius-sm: $input-border-radius !default;
$input-focus-bg: $input-background-color;

$input-focus-color: $input-color !default;
$input-focus-width: $focus-box-shadow-spread-radius !default;

$input-plaintext-color: $input-color !default;
$input-height-border: $input-border-width * 2 !default;
$input-height-inner: calc(#{$input-line-height * 1em} + #{$input-padding-y * 2}) !default;
$input-height-inner-half: calc(#{$input-line-height * 0.5em} + #{$input-padding-y}) !default;
$input-height-inner-quarter: calc(
  #{$input-line-height * 0.25em} + #{$input-padding-y * 0.5}
) !default;
$input-height: 2.5rem !default;
$input-height-sm: 2rem !default;
$input-height-lg: calc(#{$input-line-height-lg * 1em} + #{$input-padding-y-lg * 2}) !default;
$input-transition:
  border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out !default;
$form-feedback-margin-top: $spacer-xs !default;
$form-feedback-font-size: $font-size-subheader !default;
$form-feedback-valid-color: $color-foreground-success !default;
$form-feedback-invalid-color: $color-foreground-danger !default;
$form-feedback-icon-valid-color: $form-feedback-valid-color !default;
$form-feedback-icon-valid: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e"),
  "#",
  "%23"
) !default;
$form-feedback-icon-invalid-color: $form-feedback-invalid-color !default;
$form-feedback-icon-invalid: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='#{$form-feedback-icon-invalid-color}' viewBox='-2 -2 7 7'%3e%3cpath stroke='#{$form-feedback-icon-invalid-color}' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E"),
  "#",
  "%23"
) !default;
$enable-validation-icons: false;

/// Configures the width style color of the border on focus (experimental)
/// @access public
$form-focus-border-primary: $input-focus-border-primary !default;

/// Configures the distance(top bottom left right) of the border from the element on focus (experimental)
/// @access public
$form-focus-outline-distance: 4px !default;

/// Sets the box-shadow to none on focus (experimental)
/// @access public
$form-focus-box-shadow: none !default;

$form-label-color: $color-foreground-default;
