.bb-formly-switch {
  display: grid;
  gap: $spacer-sm;
  grid-template-columns: repeat(2, minmax(0, max-content));

  .bb-switch {
    display: flex;
  }
}

.bb-formly-switch--with-separated-label {
  grid-template-columns: repeat(2, auto);

  .bb-formly-switch__column {
    justify-content: flex-end;
  }

  .bb-formly-switch__column:nth-child(odd) {
    justify-content: flex-start;
  }
}

.bb-formly-switch--reversed {
  grid-template-areas:
    "col1 col2"
    "col3 col4";

  .bb-formly-switch__column {
    justify-content: flex-start;
  }

  .bb-formly-switch__column:nth-child(odd) {
    justify-content: flex-end;
  }

  .bb-formly-switch__column:nth-child(1) {
    grid-area: col2;
  }

  .bb-formly-switch__column:nth-child(2) {
    grid-area: col1;
  }

  .bb-formly-switch__column:nth-child(3) {
    grid-column: col4;
  }
}

.bb-formly-switch__column {
  display: flex;
  align-items: center;
}
