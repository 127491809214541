// Global annotations
////
/// @group journey-specific
/// @access public
////

ac-explore-products {
  @include media-breakpoint-up(sm) {
    .bb-button-bar > .btn-link:first-child {
      margin-inline-start: -$spacer-lg;
    }
  }
}

.bb-ac-explore-products-image {
  background-repeat: no-repeat;
  background-position: center;
  background-origin: content-box;
  background-color: $color-background-surface-1;
  min-height: 135px;
  min-width: 160px;

  &--size__contain {
    background-size: contain;
  }
  &--size__cover {
    background-size: cover;
  }
}
