.ds-icon:before {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  background: none no-repeat center;
  background-size: contain;
  margin-right: 1rem;
}

.ds-icon-accessibility:before {
  background-image: url("../../assets/images/accessibility.svg");
}
.ds-icon-rocket:before {
  background-image: url("../../assets/images/rocket.svg");
}
.ds-icon-styles:before {
  background-image: url("../../assets/images/styles.svg");
}
.ds-icon-components:before {
  background-image: url("../../assets/images/components.svg");
}
.ds-icon-patterns:before {
  background-image: url("../../assets/images/patterns.svg");
}
.ds-icon-formats:before {
  background-image: url("../../assets/images/formats.svg");
}
.ds-icon-resources:before {
  background-image: url("../../assets/images/resources.svg");
}
