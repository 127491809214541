// Global annotations
////
/// @group widgets
/// @access public
////

$portfolio-trading-color-grey: #616161 !default;
$portfolio-trading-background-color-neutral: #f6f7f7 !default;
$portfolio-trading-component-border: 1px solid #dedede !default;
$portfolio-trading-progress-bar-background-color: #d9d9d9 !default;
$orders-filters-body-background-color: #f5f5f5 !default;
