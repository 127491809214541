// Global annotations
////
/// @group journeys
/// @access private
////

$user-permissions-review-cag-padding-start: $sizer-lg !default;
$user-permissions-review-cag-padding-end: $sizer-lg !default;

.bb-user-permissions-review-cag__collapse {
  padding-left: $user-permissions-review-cag-padding-start;
  padding-right: $user-permissions-review-cag-padding-end;
}
