// Global annotations
////
/// @group journey-specific
/// @access private
////

.bb-money-movement-drag-and-drop {
  background: transparent;
  opacity: 1;
  border: 1px dashed $color-border-subtle;
}

.bb-money-movement-drag-and-drop--dragover {
  background: $color-background-page;
  opacity: 0.8;
}
