.design-token-list-table {
  width: 100%;

  &__head {
    border-bottom: $border-width $border-style $color-neutral-greyer;
  }

  &__head-item {
    color: $color-neutral-darkest;
    font-size: $font-size-subtitle;
    font-weight: $font-weight-semi-bold;
    line-height: 1.5rem;
    padding: $sizer * 0.75 $sizer-sm;
  }

  &__row {
    display: grid;
    gap: $spacer-lg;
    grid-template-columns: 110px minmax(260px, 2fr) minmax(260px, 3fr) minmax(110px, 1fr);
  }

  &__body {
    > tr {
      border-bottom: $border-width $border-style $color-neutral-grey-light;
    }
  }

  &__body-item {
    padding: $sizer * 0.75 $sizer-sm;
    border-radius: $border-radius * 0.25;

    &--wide {
      padding: $sizer-sm $sizer-sm;
    }

    &--variable {
      color: $color-neutral-greyest;
    }
  }

  &__body-item-showcase {
    border-radius: $border-radius * 0.25;
    height: 100%;
    width: 100%;
  }

  &__body-item-token {
    background-color: $color-neutral-lighter;
    padding: $sizer-xs $sizer-sm;

    color: $color-neutral-darkest;
    font-family: "Roboto Mono";
    font-size: $font-size-subtitle;
  }

  &__body-item-status {
    background-color: #ebf0f5;
    border-radius: $border-radius * 0.25;
    padding: $sizer-xs;

    color: $color-neutral-dark;
    font-size: $font-size-base * 0.625;
    font-weight: $font-weight-semi-bold;
    line-height: 1.125rem;

    &:empty {
      display: none;
    }
  }
}
