// Global annotations
////
/// @group journey-specific
/// @access public
////

ac-drawer-container {
  position: fixed;
  z-index: $zindex-modal;
}
