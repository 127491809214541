// Global annotations
////
/// @group journey-specific
/// @access private
////

.bb-btiy-terms-of-use-wrapper {
  background-color: $color-background-surface-2;
}

#bb-btiy-fastlink-container {
  min-height: 18.75rem;
}

.bb-btiy-bg-import-success {
  background: $color-background-success-subtle;
}

.bb-btiy-bg-import-fail {
  background: $color-background-brand-subtle;
}

.bb-btiy-list {
  .infinite-scroll-wrapper {
    max-height: 19.5rem;
    margin-bottom: $sizer-md;
    align-content: baseline;

    &:focus,
    &:focus-visible {
      outline-color: $color-foreground-brand;
      outline-offset: 2 * $border-width;
    }

    &::-webkit-scrollbar {
      width: 0.375rem;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-background-neutral;
      border-radius: $border-radius-md;
    }
  }

  &__logo {
    height: 100%;
    width: 100%;
    object-fit: contain;
    max-height: 60px;
  }

  &__card {
    cursor: pointer;
    width: 150px;
    height: 128px;
    box-shadow: $box-shadow-sm;
    position: relative;
    display: flex;

    &:focus-visible {
      outline-color: $color-foreground-brand;
      outline-offset: 2 * $border-width;
    }

    &.selected:before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 2 * $border-width solid $color-on-background-brand-subtle;
      border-radius: $sizer-md;
      content: "";
    }

    &.selected:hover:before {
      border: 2 * $border-width solid $color-foreground-brand;
    }

    &:not(.selected):hover:before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 2 * $border-width solid $color-background-brand-subtle;
      border-radius: $sizer-md;
      content: "";
    }
  }

  &__name {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.bb-btiy-software-not-found .bb-empty-state {
  padding-top: 0;
  padding-bottom: 0;
}

@media (max-width: 576px) {
  .bb-btiy-list__card {
    width: 100%;
  }

  .bb-btiy-list__logo {
    max-width: 60%;
  }
}
