// Global annotations
////
/// @group journey-specific
/// @access public
////

.bb-ac-center-error {
  display: flex;
  justify-content: center;
  height: 70vh;
}
