// Global annotations
////
/// @group journeys
/// @access private
////

.valuation-line-chart {
  --valuation-line-chart-color-start: color-mix(
    in srgb,
    #{$color-background-brand},
    transparent 7%
  );
  --valuation-line-chart-color-end: color-mix(in srgb, #{$color-background-brand}, transparent 70%);

  --valuation-line-chart-tooltip-area-fill: #{$color-background-brand};

  .area-chart {
    .vertical-grid-line {
      stroke: $color-border-default;
    }
  }

  .area-series {
    .area {
      fill: url("#valuationLineChartFill");
    }
  }

  .circle-series {
    circle {
      fill: $color-on-background-brand-subtle;
    }
  }

  .y.axis {
    .gridline-path {
      stroke: $color-border-subtle;
    }
  }
}
