// Global annotations
////
/// @group journeys
/// @access private
////

.bb-payee-group-table-list__heading {
  line-height: 2rem;
  padding-block-start: $sizer;
  padding-block-end: $sizer;
}
