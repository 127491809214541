// Global annotations
////
/// @group journey-specific
/// @access private
////

/// @deprecated - used in the journey only for 2025.01 release
bb-letter-of-credit-journey-ang {
  .bb-heading-widget--de-elevated {
    background: $color-background-brand;
  }

  .bb-heading-widget__heading {
    color: $color-on-background-brand;
  }
}

/// colors for horizontal line
bb-letter-of-credit-card-header {
  hr {
    color: $color-border-subtle;
    border-color: $color-border-subtle;
  }
}

/// LC import modal window - top line for the footer
.bb-trade-finance-footer {
  border-block-start-color: $color-border-subtle !important;
}

/// container with gray background
.bb-trade-finance-info-box-card {
  background-color: $color-background-surface-2;
  border-radius: $border-radius-sm;
}

/// container for the shipment arrow
@mixin bb-trade-finance-arrow-block {
  @include media-breakpoint-up(md) {
    height: 128px;
    width: 32px;
  }
  height: 32px;
  width: 100%;
  border-radius: $border-radius-sm;
}

/// arrow for the shipment step
.bb-trade-finance-shipment-arrow-block {
  @include bb-trade-finance-arrow-block;
  background-color: $color-background-surface-2;
}

/// arrow for shipment in the review & submitted steps
.bb-trade-finance-review-shipment-arrow-block {
  @include bb-trade-finance-arrow-block;
  background-color: $color-background-surface-1;
}

/// card with gray background
.bb-trade-finance-review-card {
  border-radius: $border-radius-md;
  padding: $sizer-lg;
  background: $color-background-surface-2;
}

/// common - wrapping for text
.bb-trade-finance-review-value {
  overflow-wrap: anywhere;
}

/// common - text semi bold (can be replaced with <strong>)
.bb-trade-finance-review-label {
  font-size: $font-size-base;
  font-weight: $font-weight-semibold;
}

/// common - preserve white spaces in text
.bb-lc-white-space-pre {
  .bb-trade-finance-review-value {
    white-space: pre-wrap;
  }
}

/// common - content box sizing
.bb-lc-box-sizing-content-box {
  box-sizing: content-box;
}

/// LC import modal window - incoterms template
.bb-lc-incoterm-template {
  display: grid;
  gap: 0 $spacer-md;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    "incoterms"
    "link"
    "other"
    "place";
  @include media-breakpoint-up(md) {
    &-md {
      grid-template-columns: $form-field-xs-width 1fr;
      grid-template-areas:
        "incoterms place"
        "link link"
        "other other";
    }
  }
}

/// LC import modal window - incoterms review template
.bb-lc-incoterm-review-template {
  display: grid;
  gap: 0 $spacer-md;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    "incoterms"
    "other"
    "place";

  @include media-breakpoint-up(sm) {
    &-sm {
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
        "incoterms place"
        "other other";
    }
  }
}

.bb-lc-item-incoterms {
  grid-area: incoterms;
}

.bb-lc-item-place {
  grid-area: place;
}

.bb-lc-item-link {
  grid-area: link;
}

.bb-lc-item-other {
  grid-area: other;
}

/// textarea border color for invalid value [fix]
bb-letter-of-credit-expected-documents,
bb-letter-of-credit-settlement-details,
bb-letter-of-credit-shipment-details {
  bb-textarea-ui.ng-invalid.ng-touched {
    textarea {
      border-color: $input-invalid-border-color;
    }
  }
}

/// LC import modal window - body fixed width
.bb-tf-container--fixed-width {
  max-width: 964px;
}

/// common - text unicode bi-directional
bb-letter-of-credit-label {
  span {
    unicode-bidi: embed;
  }
}

/// spacing for Tolerance range
.bb-loc-details-tolerance-range-positive {
  margin-inline-end: $spacer-sm;
}

/// spacing for Tolerance range
.bb-loc-details-tolerance-range-negative {
  margin-inline-start: $spacer-sm;
}

/// spacing for more that 1 input in 1 row
.bb-loc-details-sm-screen-double-fields-end {
  @include media-breakpoint-up(sm) {
    margin-inline-end: $spacer;
  }
}

/// opposite direction for an arrow for RTL
[dir="rtl"]bb-icon-ui.bb-loc-icon-flip-dir-rtl {
  transform: scaleX(-1);
}

/// override bottom spacing for radio group label
bb-letter-of-credit-step-lc-details,
bb-letter-of-credit-step-settlement,
bb-letter-of-credit-step-shipment,
bb-letter-of-credit-step-fees {
  .bb-input-radio-group__label {
    margin-block-end: $spacer-sm;
  }
}

/// common - text, input label for <legend>
.bb-lc-multiple_inputs-label {
  margin-block-end: $spacer-sm;
  font-weight: $font-weight-semi-bold;
  font-size: $font-size-base;
}

/// milestone tracker component
bb-lc-milestone-tracker {
  .bb-lc-milestone {
    &_default-status-container {
      width: auto;
      height: map-get($icon-background-size-map, "sm");
    }

    &__default-icon {
      min-width: $sizer * 0.375;
      min-height: $sizer * 0.375;
      border-radius: $border-radius-xs;
      background-color: $color-background-neutral;
    }

    &_progress-bar {
      border-radius: $border-radius-xs;
      height: $sizer-sm;
      width: 100%;
    }

    &_bg_neutral-grey {
      background-color: $color-neutral-grey;
    }

    &-step-start {
      margin-inline-start: $sizer-xs;
    }

    &-step-end {
      margin-inline-end: $sizer-xs;
    }
  }
  .alert {
    margin-bottom: 0;
  }
}

/// common - subheader text, lighter weight
.bb-lc-subheader-lighter {
  font-size: $font-size-subheader;
  font-weight: $font-weight-regular;
  line-height: $line-height-base;
}
