@import "animation";
@import "a11y";
@import "badge";
@import "bb-card";
@import "browser-fixes";
@import "chart";
@import "delivery-tracker";
@import "form";
@import "loading";
@import "set-locale-icons";
@import "sidebar";
@import "sticky-cell";
@import "text";
@import "tab";
@import "optional-property";
@import "removed-from-bootstrap-4";

@mixin border-inline-end-radius($radius) {
  @if $enable-rounded {
    border-start-end-radius: valid-radius($radius);
    border-end-end-radius: valid-radius($radius);
  }
}

@mixin border-inline-start-radius($radius) {
  @if $enable-rounded {
    border-start-start-radius: valid-radius($radius);
    border-end-start-radius: valid-radius($radius);
  }
}

@mixin list-unstyled() {
  padding-inline-start: 0;
  list-style: none;
}
