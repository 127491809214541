// Global annotations
////
/// @group widgets
/// @access private
////

.bb-billpay-sso-iframe {
  display: none;
  min-width: 100%;
  overflow: hidden;
  border: 0;
}
