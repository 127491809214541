// Global annotations
////
/// @group journey-specific
/// @access private
////

$processed-color: $color-background-category-7;
$reversed-color: $color-background-category-4;
$pending-color: $color-background-category-9;
$reversal-pending-color: $color-background-category-9;
$rejected-color: $color-background-category-1;
$cancelled-color: $color-background-category-1;
$entered-color: $color-background-category-4;
$processing-color: $color-background-category-9;
$accepted-color: $color-background-category-7;

$dd-statuses-colors: $processed-color, $reversed-color, $pending-color, $reversal-pending-color,
  $rejected-color, $cancelled-color, $entered-color, $processing-color, $accepted-color;

:root {
  --dd-statuses-bar: #{$dd-statuses-colors};
}

.direct-debit-stats-bar__legend-dot {
  height: 8px;
  width: 8px;
}

.direct-debit-stats-bar__group-value {
  height: 10px;
}
