// Global annotations
////
/// @group journey-specific
/// @access public
////

.bb-ac-dashboard-card-image {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-origin: content-box;
  background-color: $color-background-surface-1;
  min-height: 180px;

  @include media-breakpoint-up(sm) {
    width: clamp(200px, 50%, 340px);
  }
  @include media-breakpoint-up(md) {
    min-width: 290px;
  }

  &--size__contain {
    background-size: contain;
  }
  &--size__cover {
    background-size: cover;
  }
}

.bb-ac-dashboard-card-reduce-spacing {
  margin-inline-end: -$spacer-sm;
  @include media-breakpoint-down(sm) {
    margin-right: 0;
    margin-bottom: -$spacer-sm;
  }
}

.bb-ac-dashboard-card {
  min-height: 204px;
  max-width: 1183px;
}

// ------ Dashboard card display value ------

.bb-ac-display-value-double-layout,
.bb-ac-display-value-single-layout {
  display: grid;
  gap: 0 $spacer-md;
  grid-template-columns: min-content 1fr;
}

@include media-breakpoint-up(lg) {
  .bb-ac-display-value-double-layout {
    width: 100%;
    display: grid;
    gap: 0 $spacer-md;
    grid-template-columns: repeat(2, min-content 1fr);
  }
}

.bb-ac-display-value-content-label {
  white-space: nowrap;
}

.bb-ac-display-value-content-value {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 7ch;
}

// ------ Dashboard card main content ------

ac-dashboard-card-main-content {
  display: block;
}

.bb-ac-dashboard-card-main-content-container {
  gap: $spacer-md;
}

.bb-ac-dashboard-card-main-content-badge {
  display: inherit;
}

.bb-ac-dashboard-card-main-content-badge-wrapper {
  display: flex;
  align-self: flex-end;
  align-items: center;
  // to try match height of heading which is determined by 1.5rem font-size multiplied by 1.2 line-height properties
  height: calc(1.2 * 1.5rem);

  bb-badge-ui {
    display: inherit;
  }
}
