// Global annotations
////
/// @group journey-specific
/// @access private
////

.badge-approval-type-highlight {
  background-color: $color-background-category-7-subtle;
  color: $color-foreground-category-7;
}
