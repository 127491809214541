// Global annotations
////
/// @group forms
/// @access private
////

$bb-rich-text-editor-content-height: 100px !default;
$bb-rich-text-editor-content-placeholder-color: $input-placeholder-color !default;
$bb-rich-text-editor-content-border-color: $input-border-color !default;
$bb-rich-text-editor-content-invalid-border: $input-invalid-border !default;
$bb-rich-text-editor-content-focus-border-color: $input-focus-border-color !default;
$bb-rich-text-editor-content-btn-focus-box-shadow: $input-btn-focus-box-shadow !default;
$bb-rich-text-editor-char-counter-color: $color-foreground-support !default;
$bb-rich-text-editor-char-counter-margin-top: $spacer-sm !default;
$bb-rich-text-editor-toolbar-separator-width: $spacer-lg !default;
$bb-rich-text-editor-input-font-size: $font-size-base !default;
$bb-rich-text-editor-list-side-margin: $sizer-lg !default;

bb-rich-text-editor-ui {
  .form-control:focus,
  &.is-focused .form-control,
  &:focus-visible .form-control {
    box-shadow: unset;
    border-color: $bb-rich-text-editor-content-border-color;

    &:not(.is-disabled) {
      position: relative;

      &::before {
        position: absolute;
        top: $input-focus-outline-distance * 2;
        right: $input-focus-outline-distance * 2;
        bottom: $input-focus-outline-distance * 2;
        left: $input-focus-outline-distance * 2;
        border: $input-focus-border-primary;
        border-radius: inherit;
        content: "";
      }
    }
  }

  &.ng-invalid.ng-touched:not(.is-focused) .form-control:not(:focus) {
    border: $bb-rich-text-editor-content-invalid-border;
  }

  .is-disabled {
    .ql-editor,
    .ql-toolbar {
      background-color: $input-disabled-background-color;
    }

    .ql-editor.ql-blank::before {
      color: $input-disabled-placeholder-color;
    }
  }

  .form-control:read-only,
  .ql-editor,
  .ql-toolbar {
    background-color: $input-background-color;
  }

  .ql-editor.ql-blank::before {
    color: $bb-rich-text-editor-content-placeholder-color;
  }
}

html[dir="rtl"] {
  quill-editor {
    ul:not(.ql-format-group),
    ol {
      padding-right: $bb-rich-text-editor-list-side-margin;
    }
    ul > li:not(.ql-format-group_type),
    ol > li:not(.ql-format-group_type) {
      padding-right: $bb-rich-text-editor-list-side-margin;
      text-align: right;
      direction: rtl;
    }
    // for rtl, but to override styles
    li:not(.ql-direction-rtl, .ql-format-group_type) .ql-ui::before,
    ol li:not(.ql-direction-rtl, .ql-format-group_type) .ql-ui::before {
      margin-left: $spacer-xs;
      margin-right: -$bb-rich-text-editor-list-side-margin;
      text-align: left;
    }
  }
}

.bb-rich-text-editor {
  i.flip-horizontal {
    transform: scaleX(-1);
    filter: FlipH;
  }
  quill-editor {
    border: unset;
    border-radius: inherit;
    display: block;

    .ql-editor {
      line-height: inherit;
      text-align: unset;

      code {
        color: $color-foreground-default;
        background-color: $color-background-surface-2;
      }
    }

    &:focus-visible {
      outline: unset;
    }

    .ql-tooltip {
      display: none;
    }
    .ql-snow .ql-editor a {
      text-decoration: unset;
    }
    .ql-container {
      line-height: inherit;
      font-family: inherit;
      font-size: inherit;
      border-start-start-radius: inherit;
      border-start-end-radius: inherit;

      .ql-blank::before {
        font-style: normal;
        font-size: inherit;
      }
    }

    .ql-container,
    .ql-toolbar {
      border: unset;
    }
    .ql-toolbar {
      display: flex;
      border-end-start-radius: inherit;
      border-end-end-radius: inherit;

      .ql-active {
        color: $color-foreground-brand;
      }
    }
    .ql-format-group {
      display: flex;
      &:not(:empty):not(:last-of-type) {
        padding-right: $bb-rich-text-editor-toolbar-separator-width;
        padding-left: 0;
      }
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        align-content: center;
        i {
          line-height: 1;
          vertical-align: middle;
        }
      }
    }
  }
}

.bb-rich-text-editor__textbox {
  // Unset `height` for `.form-control`
  height: auto;
  padding: unset;
  &.is-disabled {
    pointer-events: none;
  }
}

.bb-rich-text-editor__char-counter {
  margin-top: $bb-rich-text-editor-char-counter-margin-top;
  color: $bb-rich-text-editor-char-counter-color;
}

.bb-rich-text-editor__content > .ql-container {
  height: $bb-rich-text-editor-content-height;
  overflow-y: auto;
  outline: 0;
}
