// Global annotations
////
/// @group journey-specific
/// @access public
////

ac-milestone {
  width: 100%;
}

.bb-ac-milestone-tracker {
  @include media-breakpoint-up(md) {
    &--scroll {
      padding-bottom: 0.5rem;
      overflow-x: auto;
    }

    &__content {
      &--scroll {
        min-width: 68rem;
      }
    }
  }
}

$progress-states: "primary", "secondary", "success", "info", "warning", "danger", "accent", "light",
  "dark";

.bb-ac-milestone {
  &__grid {
    display: grid;
    grid-gap: 0 $spacer-sm;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      "icon milestone"
      "progress description";
  }

  &__default-icon {
    min-width: 0.75 * $sizer-sm;
    min-height: 0.75 * $sizer-sm;
    border-radius: $border-radius-xs;
    background-color: $color-background-neutral; // default color when `statusBackground` is not provided
    grid-area: icon;

    // maps supported color variables to their countertparts
    @each $progress in $progress-states {
      &--bg-#{$progress} {
        background-color: map-get($theme-default-colors, $progress);
      }
    }

    // Currently: NOTREQUIRED status in `acAppMilestoneStatusMap`
    &.bg-neutral-dark {
      background-color: $color-background-neutral-hovered;
    }
  }

  &__progress {
    grid-area: progress;
    justify-self: center;
    height: $sizer-xl;
    width: $sizer-xs;
    border-radius: $border-radius-xs;

    // maps supported color variables to their `-lightest` countertparts
    @each $progress in $progress-states {
      &.bar-bg-#{$progress} {
        background-color: map-get($theme-bright-default-colors, $progress);
      }
    }

    // Currently: NOTREQUIRED status in `acAppMilestoneStatusMap`
    &.bar-bg-neutral-greyer {
      // this used to be `color-neutral-greyer` but there's no semantic counterpart for this
      // for now let's make it match bar-bg-empty and figure out how to handle it in the future
      background-color: $color-background-neutral-subtle;
    }

    /*
      Intented as a default value when neither `progressBarBackground` nor `statusBackground` is provided.
      Currently: NOTSTARTED and UNDEFINED in `acAppMilestoneStatusMap`
    */
    &.bar-bg-empty {
      background-color: $color-background-neutral-subtle;
    }
  }

  &__progress-vertical {
    width: map-get($icon-background-size-map, "sm");
  }
}

.bb-ac-milestone-default-status-container {
  height: map-get($icon-background-size-map, "sm");
  width: map-get($icon-background-size-map, "sm");
}

.bb-ac-milestone-icon {
  grid-area: icon;
}

.bb-ac-milestone-name {
  grid-area: milestone;
}

.bb-ac-milestone-status-label {
  font-size: $font-size-subheader;
  font-weight: $font-weight-regular;
  line-height: $font-size-subheader; // match with font-size to make spacing between name & label minimal in vertical view
  grid-area: description;
  align-self: start;
}

@include media-breakpoint-up(md) {
  .bb-ac-milestone {
    &__grid {
      grid-gap: 0 $spacer-xs;
      grid-template-areas:
        "icon progress"
        "milestone milestone"
        "description description";
    }

    &__progress {
      border-radius: $border-radius-xs;
      height: $sizer-sm;
      width: 100%;
    }
  }

  .bb-ac-milestone-default-status-container {
    width: auto;
    height: map-get($icon-background-size-map, "sm");
  }
}

.bb-ac-padding-end-xs {
  @include media-breakpoint-up(md) {
    padding-inline-end: $spacer-xs;
  }
}
