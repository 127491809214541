// Global annotations
////
/// @group widgets
/// @access private
////

.bb-view-container__root-wrapper .bb-view-container__no-network-template {
  display: block;
}

.bb-view-container__no-network-template {
  display: none;
}

.bb-view-container__card {
  margin-block-start: $spacer-xl;
  margin-block-end: $spacer-xl;
}
