bb-showcase-storybook {
  display: block;
  height: 100%;

  .showcase-storybook-iframe {
    width: 1px;
    min-width: 100%;
    height: 100%;
  }
}
