.dso {
  &--with-overlay {
    overflow: hidden;
  }

  &:has(bb-overview-list) {
    background-color: $color-neutral-lighter;
  }

  &__side {
    @include media-breakpoint-up(xl) {
      width: $sidebar-inline-offset-xl;
      padding-block-start: $spacer-lg;
    }

    @include media-breakpoint-up(xxl) {
      width: $sidebar-inline-offset-xxl;
    }

    &--expanded {
      background-color: $color-neutral-white;

      width: 100vw;
      height: calc(100vh - $header-offset-xs);

      position: fixed;
      left: 0;
      top: $header-offset-xs;
      z-index: $zindex-modal;
    }

    &--hidden {
      position: fixed;
      left: -100vw;

      @include media-breakpoint-up(xl) {
        display: none;
      }
    }
  }

  // used on content: main, sidebar, mini-menu
  &__body {
    padding-block-start: $header-offset-xs;
    min-height: calc(100vh - $footer-offset);

    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;

    @include media-breakpoint-up(xl) {
      margin-inline: auto;
      padding-block-start: $header-offset-lg;
    }

    &::-webkit-scrollbar {
      width: 0 !important;
    }

    & > .dso__side {
      @include media-breakpoint-up(xl) {
        display: none;
      }
    }
  }

  // used for setting up max-width for content
  &__content {
    margin-inline: auto;

    @include media-breakpoint-up(sm) {
      max-width: $responsive-sm;
    }

    @include media-breakpoint-up(md) {
      max-width: $responsive-md;
    }

    @include media-breakpoint-up(lg) {
      max-width: $responsive-lg;
    }

    @include media-breakpoint-up(xl) {
      max-width: $responsive-xl;
    }
  }

  // used in doc pages to provide layout styles
  &__content-docs {
    width: 100%;
    padding: $spacer;

    @import "dso-docs"; // scope markdown generic css styles to this class

    @include media-breakpoint-up(md) {
      padding: $spacer-lg;
    }
  }

  &__loading-indicator {
    height: 40vh;
    display: grid;
    align-items: center;
  }

  // used for setting up grid layout with sidebar and mini-menu
  &__layout {
    margin-inline: auto;
    min-height: calc(100vh - ($footer-offset + $header-offset-xs));

    @include media-breakpoint-down(lg) {
      display: flex;
    }

    @include media-breakpoint-up(lg) {
      display: grid;
      grid-template-columns: minmax(auto, $responsive-lg) $mini-menu-inline-offset;
    }

    @include media-breakpoint-up(xl) {
      min-height: calc(100vh - ($footer-offset + $header-offset-lg));
      max-width: calc($sidebar-inline-offset-xl + $responsive-lg + $mini-menu-inline-offset);

      display: grid;
      grid-template-columns: $sidebar-inline-offset-xl minmax(auto, $responsive-xl) $mini-menu-inline-offset;
    }

    @include media-breakpoint-up(xxl) {
      max-width: calc($sidebar-inline-offset-xxl + $responsive-xl + $mini-menu-inline-offset);

      grid-template-columns: $sidebar-inline-offset-xxl minmax(auto, $responsive-xl) $mini-menu-inline-offset;
    }

    &--content-right {
      @include media-breakpoint-up(xl) {
        max-width: calc($sidebar-inline-offset-xl + $responsive-lg);

        display: grid;
        grid-template-columns: $sidebar-inline-offset-xl minmax(auto, $responsive-xl);
      }

      @include media-breakpoint-up(xxl) {
        max-width: calc($sidebar-inline-offset-xxl + $responsive-xl);

        grid-template-columns: $sidebar-inline-offset-xxl minmax(auto, $responsive-xl);
      }
    }
  }

  &__mini-menu {
    padding-block-start: $spacer-lg;
  }

  // used in heading-container
  // TODO: move them into their own stylesheet
  &__page-heading {
    padding-block-start: $spacer-lg;
    padding-block-end: $spacer-sm;
  }

  &__page-title {
    margin-block-end: 1rem;

    font-weight: 500;
    font-size: $font-size-h2;
    line-height: 2rem;
  }

  &__main-content {
    color: $color-neutral-dark;
    font-size: $font-size-subtitle;
    font-weight: $font-weight-normal;
    line-height: calc(1rem * 1.5);

    h2 {
      color: $color-neutral-darker;
      font-size: $font-size-highlight;
      font-style: normal;
      font-weight: $font-weight-semibold;
      line-height: calc(1rem * 1.6875); //27px
      padding: $sizer-lg 0 $sizer-sm 0;
    }

    .table,
    table {
      thead th {
        padding: 0.75rem $sizer-sm;
        font-size: $font-size-subtitle;
      }

      tbody td {
        padding: 0.75rem $sizer-sm;
      }
    }

    &__header {
      padding-block: $sizer-sm;
    }

    &__content {
      padding-block-end: $sizer-sm;
    }

    &__types {
      display: grid;
      grid-template-columns: 100%;
      grid-gap: 64px 24px;
      padding-block: $sizer-lg $sizer-xxl;
      @include media-breakpoint-up(sm) {
        grid-template-columns: 50% 50%;
      }
    }

    &__tile {
      img {
        padding-block-end: $sizer;
      }
      font-size: $font-size-base;
    }

    &__tile-content {
      > * {
        padding-block: $sizer-sm;
      }
      p {
        margin: 0;
      }
    }

    &__tile-header {
      color: $color-neutral-darker;
      font-weight: $font-weight-semibold;
    }
  }
}
