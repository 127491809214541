// Global annotations
////
/// @group feedback-indicators
/// @access private
////

@import "bootstrap/scss/alert";

$alert-colors: (
  "success": (
    "background": $color-background-success-subtle,
    "color": $color-on-background-success-subtle,
  ),
  "info": (
    "background": $color-background-info-subtle,
    "color": $color-on-background-info-subtle,
  ),
  "warning": (
    "background": $color-background-warning-subtle,
    "color": $color-on-background-warning-subtle,
  ),
  "danger": (
    "background": $color-background-danger-subtle,
    "color": $color-on-background-danger-subtle,
  ),
) !default;

// Bootstrap overrides
@each $color, $value in $alert-colors {
  .alert-#{$color} {
    --#{$prefix}alert-bg: #{map-get($value, "background")};
    --#{$prefix}alert-border-color: #{$color-border-subtle};
    --#{$prefix}alert-link-color: #{$color-foreground-support};
    box-shadow: $alert-box-shadow;

    .alert-icon {
      .bb-icon {
        color: #{map-get($value, "color")};
      }
    }

    .alert-actions__supportive-link {
      text-decoration: underline;
      font-weight: $font-weight-regular;
      color: #{map-get($value, "color")};

      &:not(:disabled):not(.disabled):hover,
      &:not(:disabled):not(.disabled):active {
        color: map-get($value, "color");
      }

      &:focus:not(:hover):not(:active):not(:disabled):not(.disabled) {
        color: map-get($value, "color");
      }
    }
  }
}

// Bootstrap override
.alert-dismissible {
  padding-right: unset;
  padding-inline-start: 1rem;
  padding-inline-end: $alert-dismissible-padding-right;

  .alert-btn-close {
    position: absolute;
    right: unset;
    top: unset;
    inset-block-start: $spacer-sm;
    inset-inline-end: $spacer-sm;
  }
}

// Bootstrap override
.alert-heading {
  font-weight: $font-weight-semi-bold;
  font-size: $font-size-base;
  color: $color-foreground-default;
}

.alert-description {
  font-weight: $font-weight-regular;
  font-size: $font-size-subtitle;
  color: $color-foreground-support;
}

.alert-link {
  font-weight: $font-weight-regular;
  text-decoration: underline;
  &:focus {
    text-decoration: none;
    outline-width: 3px;
    outline-style: solid;
    outline-color: inherit;
    outline-offset: 1px;
  }
}

.alert-body {
  display: flex;
  gap: $spacer-sm;
}

.alert-content {
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
}

.alert-content__messages {
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  gap: $spacer-xs;
}

.alert-actions {
  display: flex;
  flex-wrap: wrap;
  gap: $spacer;
  margin-block-start: $spacer-sm;

  &.alert-actions--align-bottom {
    margin-block-start: $spacer;
  }
}
