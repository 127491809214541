// Global annotations
////
/// @group journey-specific
/// @access private
////

.bb-approvals-assign-badge {
  background-color: $color-background-surface-2;
  padding: $spacer-sm;
  border-radius: $border-radius-sm;
  width: fit-content;
  max-width: 20rem;

  &.badge-danger {
    background-color: $color-background-danger-subtle;
  }
}
