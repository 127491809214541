// Global annotations
////
/// @group journeys
/// @access private
////

// Loading state
$entitlements-chip-loading-state-border: 1px solid $color-border-default !default;
$entitlements-chip-loading-state-bg-color: $color-background-surface-1-pressed !default;
$entitlements-chip-loading-indicator-color: $color-on-background-brand-subtle !default;

// Active state
$entitlements-chip-active-bg-color: $color-background-surface-1-pressed !default;
$entitlements-chip-active-color: $color-on-background-brand-subtle !default;
$entitlements-chip-color-default: $color-border-brand !default;

// Hover state
$entitlements-chip-hover-bg-color: $color-background-surface-1-hovered !default;
$entitlements-chip-hover-color: $color-on-background-brand-subtle !default;

// Active/Hover border
$entitlements-chip-hover-active-border: 1px solid $color-border-default !default;

// Disabled state
$entitlements-chip-disabled-bg-color: $color-background-disabled !default;
$entitlements-chip-color-disabled: $color-foreground-disabled !default;

@mixin bb-entitlements-chip-buttons() {
  .btn {
    border: none;
    height: 100%;
    min-height: 0;
    padding: 0;

    &:not(:disabled):not(.disabled):hover {
      background-color: transparent;
      color: $entitlements-chip-hover-color;

      .bb-icon::before {
        color: $entitlements-chip-hover-color;
      }
    }

    &:not(:disabled):not(.disabled):active:focus {
      background-color: $entitlements-chip-active-bg-color;
    }

    &:not(:disabled):not(.disabled):focus {
      background-color: transparent;
    }

    &:active {
      background-color: transparent;
      color: $entitlements-chip-active-color;

      .bb-icon::before {
        color: $entitlements-chip-active-color;
      }
    }

    &:disabled,
    &.disabled {
      background-color: $entitlements-chip-disabled-bg-color;
      color: $entitlements-chip-color-disabled;
      pointer-events: none;

      .bb-icon::before {
        color: $entitlements-chip-color-disabled;
      }
    }
  }

  .btn:first-child:disabled + .btn:last-child:disabled {
    background-color: $entitlements-chip-disabled-bg-color;
  }

  .btn:first-child {
    border-inline-end: none;
    display: flex;
    justify-content: space-between;
    max-width: $sizer * 13.75;
    padding-inline-end: $sizer-xs;
    padding-inline-start: $sizer-sm;

    .bb-icon {
      margin-inline-end: $sizer * 0.375;
    }
  }

  .btn:last-child {
    background-color: transparent;
    border-inline-start: none;
    padding-inline-end: $sizer-sm;

    &:disabled {
      background-color: transparent;

      .bb-icon {
        background-color: $entitlements-chip-disabled-bg-color;
      }

      .bb-icon::before {
        color: $entitlements-chip-color-disabled !important;
      }
    }

    &.btn-circle {
      width: $sizer * 1.675;

      .bb-icon {
        border-radius: $border-radius;
        inset-inline-end: $sizer * -0.125;
        padding: 1px;
        position: relative;
        top: -1px;

        &:not(:disabled):not(.disabled):hover {
          background-color: $entitlements-chip-hover-bg-color;
        }

        &:active {
          background-color: $entitlements-chip-active-bg-color !important;
        }
      }
    }
  }
}

@mixin entitlements-chip-close-btn-loading-state() {
  .bb-state-container {
    padding-block: 0;
  }

  .bb-loading-indicator__circle {
    background-color: $entitlements-chip-loading-state-bg-color;
    border-radius: $border-radius;
    color: $entitlements-chip-loading-indicator-color;
    height: $sizer * 1.25;
    inset-inline-end: $sizer * -0.25;
    position: relative;
    stroke-width: $sizer * 0.125;
    width: $sizer * 1.25;
  }

  .btn-circle-loading {
    pointer-events: none;
  }

  bb-icon-ui {
    vertical-align: middle;
  }
}

.bb-entitlements-chip {
  display: inline-flex;
  height: $sizer * 1.75;
  min-width: $sizer * 3.75;

  .bb-load-button__content {
    height: 100%;
  }

  .bb-load-button {
    border: $entitlements-chip-loading-state-border;
    height: 100%;
    min-height: 0;
    padding: 0;

    &:not(:has(.btn-circle:hover)):hover {
      background-color: $entitlements-chip-hover-bg-color;
      border: $entitlements-chip-hover-active-border;
      color: $entitlements-chip-hover-color;

      .bb-icon::before,
      .btn {
        color: $entitlements-chip-hover-color;
      }
    }

    &:not(:has(.btn-circle:hover)):active {
      background-color: $entitlements-chip-active-bg-color;
      border: $entitlements-chip-hover-active-border;
      color: $entitlements-chip-active-color;

      .bb-icon::before,
      .btn {
        color: $entitlements-chip-active-color;
      }
    }

    &:has(.btn-circle:hover):hover,
    &:has(.btn-circle:hover):active {
      background-color: transparent;
      border: $entitlements-chip-hover-active-border;

      .bb-icon::before {
        color: $entitlements-chip-color-default;
      }
    }

    &:disabled {
      border: none;
      color: $entitlements-chip-color-disabled;
      pointer-events: none;
    }

    &.bb-load-button--is-loading {
      background-color: $entitlements-chip-loading-state-bg-color;
      pointer-events: none;

      .bb-load-button__loading-indicator {
        color: $entitlements-chip-loading-indicator-color;
      }
    }
  }

  .btn-group {
    height: 100%;

    @include bb-entitlements-chip-buttons();
    @include entitlements-chip-close-btn-loading-state();
  }
}
