// Global annotations
////
/// @group journey-specific
/// @access private
////

$accounts-filter-margin-horizontal: $spacer-lg !default;
$accounts-filter-margin-horizontal--md: $sizer-md !default;
$accounts-filter-margin-horizontal--sm: $sizer-md !default;

/// Accounts filter panel
///
/// @example html
/// <div class="bb-accounts-filter-container">
/// <div class="bb-accounts-filter__dropdown">
/// <div class="bb-accounts-filter-dropdown__items"></div>
/// <div class="bb-accounts-filter-dropdown__items"></div>
/// </div>
/// <div class="bb-accounts-filter__dropdown bb-accounts-filter__buttons-panel">
/// </div>
/// </div>

@mixin accounts-filter-margins($sizer) {
  margin: 0 -#{$sizer};

  .bb-accounts-filter__dropdown {
    padding: $sizer;
  }
  .bb-accounts-filter__buttons-panel {
    padding-top: 0;
  }
  .bb-accounts-filter-dropdown__items:not(:last-child) {
    margin-right: $sizer;
  }
}

.bb-accounts-filter-container {
  @include accounts-filter-margins($accounts-filter-margin-horizontal);
  background-color: $color-background-surface-2;

  .bb-accounts-filter-dropdown__items {
    width: 50%;
  }

  .bb-dropdown-panel__dropdown {
    width: 100%;
  }

  @if $experimental-enable-dynamic-spacing {
    @include media-breakpoint-down(md) {
      @include accounts-filter-margins($accounts-filter-margin-horizontal--md);
    }
    @include media-breakpoint-down(sm) {
      @include accounts-filter-margins($accounts-filter-margin-horizontal--sm);
    }
  }

  .accounts-filter-dropdown {
    flex-wrap: wrap;
    gap: $sizer;

    .bb-accounts-filter-dropdown__items {
      &:not(:last-child) {
        margin: 0;
      }
      margin-right: 0;
      width: calc((100% - $sizer) / 2);

      &.bb-accounts-filter-item-size--md {
        width: 100%;
      }
    }
  }
}
