// Global annotations
////
/// @group journey-specific
/// @access public
////

ac-greeting {
  display: block;
}

.bb-ac-text-highlight {
  font-size: $font-size-highlight;
  font-weight: $font-weight-normal;
}
