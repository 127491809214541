.platforms {
}

.platform {
  display: flex;
  height: 400px;
  @include media-breakpoint-down(sm) {
    margin-bottom: 2rem;
  }
  & > div {
    overflow: hidden;
    width: 100%;
  }

  &--small {
    height: 290px;
  }
}

.card-body__text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 1rem;
}

.platform__web {
  background-color: $color-neutral-darker;
  color: $color-neutral-white;

  a,
  p,
  span {
    color: $color-neutral-white;
  }
}

.platform__ios {
  background-color: $color-primary;
  color: $color-neutral-white;

  a,
  p,
  span {
    color: $color-neutral-white;
  }
}

.platform__android {
  background-color: $chart-color-4;
  color: $color-neutral-white;

  a,
  p,
  span {
    color: $color-neutral-white;
  }
}

.platform__new {
  background-color: $color-primary;
  color: $color-neutral-white;

  a,
  p,
  span {
    color: $color-neutral-white;
  }
}

.platform__content {
  position: relative;
}

.platform__image {
  width: 80%;
  position: absolute;
  bottom: 0;
  left: 0;

  img {
    width: 100%;
    max-width: 100%;
  }

  &--right {
    left: unset;
    right: 0;
  }
}

.col-md-6 .platform__image {
  width: 55%;
}
