// Global annotations
////
/// @group 5-components/typography/colors
/// @access public
////

/// Extending from the global color variable `$color-background-warning-subtle`.
///
/// @name highlight-background-color
$highlight-background-color: $color-background-warning-subtle;

/// Sets the color of containing text to the 'primary' color, using variable `$color-foreground-brand`.
///
/// @example html
/// <p class="bb-text-primary">
///   Text with primary color
/// </p>
.bb-text-primary {
  color: $color-foreground-brand;
}

/// Sets the color of the containing text to the 'support' color, using variable `$color-foreground-support`.
///
/// @example html
/// <p class="bb-text-support">
///   Text with support color
/// </p>
.bb-text-support {
  color: $color-foreground-support;
}

/// Sets the color of the containing text to the 'disabled' color, using variable `$color-foreground-disabled`.
///
/// @example html
/// <p class="bb-text-disabled">
///   Text with disabled color
/// </p>
.bb-text-disabled {
  color: $color-foreground-disabled;
}

/// Sets the color of the containing text to the 'danger' color, using variable `$color-foreground-danger`.
///
/// @example html
/// <p class="bb-text-danger">
///   Text with danger color
/// </p>
.bb-text-danger {
  color: $color-foreground-danger;
}

/// Sets the color of the containing text to the 'success' color, using variable `$color-foreground-success`.
///
/// @example html
/// <p class="bb-text-success">
///   Text with success color
/// </p>
.bb-text-success {
  color: $color-foreground-success;
}

/// Adds a background color and an outline of the same color to the element, using variable `$highlight-background-color`.
///
/// @example html
/// <span class="bb-text-highlighted">
///     Background color and outline
/// </span>
.bb-text-highlighted {
  background-color: $highlight-background-color;
  outline: $highlight-background-color solid $sizer-xs;
}
