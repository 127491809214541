// Global annotations
////
/// @group elements
/// @access private
////

@import "bootstrap/scss/maps";
@import "bootstrap/scss/root";

// This piece of code was moved from Bootstrap 4.6, since it was removed in Bootstrap 5.
// These CSS variables are used by MediaQueryService from `@backbase/ui-ang/layout`
:root {
  @each $bp, $value in $grid-breakpoints {
    --breakpoint-#{$bp}: #{$value};
  }
}
