.section.section-illustrated.section-illustrated--left {
  display: flex;
  margin: 50px 0;
}

.section__illustration {
  width: calc(20% - 20px);
  margin-right: 20px;
}

.section__content.section-illustrated__content {
  width: 80%;
}
