// Global annotations
////
/// @group ui-components
/// @access private
////

$badge-counters-colors: (
  "primary": (
    "background": $color-background-brand,
    "color": $color-on-background-brand,
  ),
  "secondary": (
    "background": $color-background-inverted,
    "color": $color-on-background-inverted,
  ),
  "success": (
    "background": $color-background-success,
    "color": $color-on-background-success,
  ),
  "info": (
    "background": $color-background-info,
    "color": $color-on-background-info,
  ),
  "warning": (
    "background": $color-background-warning,
    "color": $color-on-background-warning,
  ),
  "danger": (
    "background": $color-background-danger,
    "color": $color-on-background-danger,
  ),
) !default;

.bb-badge-counter {
  line-height: 1;
  display: block;
}

.badge-counter {
  @each $color, $value in $badge-counters-colors {
    &.badge-counter-#{$color} {
      color: map_get($value, "color");
      background-color: map_get($value, "background");
    }
  }
  font-weight: $font-weight-semi-bold;
  line-height: $badge-line-height;
  border-radius: $border-radius-pill;
}
