$option-list-label-font-size: $font-size-base !default;
$option-list-label-font-weight: $font-weight-semi-bold !default;

$option-list-background: $color-foreground-on-color !default;
$option-list-border-color: $color-foreground-disabled !default;
$option-list-color: $color-foreground-default !default;
$option-list-active-color: $color-foreground-brand !default;
$option-list-active-border-color: $color-foreground-brand !default;
$option-list-background-disabled: $color-background-disabled !default;
$option-list-color-disabled: $color-foreground-disabled !default;

$option-list-on-color-background: $color-foreground-brand !default;
$option-list-on-color-border-color: $color-border-on-color !default;
$option-list-on-color-color: $color-foreground-on-color !default;
$option-list-on-color-active-background: $color-foreground-on-color !default;
$option-list-on-color-active-border-color: $color-border-on-color !default;
$option-list-on-color-active-color: $color-foreground-brand !default;
$option-list-on-color-background-disabled: $color-background-disabled-on-color !default;
$option-list-on-color-color-disabled: $color-foreground-disabled-on-color !default;

@mixin option-list-style(
  $background,
  $border-color,
  $color,
  $background-active,
  $border-color-active,
  $color-active,
  $background-disabled,
  $border-color-disabled,
  $color-disabled
) {
  & .btn {
    border-radius: $border-radius-sm;

    @include button-variant(
      $background,
      $border-color,
      $color,
      $background-active,
      $border-color-active,
      $color-active,
      $background-active,
      $border-color-active,
      $color-active,
      $background-disabled,
      $border-color-disabled,
      $color-disabled
    );
  }

  & .btn-neutral:focus:not(:hover):not(:disabled):not(.disabled) {
    background-color: $background;
    border: $border-width $border-style $border-color;
    color: $color;
  }

  & .btn-neutral:not(:disabled):not(.disabled):hover,
  .btn-neutral:not(:disabled):not(.disabled).active,
  .btn-neutral.active:focus:not(:hover):not(:disabled):not(.disabled) {
    background-color: $background-active;
    border: $border-width $border-style $border-color-active;
    color: $color-active;
  }

  & .btn:not(.btn-unstyled):focus::before {
    border-radius: $border-radius-sm;
  }
}

.bb-option-list__item {
  @include option-list-style(
    $option-list-background,
    $option-list-border-color,
    $option-list-color,
    $option-list-background,
    $option-list-active-border-color,
    $option-list-active-color,
    $option-list-background-disabled,
    $option-list-border-color,
    $option-list-color-disabled
  );
}

.bb-option-list__label {
  margin-block-end: 0;
  font-weight: $option-list-label-font-weight;
  font-size: $option-list-label-font-size;
}

.bb-option-list--on-color {
  @include option-list-style(
    $option-list-on-color-background,
    $option-list-on-color-border-color,
    $option-list-on-color-color,
    $option-list-on-color-active-background,
    $option-list-on-color-active-border-color,
    $option-list-on-color-active-color,
    $option-list-on-color-background-disabled,
    $option-list-on-color-border-color,
    $option-list-on-color-color-disabled
  );

  & .btn:not(.btn-unstyled):focus::before {
    border-color: $option-list-on-color-border-color;
  }

  & .bb-option-list__label {
    color: $color-foreground-on-color;
  }
}

.bb-option-list__sr-position-in-group {
  @include sr-only;
}
