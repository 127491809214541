// Global annotations
////
/// @group navigation
/// @access private
////

$tracker-segment-vertical-length: 88px !default;
$tracker-segment-active-sm-length: 60% !default;
$tracker-segment-gap: $spacer-xs !default;

$tracker-progress-thickness: $sizer-sm !default;
$tracker-progress-border-radius: 2px !default;
$tracker-progress-active-background-color: $color-foreground-brand !default;
$tracker-progress-inactive-background-color: $color-background-neutral-subtle !default;
$tracker-progress-complete-background-color: $color-on-background-brand-subtle !default;
$tracker-progress-complete-border-radius: 0 !default;
$tracker-progress-transition-duration: 800ms;
$tracker-progress-transition-timing-function: ease-out;

$tracker-segment-title-active-color: $color-foreground-brand !default;
$tracker-segment-title-inactive-color: $color-foreground-support !default;
$tracker-segment-title-complete-color: $color-foreground-default !default;

$tracker-segment-count-color: $color-foreground-support !default;
$tracker-segment-count-font-size: $font-size-subheader !default;

$tracker-segment-subtitle-color: $color-foreground-support !default;
$tracker-segment-subtitle-font-size: $font-size-subheader !default;

$tracker-segment-vertical-transition: width $tracker-progress-transition-duration
  $tracker-progress-transition-timing-function;
$tracker-segment-horizontal-transition: height $tracker-progress-transition-duration
  $tracker-progress-transition-timing-function;

.bb-tracker {
  display: flex;
  flex-direction: column;
}

.bb-tracker__segments-wrapper {
  display: flex;
  gap: $tracker-segment-gap;

  .bb-tracker--vertical & {
    flex-direction: column;
  }
}

.bb-tracker-segment {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;

  @include media-breakpoint-down(md) {
    &.bb-tracker-segment--active:not(.bb-tracker-segment--vertical) {
      flex-basis: $tracker-segment-active-sm-length;
    }
  }
}

.bb-tracker-segment__label,
.bb-tracker-segment__count {
  .bb-tracker-segment__title & {
    .bb-tracker-segment--active &,
    .bb-tracker-segment--complete & {
      font-weight: $font-weight-semibold;
    }
  }
}

.bb-tracker-segment--vertical {
  flex-direction: row;
  flex-basis: $tracker-segment-vertical-length;
}

.bb-tracker-segment__count {
  font-size: $tracker-segment-count-font-size;
  color: $tracker-segment-count-color;
  align-self: baseline;

  .bb-tracker-segment__title & {
    font-size: inherit;
    color: inherit;
  }
}

.bb-tracker-segment__bar-wrapper {
  display: flex;
  overflow: hidden;
  border-radius: $tracker-progress-border-radius;

  .bb-tracker-segment--active.bb-tracker-segment--vertical & {
    flex-direction: column;
    border-top: 1px solid $tracker-progress-active-background-color;
  }

  .bb-tracker-segment--active.bb-tracker-segment--horizontal & {
    border-inline-start: 1px solid $tracker-progress-active-background-color;
  }

  .bb-tracker-segment--active.bb-tracker-segment--vertical & {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .bb-tracker-segment--active.bb-tracker-segment--horizontal & {
    border-start-start-radius: 0;
    border-start-end-radius: $tracker-progress-border-radius;
    border-end-start-radius: 0;
    border-end-end-radius: $tracker-progress-border-radius;
  }

  .bb-tracker-segment--complete & {
    background-color: $tracker-progress-complete-background-color;
    border-radius: $tracker-progress-complete-border-radius;
  }
}

.bb-tracker-segment__bar {
  height: $tracker-progress-thickness;
  width: auto;
  flex-grow: 1;
  flex-shrink: 0;
  background-color: $tracker-progress-inactive-background-color;

  .bb-tracker-segment--vertical & {
    width: $tracker-progress-thickness;
    height: auto;
  }

  .bb-tracker-segment--active & {
    position: relative;

    &.bb-tracker-segment__bar--with-horizontal-gap {
      right: -$spacer-xs;

      &:before {
        content: "";
        width: 100%;
        height: $tracker-progress-thickness;
        position: absolute;
        left: -100%;
        box-shadow: $spacer-xs 0 0 0 $tracker-progress-inactive-background-color;
        border-radius: $spacer-xs / 2;
      }

      [dir="rtl"] & {
        left: -$spacer-xs;
        right: auto;

        &:before {
          right: -100%;
          box-shadow: -$spacer-xs 0 0 0 $tracker-progress-inactive-background-color;
        }
      }
    }

    &.bb-tracker-segment__bar--with-vertical-gap {
      bottom: -$spacer-xs;

      &:before {
        content: "";
        height: 100%;
        width: $tracker-progress-thickness;
        position: absolute;
        top: -100%;
        box-shadow: 0 $spacer-xs 0 0 $tracker-progress-inactive-background-color;
        border-radius: $spacer-xs / 2;
      }
    }
  }

  .bb-tracker-segment--complete & {
    background-color: $tracker-progress-complete-background-color;
  }
}

.bb-tracker-segment__progress {
  background-color: $tracker-progress-active-background-color;
  border-radius: $tracker-progress-border-radius;

  .bb-tracker-segment--vertical & {
    border-start-start-radius: 0;
    border-start-end-radius: 0;
  }

  .bb-tracker-segment--horizontal & {
    border-start-start-radius: 0;
    border-start-end-radius: $tracker-progress-border-radius;
    border-end-start-radius: 0;
    border-end-end-radius: $tracker-progress-border-radius;
  }

  @media (prefers-reduced-motion: no-preference) {
    &:not(.bb-tracker-ui--disable-animation &) {
      // transition within same section
      transition-duration: $tracker-progress-transition-duration;
      transition-timing-function: $tracker-progress-transition-timing-function;

      // section to section animation
      animation-duration: $tracker-progress-transition-duration;
      animation-timing-function: $tracker-progress-transition-timing-function;

      .bb-tracker-segment--vertical & {
        transition-property: height;

        .bb-tracker__segments-wrapper--animate-back & {
          animation-name: vertical-backward;
        }

        .bb-tracker__segments-wrapper--animate-forward & {
          animation-name: vertical-forward;
        }
      }

      .bb-tracker-segment--horizontal & {
        transition-property: width;

        .bb-tracker__segments-wrapper--animate-back & {
          animation-name: horizontal-backward;
        }

        .bb-tracker__segments-wrapper--animate-forward & {
          animation-name: horizontal-forward;
        }
      }
    }
  }

  @keyframes vertical-forward {
    from {
      height: 0;
    }
  }

  @keyframes vertical-backward {
    from {
      height: 100%;
    }
  }

  @keyframes horizontal-forward {
    from {
      width: 0;
    }
  }

  @keyframes horizontal-backward {
    from {
      width: 100%;
    }
  }
}

.bb-tracker-segment__title-wrapper {
  display: flex;
  flex-direction: column;
  padding-inline-start: $spacer-md;
  padding-inline-end: 1px;
  padding-block-start: $spacer-md;
  position: relative;

  &:before {
    content: "";
    background-color: $tracker-progress-active-background-color;
    position: absolute;
    top: 0;
    left: 0;
  }

  .bb-tracker-segment--horizontal &,
  .bb-tracker__title-wrapper-sm & {
    padding-inline-start: $spacer-sm;
  }

  .bb-tracker-segment.bb-tracker-segment--vertical & {
    &:before {
      width: 0;
      height: 1px;
    }
  }

  .bb-tracker-segment.bb-tracker-segment--horizontal & {
    &:before {
      width: 1px;
      height: 0;
    }
  }

  .bb-tracker-segment--active.bb-tracker-segment--vertical & {
    &:before {
      width: 100%;
    }
  }

  .bb-tracker-segment--active.bb-tracker-segment--horizontal & {
    &:before {
      height: 100%;
    }
  }

  @media (prefers-reduced-motion: no-preference) {
    &:not(.bb-tracker-ui--disable-animation &) {
      .bb-tracker-segment.bb-tracker-segment--vertical & {
        &:before {
          transition: $tracker-segment-vertical-transition;
        }
      }

      .bb-tracker-segment.bb-tracker-segment--horizontal & {
        &:before {
          transition: $tracker-segment-horizontal-transition;
        }
      }
    }
  }
}

.bb-tracker-segment__title {
  display: flex;
  align-items: center;
  gap: $spacer-xs;
  margin-bottom: $spacer-sm;
  color: $tracker-segment-title-inactive-color;
  font-size: $font-size-subtitle;

  .bb-tracker-segment--active &,
  .bb-tracker__title-wrapper-sm & {
    color: $tracker-segment-title-active-color;
    font-weight: $font-weight-semibold;
  }

  .bb-tracker-segment--complete & {
    color: $tracker-segment-title-complete-color;
  }

  .bb-tracker-segment--horizontal &,
  .bb-tracker__title-wrapper-sm & {
    margin-bottom: $spacer-xs;
  }

  .bb-tracker--static & {
    font-weight: $font-weight-semibold;
  }

  .bb-tracker-segment__count {
    flex-shrink: 0;
  }
}

.bb-tracker-segment__subtitle {
  color: $tracker-segment-subtitle-color;
  font-size: $tracker-segment-subtitle-font-size;
}

.bb-tracker-segment__title__icon {
  margin-inline-end: $spacer-xs;
}
