// Global annotations
////
/// @group forms
/// @access private
////

// Added: @1.47.0 => @1.48.0
$dropdown-multi-select-placeholder-color: $input-placeholder-color !default;
$dropdown-multi-select-disabled-toggle: $color-foreground-disabled !default;
$dropdown-multi-select-disabled-toggle-bg: $color-background-surface-2 !default;
$dropdown-multi-select-clear-button-color: $color-foreground-brand !default;
$dropdown-multi-select-clear-button-font-weight: $font-weight-bold !default;

$dropdown-multi-select-option-checkbox-dimensions: 1.5rem !default;
$input-multi-select-option-checkbox-checkbox-border-radius: $border-radius-xs !default;
$input-multi-select-option-checkbox-bg: $input-bg !default;
$input-multi-select-option-checkbox-border-color: $input-border-color !default;
$input-multi-select-option-checkbox-checked-bg: $color-background-brand !default;
$input-multi-select-option-checkbox-checked-border-color: $color-border-brand !default;
$input-multi-select-option-checkbox-checked-color: $color-on-background-brand !default;

.bb-dropdown-multi-select__toggle {
  &.form-control {
    background-color: $input-bg;
  }
  span:last-child {
    display: flex;
  }
}

.bb-dropdown-multi-select__result {
  width: calc(100% - #{$font-icon-size});
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.bb-dropdown-multi-select__options-list {
  max-height: $pre-scrollable-max-height;
  margin-block-end: $spacer-sm;
  overflow-y: auto;

  .dropdown-item {
    padding: 0;
  }

  .bb-input-checkbox {
    width: 100%;
    padding-block: $sizer-sm;
    padding-inline: $sizer-md;
  }

  .dropdown-option {
    width: auto;
    margin-block: 0;
    margin-inline: $spacer-md;
    padding: $spacer-xs;

    &::before {
      display: inline-block;
      width: $dropdown-multi-select-option-checkbox-dimensions;
      height: $dropdown-multi-select-option-checkbox-dimensions;
      margin-block: 0;
      margin-inline-start: 0;
      margin-inline-end: $spacer-sm;
      padding-inline-start: 1px;
      color: $input-multi-select-option-checkbox-checked-color;
      font-size: 1.3rem;
      font-family: "Material Icons Outlined", sans-serif;
      line-height: 1.2;
      vertical-align: bottom;
      background-color: $input-multi-select-option-checkbox-bg;
      border: 1px solid $input-multi-select-option-checkbox-border-color;
      border-radius: 0.25rem;
      cursor: pointer;
      content: "";
    }
  }

  .dropdown-option--selected {
    &::before {
      background-color: $input-multi-select-option-checkbox-checked-border-color;
      border-color: $input-multi-select-option-checkbox-checked-border-color;
      content: "check";
    }
  }

  .dropdown-option__input {
    display: none;
  }
}

.bb-dropdown-multi-select--disabled {
  cursor: not-allowed;
  pointer-events: none;

  .bb-dropdown-multi-select__toggle {
    color: $dropdown-multi-select-disabled-toggle;
    background-color: $dropdown-multi-select-disabled-toggle-bg;
    cursor: not-allowed;
  }
}

.bb-dropdown-multi-select__clear-button {
  margin-block-start: $spacer-sm;
  margin-inline-start: $spacer-md;
  padding: $sizer-xs;
  color: $dropdown-multi-select-clear-button-color;
  font-weight: $dropdown-multi-select-clear-button-font-weight;

  &:hover {
    color: $dropdown-multi-select-clear-button-color;
  }

  &:focus {
    border: $input-focus-border-primary;
    border-radius: $input-focus-dropdown-items-border-radius;
  }
}

.bb-dropdown-panel {
  .bb-dropdown-multi-select__toggle:focus {
    position: relative;
    border-color: $input-border-color;
    box-shadow: none;
    &::before {
      position: absolute;
      inset: $input-focus-outline-distance * 2;
      border: $input-focus-border-primary;
      border-radius: inherit;
      content: "";
    }
  }

  .bb-dropdown-panel__dropdown.show {
    .bb-dropdown-multi-select__options-list {
      &:focus {
        outline: 0;
      }
      .dropdown-item:focus,
      .dropdown-item.focus,
      .dropdown-item:active,
      .dropdown-item.active {
        background-color: transparent;
        border: $input-focus-border-primary;
        border-radius: $input-focus-dropdown-items-border-radius;
        outline: 0;
      }
    }
  }
}

.bb-dropdown-multi-select__label {
  display: inline-block;
  font-weight: $font-weight-semi-bold;
}
