// Global annotations
////
/// @group navigation
/// @access private
////

$nav-tabs-inverse-link-color: $color-on-background-inverted !default;
$nav-tabs-inverse-link-focus-border-color: 3px solid $color-on-background-inverted !default;
$nav-tabs-inverse-link-hover-border-color: $color-background-neutral-hovered !default;
$nav-tabs-inverse-link-active-border-color: $color-background-brand !default;
$nav-tabs-inverse-link-active-hover-border-color: $color-background-brand-hovered !default;
$nav-tabs-inverse-link-border-color: $color-on-background-inverted !default;
$nav-tabs-inverse-link-disabled-color: $color-foreground-disabled !default;

$nav-tabs-on-color-link-color: $color-foreground-on-color !default;
$nav-tabs-on-color-link-focus-border-color: 3px solid $color-focus-on-color-outline !default;
$nav-tabs-on-color-link-active-hover-bg: $color-background-overlay-hovered !default;
$nav-tabs-on-color-link-hover-border-color: $color-background-overlay-hovered !default;
$nav-tabs-on-color-link-active-border-color: $color-background-on-color !default;
$nav-tabs-on-color-link-border-color: $color-border-on-color !default;
$nav-tabs-on-color-link-disabled-color: $color-foreground-disabled-on-color !default;
$nav-tabs-on-color-link-active-hover-border-color: $color-background-on-color-hovered !default;

.bb-tab--on-color {
  @include navigation-tabs(
    $nav-tabs-on-color-link-color,
    $nav-tabs-on-color-link-focus-border-color,
    $nav-tabs-on-color-link-hover-border-color,
    $nav-tabs-on-color-link-active-border-color,
    $nav-tabs-on-color-link-border-color,
    $nav-tabs-on-color-link-disabled-color,
    $nav-tabs-on-color-link-active-hover-border-color
  );
}

.bb-tab--inverse {
  @include navigation-tabs(
    $nav-tabs-inverse-link-color,
    $nav-tabs-inverse-link-focus-border-color,
    $nav-tabs-inverse-link-hover-border-color,
    $nav-tabs-inverse-link-active-border-color,
    $nav-tabs-inverse-link-border-color,
    $nav-tabs-inverse-link-disabled-color,
    $nav-tabs-inverse-link-active-hover-border-color
  );
}
