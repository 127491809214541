.design-token-list {
  display: flex;
  flex-direction: column;
  gap: $spacer-lg;

  &__description {
    color: $color-neutral-dark;
    margin-block-end: unset;
  }

  &__tabs {
    // override bootstrap
    .nav-tabs {
      border-bottom: 0;
      margin-block-end: unset;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .nav-link {
      color: $color-neutral-greyest !important;
      width: max-content;

      &.active {
        color: $color-neutral-darkest !important;

        &::after {
          background-color: $color-neutral-darkest !important;
        }
      }
    }
  }

  // filters section (search + platform dropdown)
  &__filters {
    display: grid;

    grid-template-columns: 1fr 1fr;

    @include media-breakpoint-up(sm) {
      grid-template-columns: 2fr 1fr;
    }

    @include media-breakpoint-up(md) {
      grid-template-columns: 3fr 1fr;
    }

    @include media-breakpoint-up(lg) {
      grid-template-columns: 5fr 1fr;
    }
  }

  &__filters-dropdown {
    grid-column-start: 2;

    .bb-dropdown__select {
      border-radius: $border-radius * 0.125;
    }
  }

  &__table {
    position: relative;

    &--overflow {
      position: absolute;
      right: 0;
      top: 3rem; // approximate size of table header

      background: linear-gradient(270deg, $color-neutral-black 14.06%, rgba(0, 0, 0, 0) 100%);
      width: 40px;
      height: calc(100% - 3rem);
      opacity: 0.2;

      @include media-breakpoint-up(lg) {
        display: none;
      }
    }
  }

  &__table-list {
    overflow-x: auto;
  }
}
