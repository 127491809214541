.mini-menu {
  position: fixed;
  width: 100%;
  max-width: $mini-menu-inline-offset;

  &__title {
    color: $color-neutral-dark;
    font-size: $font-size-subheader;
    font-weight: $font-weight-regular;
    line-height: 1.125rem;
    text-transform: uppercase;
  }

  &__item {
    padding: $sizer-sm;
    margin-bottom: 0; // override reset margins

    &--active {
      background-color: $color-neutral-lighter;
    }
  }

  &__item-link {
    color: $color-neutral-dark;
    font-size: $font-size-subtitle;
    line-height: 1.5rem;

    &--active {
      color: $color-neutral-darker;
      font-weight: $font-weight-semi-bold;
    }
  }
}
