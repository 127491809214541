// Global annotations
////
/// @group forms
/// @access private
////

$ng-select-primary-text: $color-foreground-default !default;
$ng-select-disabled-text: $color-foreground-disabled !default;
$ng-select-border: $color-border-default !default;
$ng-select-border-radius: 4px !default;
$ng-select-bg: $color-background-surface-1 !default;
$ng-select-selected: $color-background-surface-1 !default;
$ng-select-marked: $color-background-surface-1 !default;
$ng-select-box-shadow:
  inset 0 1px 1px rgba(0, 0, 0, 0.075),
  0 0 0 3px rgba(0, 126, 255, 0.1) !default;
$ng-select-placeholder: $color-foreground-support !default;
$ng-select-height: 36px !default;
$ng-select-value-padding-left: 10px !default;
$ng-select-value-font-size: 0.9em !default;
