////
/// @group ui-components
/// @access private
////

$page-header-padding-block-end: 12.5 * $sizer; // 200px

.bb-page-header--with-background {
  @extend .btn-on-color;

  .bb-dropdown-btn-group-wrapper {
    &.show {
      > .btn-primary:not(:disabled):not(.disabled):focus {
        $primary-btn: map-get($theme-button-on-color-colors, "primary");

        background-color: map-get($primary-btn, "active-bg");
        border-color: map-get($primary-btn, "active-bg");
      }

      > .btn-secondary:not(:disabled):not(.disabled):focus {
        $secondary-btn: map-get($theme-button-on-color-colors, "primary");

        background-color: map-get($secondary-btn, "active-bg");
        border-color: map-get($secondary-btn, "active-bg");
        color: map-get($secondary-btn, "active-color");
      }
    }
  }

  background-color: $color-background-brand;
  color: $color-on-background-brand;
  padding-block-end: $page-header-padding-block-end;
  padding-inline: $sizer-sm;

  @include media-breakpoint-up(sm) {
    border-radius: $border-radius;
    padding-inline: $sizer-xl;
  }

  .bb-page-header__hero-content-title,
  .bb-page-header__hero-content-subtitle {
    color: $color-on-background-brand;
  }
}

.bb-page-header__hero {
  padding-block: $sizer $sizer-lg;

  .bb-button-bar {
    @include media-breakpoint-up(sm) {
      width: auto;
    }
  }
}

.bb-page-header__hero-navigation-link {
  display: flex;
  height: $btn-min-height;
}

.bb-page-header__hero-content {
  @extend .bb-stack;
  @extend .bb-stack--align-top;
  @extend .bb-stack--space-between;
  @extend .bb-stack--wrap;

  gap: $spacer $spacer-lg;
}

.bb-page-header__hero-content-title {
  min-width: 320px; // fixed value
  margin-bottom: $spacer-xs;
  line-height: $line-height-base;
}

.bb-page-header__hero-content-subtitle {
  @extend .bb-highlight;
  @extend .bb-highlight--regular;

  color: $color-foreground-support;
}
