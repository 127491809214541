// Global annotations
////
/// @group widgets
/// @access private
////

.bb-day-month-icon {
  box-shadow: 0px 0px 2px 1px rgb(0 0 0 / 0.06);
  padding: 5.5px 5px;
  border-radius: $border-radius-xs;
  line-height: 10px;
  width: 40px;
}
.bb-day-month-icon__month {
  letter-spacing: 0.11rem;
  color: $color-text-danger;
  margin-bottom: $spacer-xs;
  font-size: 0.625rem;
  text-transform: uppercase;
}
