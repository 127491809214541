// Global annotations
////
/// @group journeys
/// @access private
////

$orders-list-grouped-item-background: $color-background-surface-1-hovered !default;

.bb-orders-list-grouped__item {
  position: relative;
  padding-bottom: $sizer;
  padding-top: $sizer;
  &:hover {
    background-color: $orders-list-grouped-item-background;
  }
}
