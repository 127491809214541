// Global annotations
////
/// @group widgets
/// @access private
////

:host {
  display: block;
  padding-bottom: var(--buttons-height);

  @include media-breakpoint-up(md) {
    display: flex;
    padding-bottom: 0;
  }
}

.bb-responsive-button-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: $spacer-xl $spacer-md;
  background-color: $color-background-surface-1;

  @include media-breakpoint-up(md) {
    position: initial;
    padding: 0;
    background-color: transparent;
  }
}
