// Global annotations
////
/// @group journeys
/// @access private
////

$data-group-name-container-padding: $sizer-xs !default;
$accounts-payees__table-cell--min-width: 12.5rem !default;

.bb-accessgroup-accounts-payees-table {
  .accounts-payees__table-cell--width {
    max-width: 0;
    min-width: $accounts-payees__table-cell--min-width;
  }

  .accounts-payees__table-row--no-bottom-padding {
    padding-bottom: 0;
  }

  .data-group__cta-btn--no-min-height {
    button {
      min-height: 0;
    }
  }

  .data-group__container--align-baseline {
    align-items: baseline;
  }
  .data-group__name-container--padding {
    padding: $data-group-name-container-padding;
  }
}
