////
/// @group ui-components
/// @access private
////

$empty-state-title-line-height: 1.5 !default;

$empty-state-padding-x: 2.5rem !default;
$empty-state-padding-y: 2.5rem !default;

.bb-empty-state {
  @include setOptional(min-width, $empty-state-container-min-width);
  margin-block: 0;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacer;
  padding: $empty-state-padding-x $empty-state-padding-y;
}

.bb-empty-state__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacer-xs;
}

.bb-empty-state__title {
  margin-block-end: 0;
  color: $empty-state-title-color;
  font-weight: $empty-state-title-font-weight;
  @include setOptional(font-size, $empty-state-title-font-size);
  line-height: $empty-state-title-line-height;
  text-align: center;
}

.bb-empty-state__message {
  margin-block-end: 0;
  color: $empty-state-message-color;
  @include setOptional(font-weight, $empty-state-message-font-weight);
  font-size: $empty-state-message-font-size;
  text-align: center;
}

.bb-empty-state__footer {
  display: flex;
  gap: $spacer * 0.625;

  button {
    gap: $spacer-xs;
  }
}
