////
/// Colors (global)
/// @group 1-variables/semantic/color/link
/// @access public
////

/* stylelint-disable color-no-hex */

/// figma equivalent link/default
$color-link-default: color-from-semantic("link/default") !default;

/// figma equivalent link/hovered
$color-link-hovered: color-from-semantic("link/hovered") !default;

/// figma equivalent link/pressed
$color-link-pressed: color-from-semantic("link/pressed") !default;

/// figma equivalent link/disabled
$color-link-disabled: color-from-semantic("link/disabled") !default;

/// figma equivalent link/on-color/default
$color-link-on-color-default: color-from-semantic("link/on-color/default") !default;

/// figma equivalent link/on-color/hovered
$color-link-on-color-hovered: color-from-semantic("link/on-color/hovered") !default;

/// figma equivalent link/on-color/pressed
$color-link-on-color-pressed: color-from-semantic("link/on-color/pressed") !default;

/// figma equivalent link/on-color/disabled
$color-link-on-color-disabled: color-from-semantic("link/on-color/disabled") !default;
