// Global annotations
////
/// @group widgets
/// @access private
////

$bb-messages-conversations-list-padding: $sizer-xl !default;

.bb-messages-conversations-list__container {
  @include media-breakpoint-up(sm) {
    padding: $bb-messages-conversations-list-padding;
  }
}
