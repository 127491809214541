////
/// Colors (global)
/// @group 1-variables/semantic/color/foreground
/// @access public
////

/* stylelint-disable color-no-hex */

/// figma equivalent foreground/default
$color-foreground-default: color-from-semantic("foreground/default") !default;

/// figma equivalent foreground/support
$color-foreground-support: color-from-semantic("foreground/support") !default;

/// figma equivalent foreground/disabled
$color-foreground-disabled: color-from-semantic("foreground/disabled") !default;

/// figma equivalent foreground/disabled-on-color
$color-foreground-disabled-on-color: color-from-semantic("foreground/disabled-on-color") !default;

/// figma equivalent foreground/brand
$color-foreground-brand: color-from-semantic("foreground/brand") !default;

/// figma equivalent foreground/info
$color-foreground-info: color-from-semantic("foreground/info") !default;

/// figma equivalent foreground/success
$color-foreground-success: color-from-semantic("foreground/success") !default;

/// figma equivalent foreground/warning
$color-foreground-warning: color-from-semantic("foreground/warning") !default;

/// figma equivalent foreground/danger
$color-foreground-danger: color-from-semantic("foreground/danger") !default;

/// figma equivalent foreground/category-1
$color-foreground-category-1: color-from-semantic("foreground/category-1") !default;

/// figma equivalent foreground/category-2
$color-foreground-category-2: color-from-semantic("foreground/category-2") !default;

/// figma equivalent foreground/category-3
$color-foreground-category-3: color-from-semantic("foreground/category-3") !default;

/// figma equivalent foreground/category-4
$color-foreground-category-4: color-from-semantic("foreground/category-4") !default;

/// figma equivalent foreground/category-5
$color-foreground-category-5: color-from-semantic("foreground/category-5") !default;

/// figma equivalent foreground/category-6
$color-foreground-category-6: color-from-semantic("foreground/category-6") !default;

/// figma equivalent foreground/category-7
$color-foreground-category-7: color-from-semantic("foreground/category-7") !default;

/// figma equivalent foreground/category-8
$color-foreground-category-8: color-from-semantic("foreground/category-8") !default;

/// figma equivalent foreground/category-9
$color-foreground-category-9: color-from-semantic("foreground/category-9") !default;

/// figma equivalent foreground/category-10
$color-foreground-category-10: color-from-semantic("foreground/category-10") !default;

/// figma equivalent foreground/on-color
$color-foreground-on-color: color-from-semantic("foreground/on-color") !default;
