.ds-credit-report-task-view__experian-logo {
  width: 7.5 * $sizer;
}

.ds-credit-report-task-view__actions-title {
  color: $color-foreground-info;
}

.ds-credit-report-task-view__table-wrapper {
  overflow: auto;
}
