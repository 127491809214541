// Global annotations
////
/// @group journeys
/// @access private
////

$service-agreement-search-card-body-width: $sizer * 35 !default;
$service-agreement-search-box-min-width: $sizer * 16 !default;
$service-agreement-search-switch-min-width: $sizer * 8.75 !default;

.service-agreement-list {
  .bb-card__body {
    @include media-breakpoint-down(md) {
      width: 100%;
    }

    width: $service-agreement-search-card-body-width;
  }

  bb-search-box-ui {
    width: 100%;
    min-width: $service-agreement-search-box-min-width;
  }

  bb-switch-ui {
    min-width: $service-agreement-search-switch-min-width;
  }

  .label-optional {
    font-weight: 400;
    color: $color-foreground-support;
  }

  .bb-state-container .divider-container {
    max-width: $sizer * 30;
    width: 100%;
  }

  form {
    width: 100%;
  }

  .form-controls-container {
    @include media-breakpoint-down(md) {
      align-items: start;
      flex-direction: column;
    }
  }

  .sa-bank-search {
    .horizontal {
      .form-controls-container {
        @include media-breakpoint-down(md) {
          margin-block-end: $sizer * 0.5;
        }
      }

      .user-id-container {
        @include media-breakpoint-down(md) {
          margin-bottom: $sizer * 0.5;
        }

        flex-basis: fit-content;
        flex-grow: 2;
        position: relative;

        bb-search-box-ui {
          width: 100%;
          min-width: $sizer * 11;
        }
      }

      .sa-name-control {
        bb-search-box-ui {
          @include media-breakpoint-down(md) {
            margin-bottom: $sizer * 0.5;
            flex-wrap: wrap;
          }
        }
      }

      .bb-button-bar {
        justify-content: flex-end;
      }
    }

    .sa-name-container {
      flex-basis: fit-content;
      flex-grow: 3;
    }

    .user-id-container:has(.user-id-validation-error) {
      @include media-breakpoint-down(md) {
        margin-bottom: $sizer * 2;
      }
    }
  }

  .user-id-validation-error {
    bottom: $sizer * -1.75;
    position: absolute;
    white-space: nowrap;
  }
}
