.showcase-title {
  padding-block-start: $sizer-lg;
  padding-block-end: $sizer-sm;
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  color: $color-neutral-darker;
  font-size: $font-size-highlight;
  font-style: normal;
  font-weight: $font-weight-semi-bold;
  line-height: 1.6875rem; // 27px
}

.showcase-interactive-demo {
  background: $color-neutral-lighter;
  border: $border;
  border-radius: $border-radius * 0.5;
  padding: $sizer-lg;

  code {
    padding: unset;
  }
}

.title-padding {
  padding-block: $sizer;
}

.code-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  background-color: $color-neutral-lighter;
  border: $border;
  border-radius: $border-radius * 0.5;
  padding: $sizer-lg;

  code {
    // override dso-markdown-content styles
    background-color: transparent;
  }
}

bb-showcase-description {
  pre {
    border: $border;
    border-radius: $border-radius * 0.5;
    background-color: $color-neutral-lighter;
  }
}
