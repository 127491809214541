.page-title {
  font-size: $font-size-hero;
  font-weight: $font-weight-bolder;
  display: block;
  margin-bottom: 2rem;
  line-height: $line-height-heading;

  small {
    font-size: $font-size-h2;
    font-weight: $font-weight-regular;
    color: $color-neutral-greyest;
    display: block;
  }
}

.page-title--home {
  font-size: 80px;

  small {
    font-size: 50px;
  }
}

.page-intro {
  font-size: 2rem;
  line-height: 50px;
  font-weight: $font-weight-lighter;
  color: $color-neutral-dark;
  padding-bottom: 2em;
}

.page-section {
  font-size: $font-size-h2;
  font-weight: $font-weight-bolder;
  line-height: 2;
  color: $color-neutral-black;
}

.subtext {
  font-size: $font-size-subtitle;
  color: $color-neutral-dark;
}

.subheader {
  font-size: $font-size-subheader;
  font-weight: $font-weight-bolder;
  text-transform: uppercase;
}

.subtitle {
  font-size: $font-size-subtitle;
  font-weight: $font-weight-bolder;
  color: $color-neutral-dark;
}

.highlight {
  font-size: $font-size-highlight;
  font-weight: $font-weight-normal;
}

.blockquote {
  position: relative;
  padding: 0.5em 0 0.5em 1em;
  border-left: 2px solid #666666;
  font-size: $font-size-base;
  font-weight: $font-weight-light;
  display: flex;
  flex-direction: column;

  footer {
    font-size: 0.7em;
    font-weight: 600;
    color: #d9d9d9;
    display: flex;
    justify-content: flex-end;
    padding-top: $spacer-sm;

    &:before {
      content: "\2015";
    }
  }
}

blockquote {
  margin-block-start: 1rem;
  margin-block-end: 1rem;

  padding-block-start: 0.5rem;
  padding-block-end: 0.5rem;

  p {
    border-radius: $alert-border-radius;
    background-color: $color-primary-lightest;
    padding: 1.15rem;
    font-size: $font-size-base;
    border: 6px solid $color-primary;
    border-right: 0;
    border-top: 0;
    border-bottom: 0;
    padding-block-start: 0.75rem;
    padding-block-end: 0.75rem;
    margin-bottom: 0;
    box-shadow: 0 13px 13px -16px rgba(0, 0, 0, 50%);
  }
}

.bb-header--not-found {
  display: grid;
  justify-items: center;
  .bb-header--not-found__title {
    font-size: $font-size-h2;
    font-weight: 500;
    line-height: 2rem;
    padding-top: $sizer-lg;
    padding-bottom: $sizer-sm;
    margin-bottom: 10px;
  }

  .bb-header--not-found__content {
    color: $color-neutral-dark;
    margin-bottom: 10px;
    padding: 0px 0px 8px 0px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }
  &__btn {
    width: 100%;
    padding-top: 15vh;

    @include media-breakpoint-up(sm) {
      width: auto;
      padding-top: $sizer-lg;
      padding-bottom: $sizer-sm;
      margin-right: calc(1rem * 0.625);
    }
  }
}
