// Global annotations
////
/// @group journey-specific
/// @access public
////

.bb-ac-anchor-button {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;

  &::before {
    content: "";
    display: block;
    height: 1rem;
    background: linear-gradient(
      to top,
      $color-background-surface-1,
      rgba($color-background-surface-1, 0)
    );
  }

  & > div {
    background-color: $color-background-surface-1;
  }
}
