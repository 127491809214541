// Global annotations
////
/// @group journeys
/// @access private
////

$instrument-icon-size-map: (
  sm: $spacer * 2.5,
  md: $spacer * 3,
  lg: $spacer * 4,
  xl: $spacer * 5,
);

.bb-instrument-logo {
  border-radius: $border-radius-sm;
  object-fit: cover;

  @each $size-abbr, $size in $instrument-icon-size-map {
    &--#{$size-abbr} {
      width: $size;
      height: $size;
    }
  }
}

.bb-instrument-icon-wrapper {
  position: relative;

  &::before {
    width: 100%;
    height: 100%;
    display: block;
    content: "";
    border: 1px solid transparent;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: $border-radius-sm;
  }
}

.bb-instrument-table-row:not(.disable-hover) {
  &:hover {
    .bb-instrument-icon-wrapper {
      &::before {
        border-color: $color-border-default;
      }
    }
  }
}
