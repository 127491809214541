// Global annotations
////
/// @group journey-specific
/// @access public
////

/// Configure the spacing of exception details user info card for extra small devices
$positive-pay-user-info-xs-sizer: $sizer-sm !default;

// Configure the height of check images
$positive-pay-check-img-height: 15.75rem !default;
// Configure the width of check image container
$positive-pay-check-img-width: 50% !default;
// Configure the spacing for the image viewer button
$positive-pay-check-img-button-spacer-sm: $spacer-sm !default;

/// @example html
/// <div role="row" class="bb-stack bb-positive-pay-exception-details-row">
///  <div class="bb-card bb-card--sm bb-card--highlight bb-card--shadowless bb-positive-pay-user-info bb-positive-pay-full-height">
///   <div class="bb-card__body bb-positive-pay-user-info__inner">Some content</div>
///  </div>
/// </div>
///
.bb-positive-pay-exception-details-row {
  align-items: stretch;
}

.bb-positive-pay-user-info {
  &:not(.bb-card--ignore).bb-card--sm.bb-card {
    .bb-positive-pay-user-info__inner {
      @include media-breakpoint-down(sm) {
        padding: $positive-pay-user-info-xs-sizer;
      }
    }
  }
}

/// @example html
/// <div class="bb-stack bb-positive-pay-exception-error">
///  <bb-icon-ui class="bb-stack__item bb-positive-pay-exception-error__icon bb-positive-pay-exception-error__icon--inline" name="error-outline"></bb-icon-ui>
///  <span class="bb-stack__item bb-positive-pay-exception-error__date">Dec 21, 2021</span>
/// </div>
///
.bb-positive-pay-exception-error__icon--inline {
  display: inline-block;
}

.bb-positive-pay-exception-error__date {
  white-space: pre-wrap;
}

/// @example html
/// <div class="bb-stack bb-positive-pay-check-images">
///   <div class="bb-stack__item bb-positive-pay-check-images__front">
///     <div class="bb-stack__item bb-positive-pay-check-image">
///       <div class="bb-positive-pay-check-image__overlay">
///         <button class="bb-positive-pay-check-image__viewer-button">
///           <i name="zoom-in" size="lg" aria-label="Expand Image"></i>
///         </button>
///       </div>
///     </div>
///     <img class="bb-positive-pay-check-image__img" alt="image name" [src]="imageURl" />
///   </div>
/// </div>
///

.bb-positive-pay-check-images__front {
  width: $positive-pay-check-img-width;
}

.bb-positive-pay-check-images__back {
  width: $positive-pay-check-img-width;
}

.bb-positive-pay-check-image {
  position: relative;
  height: $positive-pay-check-img-height * 0.28;

  @include media-breakpoint-up(sm) {
    height: $positive-pay-check-img-height * 0.451;
  }
}

.bb-positive-pay-check-image__overlay {
  position: absolute;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  right: 0;
  min-height: $positive-pay-check-img-height * 0.28;
  @include media-breakpoint-up(sm) {
    min-height: $positive-pay-check-img-height * 0.451;
  }
}

.bb-positive-pay-check-image__viewer-button {
  margin-block-end: $positive-pay-check-img-button-spacer-sm;
  margin-inline-end: $positive-pay-check-img-button-spacer-sm;
}

.bb-positive-pay-check-image__img {
  height: 100%;
  width: 100%;
}
