// Global annotations
////
/// @group widgets
/// @access private
////

/// @name bb-business-function-privileges-name
///
/// @example html
/// <span class="col bb-business-function-privilege-name-item bb-text-align-center">View</span>
.bb-business-function-privilege-name-item {
  padding-right: 0;
  padding-left: 0;
}
