// Global annotations
////
/// @group widgets
/// @access private
////

.bb-valuation__header {
  display: grid;
  column-gap: $spacer-lg;
  grid-template-areas:
    "chart-name"
    "trading-periods";

  @include media-breakpoint-up(md) {
    grid-template-columns: auto 25rem;
    grid-template-areas: "chart-name trading-periods";
    margin-block-end: $sizer-md;
  }
}

.bb-valuation__header--settings {
  grid-template-areas:
    "chart-name chart-settings"
    "trading-periods trading-periods";

  @include media-breakpoint-up(md) {
    grid-template-columns: 1fr repeat(2, auto);
    grid-template-areas: "chart-name trading-periods chart-settings";
  }
}

.bb-valuation__header--multiple.bb-valuation__header--has-toggle {
  gap: $spacer-md;
  grid-template-areas:
    "chart-name toggle"
    "trading-periods trading-periods";

  @include media-breakpoint-up(md) {
    grid-template-columns: auto 25rem;
    grid-template-areas:
      "chart-name chart-name"
      "toggle trading-periods";
  }
}

.bb-valuation__header-chart-name {
  grid-area: chart-name;
  align-content: center;
  min-height: 1.875rem;
  margin-block-end: $sizer-lg;

  @include media-breakpoint-up(md) {
    min-height: 2.5rem;
    margin-block-end: 0;
  }
}

.bb-valuation__header--multiple.bb-valuation__header--has-toggle .bb-valuation__header-chart-name {
  margin-block-end: 0;

  @include media-breakpoint-up(md) {
    box-sizing: content-box;
    padding-block-end: $spacer-sm;
    border-bottom: 1px solid $color-border-subtle;
  }
}

.bb-valuation__header--multiple .bb-valuation__header-toggle {
  @media (max-width: 767px) {
    grid-area: toggle;
    justify-self: end;
  }
}

.bb-valuation__header-trading-periods {
  grid-area: trading-periods;
  align-content: center;
}

.bb-valuation__header-chart-settings {
  grid-area: chart-settings;
  align-content: center;
}

.bb-valuation__header-data-slot {
  grid-area: toggle;
  justify-self: left;
}

.bb-valuation__header--multiple .bb-valuation__header-data-slot {
  @media (max-width: 767px) {
    grid-area: chart-name;
    justify-self: left;
  }
}

.bb-valuation__header-trading-periods .bb-button-bar {
  justify-content: space-between;
}

.bb-valuation__header-trading-periods .bb-button-bar__button {
  width: auto;
  min-height: inherit;
  padding: $spacer-xs 0.75rem;
  margin: 0;
  border-radius: $border-radius-xs;
}
