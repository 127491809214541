// Global annotations
////
/// @group ui-components
/// @access private
////

$bb-char-counter-color: $color-foreground-support !default;
$bb-char-counter-invalid-color: $color-foreground-danger !default;

.bb-char-counter {
  display: block;
  margin-block-start: $spacer-sm;
  color: $bb-char-counter-color;
}

.bb-char-counter--invalid {
  color: $bb-char-counter-invalid-color;
}
