.expanded {
  max-height: auto;
}

.copy-code-icon {
  position: absolute;
  right: 0;
  cursor: pointer;

  @include media-breakpoint-down(md) {
    top: -2rem;
  }
}

.code-snippet-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  bb-icon-ui {
    padding: $sizer-lg;
  }
}

/* Compodoc Vendor Styles [START BLOCK] */
.api-body {
  max-width: 1200px;

  &.table-responsive {
    overflow-x: initial;
  }

  table {
    margin: 12px 0 3rem !important;

    th {
      text-transform: none;
      font-size: 16px;
      font-weight: bold;
    }

    tr {
      border-bottom: 1px solid lightgray;
    }

    td {
      vertical-align: middle;
    }

    td:first-of-type,
    th:first-of-type {
      padding-left: 24px !important;
    }

    td:last-of-type,
    th:last-of-type {
      padding-right: 24px !important;
    }

    td,
    th {
      padding: 16px 12px !important;
      p {
        margin: 0;
        padding: 0;
      }
    }

    td:nth-child(1) {
      min-width: 150px;
    }

    td:nth-child(2) {
      min-width: 200px;
    }

    td:nth-child(3) {
      max-width: 55%;
    }

    hr {
      margin: 16px 0;
    }

    tr:last-child {
      border-bottom: none;
    }

    &.item-table {
      td {
        padding: 32px;
      }
    }

    &.list-table {
      td {
        padding: 0.5em 1em;
      }
    }

    .short-description {
      margin-left: 0;
    }
  }
}

table.is-full-width {
  width: 100%;
}

table.is-fixed-layout {
  table-layout: fixed;
}

#cheatsheet table tbody td {
  overflow: auto;
}

@media only screen and (max-width: 990px) {
  #cheatsheet table,
  #cheatsheet tbody,
  #cheatsheet td,
  #cheatsheet tfoot,
  #cheatsheet th,
  #cheatsheet thead,
  #cheatsheet tr {
    display: block;
    position: relative;
    max-width: 100%;
  }

  #cheatsheet table code,
  #cheatsheet tbody code,
  #cheatsheet td code,
  #cheatsheet tfoot code,
  #cheatsheet th code,
  #cheatsheet thead code,
  #cheatsheet tr code {
    padding: 0;
    background-color: inherit;
  }

  #cheatsheet th {
    border-right: none;
  }

  #cheatsheet td:not(:last-child),
  #cheatsheet th:not(:last-child) {
    border-bottom: none;
    padding-bottom: 0;
  }
}

/* Compodoc Vendor Styles [END BLOCK] */

/* PrismJS 1.15.0 [START BLOCK]
https://prismjs.com/download.html#themes=prism-tomorrow&languages=markup */
/**
 * prism.js tomorrow night eighties for JavaScript, CoffeeScript, CSS and HTML
 * Based on https://github.com/chriskempson/tomorrow-theme
 * @author Rose Pritchard
 */

code[class*="language-"],
pre,
pre[class*="language-"] {
  color: $color-neutral-black;
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;

  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;

  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;

  @include media-breakpoint-up(xl) {
    max-width: 72ch;
  }

  @include media-breakpoint-up(xxl) {
    max-width: 100ch;
  }
}

pre {
  padding: 2em;
}
/* Code blocks */
pre[class*="language-"] {
  margin: 0.5em 0;
  overflow: auto;
  border: $border-width $border-style $color-neutral-light;
}

/* Inline code */
:not(pre) > code[class*="language-"] {
  padding: 0.1em;
  border-radius: 0.3em;
  white-space: normal;
}

.token.comment,
.token.block-comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: $color-neutral-dark;
}

.token.punctuation {
  color: $color-neutral-dark;
}

.token.tag,
.token.attr-name,
.token.namespace,
.token.deleted {
  color: $color-warning-darker;
}

.token.function-name {
  color: $color-primary-darker;
}

.token.boolean,
.token.number,
.token.function {
  color: $color-accent-darker;
}

.token.property,
.token.class-name,
.token.constant,
.token.symbol {
  color: $color-warning-darker;
}

.token.selector,
.token.important,
.token.atrule,
.token.keyword,
.token.builtin {
  color: $color-primary-darker;
}

.token.string,
.token.char,
.token.attr-value,
.token.regex,
.token.variable {
  color: $color-success-darker;
}

.token.operator,
.token.entity,
.token.url {
  color: $color-info-darker;
}

.token.important,
.token.bold {
  font-weight: bold;
}
.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}

.token.inserted {
  color: green;
}

/*PRISMJS VENDOR END BLOCK*/
