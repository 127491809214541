////
/// Colors (global)
/// @group 1-variables/semantic/color/background
/// @access public
////

/* stylelint-disable color-no-hex */

/// figma equivalent background/none
$color-background-none: color-from-semantic("background/none") !default;

/// figma equivalent background/page
$color-background-page: color-from-semantic("background/page") !default;

/// figma equivalent background/surface-1
$color-background-surface-1: color-from-semantic("background/surface-1") !default;

/// figma equivalent background/surface-1-hovered
$color-background-surface-1-hovered: color-from-semantic("background/surface-1-hovered") !default;

/// figma equivalent background/surface-1-pressed
$color-background-surface-1-pressed: color-from-semantic("background/surface-1-pressed") !default;

/// figma equivalent background/surface-2
$color-background-surface-2: color-from-semantic("background/surface-2") !default;

/// figma equivalent background/surface-2-hovered
$color-background-surface-2-hovered: color-from-semantic("background/surface-2-hovered") !default;

/// figma equivalent background/surface-2-pressed
$color-background-surface-2-pressed: color-from-semantic("background/surface-2-pressed") !default;

/// figma equivalent background/brand-subtle
$color-background-brand-subtle: color-from-semantic("background/brand-subtle") !default;

/// figma equivalent background/brand
$color-background-brand: color-from-semantic("background/brand") !default;

/// figma equivalent background/brand-pressed
$color-background-brand-pressed: color-from-semantic("background/brand-pressed") !default;

/// figma equivalent background/brand-hovered
$color-background-brand-hovered: color-from-semantic("background/brand-hovered") !default;

/// figma equivalent background/neutral-subtle
$color-background-neutral-subtle: color-from-semantic("background/neutral-subtle") !default;

/// figma equivalent background/neutral
$color-background-neutral: color-from-semantic("background/neutral") !default;

/// figma equivalent background/neutral-pressed
$color-background-neutral-pressed: color-from-semantic("background/neutral-pressed") !default;

/// figma equivalent background/neutral-hovered
$color-background-neutral-hovered: color-from-semantic("background/neutral-hovered") !default;

/// figma equivalent background/info-subtle
$color-background-info-subtle: color-from-semantic("background/info-subtle") !default;

/// figma equivalent background/info
$color-background-info: color-from-semantic("background/info") !default;

/// figma equivalent background/info-pressed
$color-background-info-pressed: color-from-semantic("background/info-pressed") !default;

/// figma equivalent background/info-hovered
$color-background-info-hovered: color-from-semantic("background/info-hovered") !default;

/// figma equivalent background/success-subtle
$color-background-success-subtle: color-from-semantic("background/success-subtle") !default;

/// figma equivalent background/success
$color-background-success: color-from-semantic("background/success") !default;

/// figma equivalent background/success-pressed
$color-background-success-pressed: color-from-semantic("background/success-pressed") !default;

/// figma equivalent background/success-hovered
$color-background-success-hovered: color-from-semantic("background/success-hovered") !default;

/// figma equivalent background/warning-subtle
$color-background-warning-subtle: color-from-semantic("background/warning-subtle") !default;

/// figma equivalent background/warning
$color-background-warning: color-from-semantic("background/warning") !default;

/// figma equivalent background/warning-pressed
$color-background-warning-pressed: color-from-semantic("background/warning-pressed") !default;

/// figma equivalent background/warning-hovered
$color-background-warning-hovered: color-from-semantic("background/warning-hovered") !default;

/// figma equivalent background/danger-subtle
$color-background-danger-subtle: color-from-semantic("background/danger-subtle") !default;

/// figma equivalent background/danger-subtle
$color-background-danger: color-from-semantic("background/danger") !default;

/// figma equivalent background/danger-pressed
$color-background-danger-pressed: color-from-semantic("background/danger-pressed") !default;

/// figma equivalent background/danger-hovered
$color-background-danger-hovered: color-from-semantic("background/danger-hovered") !default;

/// figma equivalent background/category-1-subtle
$color-background-category-1-subtle: color-from-semantic("background/category-1-subtle") !default;

/// figma equivalent background/category-1
$color-background-category-1: color-from-semantic("background/category-1") !default;

/// figma equivalent background/category-2-subtle
$color-background-category-2-subtle: color-from-semantic("background/category-2-subtle") !default;

/// figma equivalent background/category-2
$color-background-category-2: color-from-semantic("background/category-2") !default;

/// figma equivalent background/category-3-subtle
$color-background-category-3-subtle: color-from-semantic("background/category-3-subtle") !default;

/// figma equivalent background/category-3
$color-background-category-3: color-from-semantic("background/category-3") !default;

/// figma equivalent background/category-4-subtle
$color-background-category-4-subtle: color-from-semantic("background/category-4-subtle") !default;

/// figma equivalent background/category-4
$color-background-category-4: color-from-semantic("background/category-4") !default;

/// figma equivalent background/category-5-subtle
$color-background-category-5-subtle: color-from-semantic("background/category-5-subtle") !default;

/// figma equivalent background/category-5
$color-background-category-5: color-from-semantic("background/category-5") !default;

/// figma equivalent background/category-6-subtle
$color-background-category-6-subtle: color-from-semantic("background/category-6-subtle") !default;

/// figma equivalent background/category-6
$color-background-category-6: color-from-semantic("background/category-6") !default;

/// figma equivalent background/category-7-subtle
$color-background-category-7-subtle: color-from-semantic("background/category-7-subtle") !default;

/// figma equivalent background/category-7
$color-background-category-7: color-from-semantic("background/category-7") !default;

/// figma equivalent background/category-8-subtle
$color-background-category-8-subtle: color-from-semantic("background/category-8-subtle") !default;

/// figma equivalent background/category-8
$color-background-category-8: color-from-semantic("background/category-8") !default;

/// figma equivalent background/category-9-subtle
$color-background-category-9-subtle: color-from-semantic("background/category-9-subtle") !default;

/// figma equivalent background/category-9
$color-background-category-9: color-from-semantic("background/category-9") !default;

/// figma equivalent background/category-10-subtle
$color-background-category-10-subtle: color-from-semantic("background/category-10-subtle") !default;

/// figma equivalent background/category-10
$color-background-category-10: color-from-semantic("background/category-10") !default;

/// figma equivalent background/overlay
$color-background-overlay: color-from-semantic("background/overlay") !default;

/// figma equivalent background/overlay-pressed
$color-background-overlay-pressed: color-from-semantic("background/overlay-pressed") !default;

/// figma equivalent background/overlay-hovered
$color-background-overlay-hovered: color-from-semantic("background/overlay-hovered") !default;

/// figma equivalent background/on-color
$color-background-on-color: color-from-semantic("background/on-color") !default;

/// figma equivalent background/on-color-pressed
$color-background-on-color-pressed: color-from-semantic("background/on-color-pressed") !default;

/// figma equivalent background/on-color-hovered
$color-background-on-color-hovered: color-from-semantic("background/on-color-hovered") !default;

/// figma equivalent background/inverted
$color-background-inverted: color-from-semantic("background/inverted") !default;

/// figma equivalent background/selected
$color-background-selected: color-from-semantic("background/selected") !default;

/// figma equivalent background/disabled
$color-background-disabled: color-from-semantic("background/disabled") !default;

/// figma equivalent background/disabled-on-color
$color-background-disabled-on-color: color-from-semantic("background/disabled-on-color") !default;
