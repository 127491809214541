.overview-list-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 250px;
  min-width: 170px;
  box-shadow: $box-shadow-sm;
  border-radius: calc($border-radius / 8);
  background-color: $color-neutral-white;

  @include media-breakpoint-between(sm, lg) {
    height: 300px;
    min-width: 225px;
  }

  @include media-breakpoint-up(lg) {
    height: 350px;
    min-width: 225px;
  }

  &__header {
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-between(sm, md) {
      height: 225px;
    }

    @include media-breakpoint-between(md, lg) {
      height: 175px;
    }

    @include media-breakpoint-up(lg) {
      height: 225px;
    }
  }

  &__header-image {
    max-height: 100%;
  }

  &__body {
    padding: $sizer;

    @include media-breakpoint-up(lg) {
      padding: $sizer-lg;
    }
  }

  &__body-title {
    color: $color-neutral-darker;
    font-weight: $font-weight-semi-bold;
    font-size: $font-size-base;
    margin-block-end: $spacer-sm;
    line-height: calc(1rem * 1.5); // 24px

    @include media-breakpoint-up(lg) {
      font-size: $font-size-highlight;
      line-height: calc(1rem * 1.6875); // 27px
    }
  }

  &__body-subtitle {
    color: $color-neutral-dark;
    font-size: calc($font-size-base * 0.625);
    line-height: calc(1rem * 1.125); // 18px

    @include media-breakpoint-up(lg) {
      font-size: $font-size-subtitle;
      line-height: calc(1rem * 1.5); // 24px
    }
  }
}
