.batch-beneficiary-selector {
  .bb-account-selector {
    .ng-select-container {
      box-shadow: none;
      border: $input-border;
      border-radius: $border-radius-sm !important;
      min-height: $sizer-md * 7;
      &:not(.ng-has-value) {
        min-height: 2.5rem;
      }
    }

    .ng-dropdown-panel-items {
      max-height: 14rem !important;
    }
  }
  &.ng-invalid {
    .ng-select-container {
      border: $input-invalid-border-width $input-invalid-border-style $input-invalid-border-color;
    }
  }
  &.batch-beneficiary-external-selector {
    .ng-has-value {
      .ng-arrow-wrapper {
        display: none;
      }
    }
  }
}
