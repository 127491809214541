// Global annotations
////
/// @group journey-specific
/// @access public
////

// ------ Loan offer action ------

.bb-ac-loan-offer-main-info-label {
  color: $color-foreground-support;
}

.bb-ac-loan-offer-main-info-element:not(:nth-last-of-type(-n + 2)) {
  margin-bottom: $spacer-lg;
}

.bb-ac-loan-offer-main-info-picture {
  height: 200px;
  border-radius: $border-radius-sm;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.bb-ac-loan-offer-divider {
  background: $color-border-subtle;
  height: 1px;
  margin: $spacer-lg 0;
}
