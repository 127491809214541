// Global annotations
////
/// @group journey-specific
/// @access private
////

.policies-first-table-column {
  width: 280px;
}

.custom-approval-policies--description {
  white-space: pre-line;
  word-break: break-all;
}
