// Global annotations
////
/// @group widgets
/// @access private
////

/// Styles for Privilege Indicator List
/// This component shows visually whether a function is permitted or not.
///
/// @name bb-privilege-indicator-list
///
/// @example html
/// <span class="col bb-privilege-indicator-list-item bb-text-align-center">

.bb-privilege-indicator-list-item {
  padding-right: 0;
  padding-left: 0;
}
