// Global annotations
////
/// @group ui-components
/// @access private
////

.bb-message-ui {
  border-color: $color-border-default;
  border-block-start: $border-width $border-style $border-color;
}
