// Global annotations
////
/// @group structure
/// @access private
////

$page-layout-background-color: $background-color-neutral !default;
$page-layout-padding-horizontal: $sizer-sm !default;
$page-layout-topbar-background-color: $color-background-surface-1 !default;

/// @name page-layout-section-padding
$page-layout-section-padding: $sizer-xl !default;

/// @name page-layout-section-padding-narrow
$page-layout-section-padding-narrow: $sizer-lg !default;

.bb-page-layout {
  min-height: 100vh;
  background-color: $page-layout-background-color;

  .bb-sidebar {
    padding-inline: $page-layout-padding-horizontal;
  }
}

.bb-page-layout__container {
  width: 100%;
}

.bb-page-layout__main {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  min-height: 100vh;
}

.bb-page-layout__content {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  min-width: 0;
}

.bb-page-layout__topbar {
  position: relative;
  width: 100%;
  max-width: 100vw;
  height: $page-layout-topbar-height;
  padding-inline: $page-layout-padding-horizontal;
  background-color: $page-layout-topbar-background-color;

  .bb-page-layout__skip-to-content {
    height: 100%;
  }
}

.bb-page-layout__content-anchor {
  position: absolute;
  left: -1000em; // TODO: can we display: none this instead?
}

.bb-page-layout__skip-to-content {
  &:focus {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    padding: $sizer-sm;
  }
  &:not(:focus) {
    @include sr-only;
  }
}

.bb-page-layout__nav-backdrop {
  position: fixed;
  inset: 0;
  display: none;
  width: 100vw;
  height: 100vh;
  background-color: $color-background-inverted;
  opacity: 0.5;
}

.bb-page-layout--sticky-sidebar {
  .bb-sidebar {
    position: fixed;
    inset-block: 0;
    inset-inline-start: 0;
    z-index: $zindex-sticky;
    overflow-y: auto;
  }
}

.bb-page-layout--sticky-topbar {
  padding-block-start: $page-layout-topbar-height;

  .bb-page-layout__topbar {
    position: fixed;
    inset-block-start: 0;
    inset-inline-start: 0;
    z-index: $zindex-sticky;
  }
}
