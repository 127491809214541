////
/// Contextual UI Variant Color Palettes (global)
/// These variables are meant for internal (Bootstrap) only use
/// @access private
/// @group bootstrap-base
////

/*
* By default, the theme uses a global cascade based on the global color-base.scss variables file to generate a uniform, consistent visual variants across alerts, badges, buttons, and other bootstrap visuals.
*
* Usage of this file is reserved for interfacing with native bootstrap 4 mixins and functions
* for example: [alert|button|badge|text-emphasis|background [ -outline ]]-variant()
*
* These mixins generate the respective color classes (for example: btn-primary)
*
* Recommendation: Only modify if you want to customize partial visual variants
* Example: have deviating primary colors for alert and badge
*/

// Color Contrasts (BS4 Overrides)
// Customize the light and dark text colors for color contrast function.
// @access public
$yiq-text-dark: $color-foreground-default !default;

// A11y Contrast Text Colors (yiq-colors;)
$light: $color-foreground-on-color !default;
$dark: $color-on-background-on-color !default;

// Color overrides for visual variants
// This will replace or add key-value pairs to the variant generators
$theme-partial-overrides: () !default;

// Replacement for BS4 native $theme-colors map for regenerating UI variant classes

// Impact: alert, badge, buttons, list-group, root(css-var), tables, background, borders, text
// Default base colors
$theme-default-colors: (
  "primary": $color-foreground-brand,
  "secondary": $color-foreground-brand,
  "success": $color-foreground-success,
  "info": $color-foreground-info,
  "warning": $color-foreground-warning,
  "danger": $color-foreground-danger,
  "accent": $color-foreground-brand,
  "light": $color-foreground-support,
  "dark": $color-foreground-default,
) !default;

$theme-default-on-colors: (
  "primary": $color-on-background-brand-subtle,
  "secondary": $color-on-background-brand-subtle,
  "success": $color-on-background-success-subtle,
  "info": $color-on-background-info-subtle,
  "warning": $color-on-background-warning-subtle,
  "danger": $color-on-background-danger-subtle,
  "accent": $color-on-background-brand-subtle,
  "light": $color-on-background-neutral-subtle,
  "dark": $color-on-background-neutral-subtle,
);

// Fallback bootstrap reset
$theme-colors: (
  "primary": $color-background-brand,
  "secondary": $color-background-brand,
  "success": $color-background-success,
  "info": $color-background-info,
  "warning": $color-background-warning,
  "danger": $color-background-danger,
  "accent": $color-background-brand,
  "light": $color-background-neutral,
  "dark": $color-background-inverted,
);

// Default base bright colors
// For events/states :hover, :disabled, :active, :focus
$theme-bright-default-colors: (
  "primary": $color-background-brand-subtle,
  "secondary": $color-background-brand-subtle,
  "success": $color-background-success-subtle,
  "info": $color-background-info-subtle,
  "warning": $color-background-warning-subtle,
  "danger": $color-background-danger-subtle,
  "accent": $color-background-brand-subtle,
  "light": $color-background-surface-2,
  "dark": $color-background-inverted,
) !default;

// Alert
//
// Used for: button-variant mixin, with arguments
/*
  * @argument background <--
  * @argument border
  * @argument color
  */
$theme-alert-colors: map-merge((), $theme-colors) !default;

// Badge
//
// Used for: badge-variant mixin, with arguments
/*
  * @argument background <--
  */
$theme-badge-colors: map-merge(
  (
    "light": $color-background-surface-2,
    "dark": $color-background-inverted,
  ),
  $theme-colors
) !default;

// Background
//
// Used for: background-variant mixin, with arguments
/*
  * @argument background <--
  */
$theme-background-colors: map-merge((), $theme-colors) !default;

// text-emphasis
//
// Used for: text-emphasis-variant mixin, with arguments
/*
  * @argument parent-selector
  * @argument color <--
  */
$theme-text-emphasis-colors: map-merge((), $theme-default-colors) !default;

// table-row
//
// Used for: table-row-variant mixin, with arguments
/*
  * @argument parent-selector
  * @argument color <--
  */
$theme-table-row-colors: map-merge((), $theme-bright-default-colors) !default;
$theme-button-colors: (
  primary: (
    color: $color-button-primary-default-foreground,
    hover-color: $color-button-primary-hovered-foreground,
    active-color: $color-button-primary-pressed-foreground,
    default-bg: $color-button-primary-default-background,
    default-border: $color-button-primary-default-border,
    hover-bg: $color-button-primary-hovered-background,
    hover-border: $color-button-primary-hovered-border,
    active-bg: $color-button-primary-pressed-background,
    active-border: $color-button-primary-pressed-border,
    focus-bg: $color-button-primary-default-background,
    focus-border: $color-button-primary-default-border,
    disabled-bg: $color-button-disabled-default-background,
    disabled-border: $color-button-disabled-default-border,
    disabled-color: $color-button-disabled-default-foreground,
  ),
  secondary: (
    color: $color-button-secondary-default-foreground,
    hover-color: $color-button-secondary-hovered-foreground,
    active-color: $color-button-secondary-pressed-foreground,
    default-bg: $color-button-secondary-default-background,
    default-border: $color-button-secondary-default-border,
    hover-bg: $color-button-secondary-hovered-background,
    hover-border: $color-button-secondary-hovered-border,
    active-bg: $color-button-secondary-pressed-background,
    active-border: $color-button-secondary-pressed-border,
    focus-bg: $color-button-secondary-default-background,
    focus-border: $color-button-secondary-default-border,
    disabled-bg: $color-button-disabled-default-background,
    disabled-border: $color-button-disabled-default-border,
    disabled-color: $color-button-disabled-default-foreground,
  ),
  success: (
    color: $color-button-success-default-foreground,
    hover-color: $color-button-success-hovered-foreground,
    active-color: $color-button-success-pressed-foreground,
    default-bg: $color-button-success-default-background,
    default-border: $color-button-success-default-border,
    hover-bg: $color-button-success-hovered-background,
    hover-border: $color-button-success-hovered-border,
    active-bg: $color-button-success-pressed-background,
    active-border: $color-button-success-pressed-border,
    focus-bg: $color-button-success-default-background,
    focus-border: $color-button-success-default-border,
    disabled-bg: $color-button-disabled-default-background,
    disabled-border: $color-button-disabled-default-border,
    disabled-color: $color-button-disabled-default-foreground,
  ),
  info: (
    color: $color-on-background-info,
    hover-color: $color-on-background-info,
    active-color: $color-on-background-info,
    default-bg: $color-background-info,
    default-border: $color-background-info,
    hover-bg: $color-background-info-hovered,
    hover-border: $color-background-info-hovered,
    active-bg: $color-background-info-pressed,
    active-border: $color-background-info-pressed,
    focus-bg: $color-background-info,
    focus-border: $color-background-info,
    disabled-bg: $color-background-disabled,
    disabled-border: $color-background-disabled,
    disabled-color: $color-foreground-disabled,
  ),
  warning: (
    color: $color-on-background-warning,
    hover-color: $color-on-background-warning,
    active-color: $color-on-background-warning,
    default-bg: $color-background-warning,
    default-border: $color-background-warning,
    hover-bg: $color-background-warning-hovered,
    hover-border: $color-background-warning-hovered,
    active-bg: $color-background-warning-pressed,
    active-border: $color-background-warning-pressed,
    focus-bg: $color-background-warning,
    focus-border: $color-background-warning,
    disabled-bg: $color-background-disabled,
    disabled-border: $color-background-disabled,
    disabled-color: $color-foreground-disabled,
  ),
  danger: (
    color: $color-button-danger-default-foreground,
    hover-color: $color-button-danger-hovered-foreground,
    active-color: $color-button-danger-pressed-foreground,
    default-bg: $color-button-danger-default-background,
    default-border: $color-button-danger-default-border,
    hover-bg: $color-button-danger-hovered-background,
    hover-border: $color-button-danger-hovered-border,
    active-bg: $color-button-danger-pressed-background,
    active-border: $color-button-danger-pressed-border,
    focus-bg: $color-button-danger-default-background,
    focus-border: $color-button-danger-default-border,
    disabled-bg: $color-button-disabled-default-background,
    disabled-border: $color-button-disabled-default-border,
    disabled-color: $color-button-disabled-default-foreground,
  ),
  tertiary: (
    color: $color-button-tertiary-default-foreground,
    hover-color: $color-button-tertiary-hovered-foreground,
    active-color: $color-button-tertiary-pressed-foreground,
    default-bg: $color-button-tertiary-default-background,
    default-border: $color-button-tertiary-default-border,
    hover-bg: $color-button-tertiary-hovered-background,
    hover-border: $color-button-tertiary-hovered-border,
    active-bg: $color-button-tertiary-pressed-background,
    active-border: $color-button-tertiary-pressed-border,
    focus-bg: $color-button-tertiary-default-background,
    focus-border: $color-button-tertiary-default-border,
    disabled-bg: $color-button-disabled-default-background,
    disabled-border: $color-button-disabled-default-border,
    disabled-color: $color-button-disabled-default-foreground,
  ),
  link: (
    color: $color-button-tertiary-default-foreground,
    hover-color: $color-button-tertiary-hovered-foreground,
    active-color: $color-button-tertiary-pressed-foreground,
    default-bg: $color-button-tertiary-default-background,
    default-border: $color-button-tertiary-default-border,
    hover-bg: $color-button-tertiary-hovered-background,
    hover-border: $color-button-tertiary-hovered-border,
    active-bg: $color-button-tertiary-pressed-background,
    active-border: $color-button-tertiary-pressed-border,
    focus-bg: $color-button-tertiary-default-background,
    focus-border: $color-button-tertiary-default-border,
    disabled-bg: $color-button-disabled-default-background,
    disabled-border: $color-button-disabled-default-border,
    disabled-color: $color-button-disabled-default-foreground,
  ),
  link-text: (
    color: $color-link-default,
    hover-color: $color-link-default,
    active-color: $color-link-default,
    default-bg: $color-background-none,
    default-border: $color-background-none,
    hover-bg: $color-background-none,
    hover-border: $color-background-none,
    active-bg: $color-background-none,
    active-border: $color-background-none,
    focus-bg: $color-background-none,
    focus-border: $color-background-none,
    disabled-bg: $color-background-none,
    disabled-border: $color-background-none,
    disabled-color: $color-foreground-disabled,
  ),
  light: (
    color: $color-foreground-default,
    hover-color: $color-foreground-default,
    active-color: $color-foreground-default,
    default-bg: $color-background-overlay,
    default-border: $color-background-none,
    hover-bg: $color-background-overlay-hovered,
    hover-border: $color-background-none,
    active-bg: $color-background-overlay-pressed,
    active-border: $color-background-none,
    focus-bg: $color-background-overlay,
    focus-border: $color-background-none,
    disabled-bg: $color-background-disabled,
    disabled-border: $color-background-disabled,
    disabled-color: $color-foreground-disabled,
  ),
  dark: (
    color: $color-button-dark-default-foreground,
    hover-color: $color-button-dark-hovered-foreground,
    active-color: $color-button-dark-pressed-foreground,
    default-bg: $color-button-dark-default-background,
    default-border: $color-button-dark-default-border,
    hover-bg: $color-button-dark-hovered-background,
    hover-border: $color-button-dark-hovered-border,
    active-bg: $color-button-dark-pressed-background,
    active-border: $color-button-dark-pressed-border,
    focus-bg: $color-button-dark-default-background,
    focus-border: $color-button-dark-default-border,
    disabled-bg: $color-button-disabled-default-background,
    disabled-border: $color-button-disabled-default-border,
    disabled-color: $color-button-disabled-default-foreground,
  ),
  link-dark: (
    color: $color-button-dark-default-foreground,
    hover-color: $color-button-dark-hovered-foreground,
    active-color: $color-button-dark-pressed-foreground,
    default-bg: $color-button-dark-default-background,
    default-border: $color-button-dark-default-border,
    hover-bg: $color-button-dark-hovered-background,
    hover-border: $color-button-dark-hovered-border,
    active-bg: $color-button-dark-pressed-background,
    active-border: $color-button-dark-pressed-border,
    focus-bg: $color-button-dark-default-background,
    focus-border: $color-button-dark-default-border,
    disabled-bg: $color-button-disabled-default-background,
    disabled-border: $color-button-disabled-default-border,
    disabled-color: $color-button-disabled-default-foreground,
  ),
  neutral: (
    color: $color-button-neutral-default-foreground,
    hover-color: $color-button-neutral-hovered-foreground,
    active-color: $color-button-neutral-pressed-foreground,
    default-bg: $color-button-neutral-default-background,
    default-border: $color-button-neutral-default-border,
    hover-bg: $color-button-neutral-hovered-background,
    hover-border: $color-button-neutral-hovered-border,
    active-bg: $color-button-neutral-pressed-background,
    active-border: $color-button-neutral-pressed-border,
    focus-bg: $color-button-neutral-default-background,
    focus-border: $color-button-neutral-default-border,
    disabled-bg: $color-button-disabled-default-background,
    disabled-border: $color-button-disabled-default-border,
    disabled-color: $color-button-disabled-default-foreground,
  ),
) !default;

$theme-button-on-color-colors: (
  primary: (
    color: $color-button-on-color-primary-default-foreground,
    hover-color: $color-button-on-color-primary-hovered-foreground,
    active-color: $color-button-on-color-primary-pressed-foreground,
    default-bg: $color-button-on-color-primary-default-background,
    default-border: $color-button-on-color-primary-default-border,
    hover-bg: $color-button-on-color-primary-hovered-background,
    hover-border: $color-button-on-color-primary-hovered-border,
    active-bg: $color-button-on-color-primary-pressed-background,
    active-border: $color-button-on-color-primary-pressed-border,
    focus-bg: $color-button-on-color-primary-default-background,
    focus-border: $color-button-on-color-primary-default-border,
    disabled-bg: $color-button-disabled-on-color-default-background,
    disabled-border: $color-button-disabled-on-color-default-border,
    disabled-color: $color-button-disabled-on-color-default-foreground,
  ),
  secondary: (
    color: $color-button-on-color-secondary-default-foreground,
    hover-color: $color-button-on-color-secondary-hovered-foreground,
    active-color: $color-button-on-color-secondary-pressed-foreground,
    default-bg: $color-button-on-color-secondary-default-background,
    default-border: $color-button-on-color-secondary-default-border,
    hover-bg: $color-button-on-color-secondary-hovered-background,
    hover-border: $color-button-on-color-secondary-hovered-border,
    active-bg: $color-button-on-color-secondary-pressed-background,
    active-border: $color-button-on-color-secondary-pressed-border,
    focus-bg: $color-button-on-color-secondary-default-background,
    focus-border: $color-button-on-color-secondary-default-border,
    disabled-bg: $color-button-disabled-on-color-default-background,
    disabled-border: $color-button-disabled-on-color-default-border,
    disabled-color: $color-button-disabled-on-color-default-foreground,
  ),
  tertiary: (
    color: $color-button-on-color-tertiary-default-foreground,
    hover-color: $color-button-on-color-tertiary-hovered-foreground,
    active-color: $color-button-on-color-tertiary-pressed-foreground,
    default-bg: $color-button-on-color-tertiary-default-background,
    default-border: $color-button-on-color-tertiary-default-border,
    hover-bg: $color-button-on-color-tertiary-hovered-background,
    hover-border: $color-button-on-color-tertiary-hovered-border,
    active-bg: $color-button-on-color-tertiary-pressed-background,
    active-border: $color-button-on-color-tertiary-pressed-border,
    focus-bg: $color-button-on-color-tertiary-default-background,
    focus-border: $color-button-on-color-tertiary-default-border,
    disabled-bg: $color-button-disabled-on-color-default-background,
    disabled-border: $color-button-disabled-on-color-default-border,
    disabled-color: $color-button-disabled-on-color-default-foreground,
  ),
  link-text: (
    color: $color-foreground-on-color,
    hover-color: $color-foreground-on-color,
    active-color: $color-foreground-on-color,
    default-bg: $color-background-none,
    default-border: $color-background-none,
    hover-bg: $color-background-none,
    hover-border: $color-background-none,
    active-bg: $color-background-none,
    active-border: $color-background-none,
    focus-bg: $color-background-none,
    focus-border: $color-background-none,
    disabled-bg: $color-background-none,
    disabled-border: $color-background-none,
    disabled-color: $color-foreground-disabled,
  ),
) !default;

// NOTE: Chart colors moved to variables/_charts.scss
