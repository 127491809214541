// Global annotations
////
/// @group journeys
/// @access private
///
///

$entitlements-approval-item-scope-max-width: $sizer * 5 !default;

.approval-item-value {
  @include text-overflow();

  &--truncated {
    @include media-breakpoint-up(lg) {
      max-width: $sizer * 12;
    }

    @include media-breakpoint-down(lg) {
      max-width: $sizer * 6;
    }
  }
}

.approval-item-scope {
  span {
    @include text-overflow();
    display: block;
    max-width: $entitlements-approval-item-scope-max-width;
  }
}
