// Global annotations
////
/// @group widgets
/// @access private
////

.bb-portfolio-summary-card__summary {
  display: grid;
  grid-template-areas:
    "title"
    "balance"
    "details";
  column-gap: $spacer;
  row-gap: $spacer-sm;
  align-items: flex-start;

  @include media-breakpoint-up(md) {
    grid-template-areas:
      "title balance"
      "details balance";
  }
}

.bb-portfolio-summary-card__summary--icon {
  grid-template-areas:
    "icon title"
    "balance balance"
    "details details";
  grid-template-columns: 2.0833rem auto;

  @include media-breakpoint-up(md) {
    grid-template-areas:
      "icon title balance"
      "icon details balance";
    grid-template-columns: 3.3333rem auto auto;
  }
}

.bb-portfolio-summary-card__summary__icon {
  grid-area: icon;
}

.bb-portfolio-summary-card__summary__title {
  grid-area: title;
  align-self: center;
}

.bb-portfolio-summary-card__summary__balance {
  grid-area: balance;
  justify-self: start;

  @include media-breakpoint-up(md) {
    justify-self: end;
  }
}

.bb-portfolio-summary-card__summary__details {
  grid-area: details;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
