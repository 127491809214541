// Global annotations
////
/// @group ui-components
/// @access private
////

$progress-bar-stripe-color: rgba(0, 0, 0, 0.4) !default;
$progress-bar-border-radius: $border-radius-sm !default;

.bb-progress-bar {
  .progress-bar {
    border-radius: $progress-bar-border-radius;
  }
  .progress-bar-striped {
    background-image: linear-gradient(
      45deg,
      $progress-bar-stripe-color 25%,
      transparent 25%,
      transparent 50%,
      $progress-bar-stripe-color 50%,
      $progress-bar-stripe-color 75%,
      transparent 75%,
      transparent
    );
  }
}
