// Global annotations
////
/// @group widgets
/// @access private
////

.bb-news-card__thumbnail {
  grid-area: thumbnail;
  height: 120px;
  aspect-ratio: 16/9;
}

.bb-news-card__title {
  grid-area: title;
}

.bb-news-card__excerpt {
  grid-area: excerpt;
}

.bb-news-card__date {
  grid-area: date;
}

.bb-news-card {
  width: 300px;
  height: 100%;
}

.bb-news-card.bb-news-card--horizontal {
  width: 100%;
  border-bottom: 1px solid $color-border-subtle;

  &:hover {
    background-color: $color-background-surface-1-hovered;
  }
}

.bb-news-card.bb-news-card--horizontal .bb-card__body {
  display: grid;
  grid-template-columns: 4rem auto 1fr;
  grid-template-areas:
    "thumbnail title date"
    "excerpt excerpt excerpt";
  column-gap: $spacer-md;
  row-gap: $spacer-sm;
  width: 100%;

  @include media-breakpoint-up(md) {
    grid-template-columns: 7rem auto 8rem;
    grid-template-areas:
      "thumbnail title date"
      "thumbnail excerpt date";
  }
}

.bb-news-card.bb-news-card--horizontal .bb-news-card__thumbnail {
  height: 3rem;
  border-radius: $border-radius-xs;

  @include media-breakpoint-up(md) {
    height: 5rem;
    border-radius: $border-radius-sm;
  }
}
