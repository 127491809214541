// Global annotations
////
/// @group journey-specific
/// @access private
////

$bb-dropdown-sa-switcher-size: 30rem !default;
$bb-sa-switcher-width: 25rem;
$bb-sa-switcher-title-container-width: 22rem;
$bb-sa-switcher-body-height: 18vw;

.bb-select-context-h {
  height: $sizer;
}

.bb-dropdown-width {
  width: $bb-dropdown-sa-switcher-size;
}

.bb-dropdown-menu-max-height {
  max-height: $bb-dropdown-sa-switcher-size;
}

.bb-dropdown-sa-switcher__sticky-footer {
  position: sticky;
  background-color: $color-background-surface-1;
  border-top: $border;
  padding: $sizer;
  bottom: 0;
}

.bb-dropdown-sa-switcher-container > .bb-dropdown-sa-switcher-menu {
  padding-bottom: 0;
}

.bb-sa-switcher {
  &--active {
    border-radius: $sizer-sm;
    border: none;
    margin-block-end: $spacer-lg;
    background-color: $color-background-inverted;
    color: $color-on-background-brand;
  }

  &--title-container {
    width: $bb-sa-switcher-title-container-width;
    overflow: hidden;
  }

  &--title {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &--other-box {
    background-color: $color-background-neutral-subtle;
    color: $color-on-background-on-color;
    border-radius: $border-radius-xs;
    font-size: $font-size-subheader;
  }

  &--item-disabled {
    color: $color-foreground-disabled;
  }
}

.bb-sa-switcher--body {
  width: $bb-sa-switcher-width;
}

.bb-sa-switcher-list {
  overflow-y: auto;
  height: $bb-sa-switcher-body-height;
}

.bb-sa-switcher-current-sa {
  padding-block-end: $sizer-sm;
}

.bb-sa-switcher-list-item {
  display: grid;
  grid-template-columns: 8% 10% auto 12%;
  gap: $spacer-xs;
}

.bb-sa-switcher-counter {
  justify-self: end;
}

.bb-approvals-comment-section {
  width: 60%;
  margin-top: $spacer-md;
}

.bb-approvals-log-record-comment {
  white-space: pre;
}
