// Global annotations
////
/// @group journeys
/// @access private
////

$permission-matrix-form-checkbox-label-xxl-top: -1.563rem !default;
$permission-matrix-form-checkbox-label-xxl-left: -0.625rem !default;
$permission-matrix-form-checkbox-label-padding: 0.25rem !default;

$bb-permission-matrix-col-margin-xxl-top: 2rem !default;

$function-name-column-container-padding: 2rem !default;
$function-name-column-content-padding: 1rem !default;
$function-name-column-checkbox-margin: 1rem !default;

.bb-permission-matrix-form-checkbox__label {
  padding-inline-end: $permission-matrix-form-checkbox-label-padding;

  @include media-breakpoint-down(xxl) {
    position: absolute;
    white-space: nowrap;
    text-align: center;
    top: $permission-matrix-form-checkbox-label-xxl-top;
    inset-inline-start: $permission-matrix-form-checkbox-label-xxl-left;
    inset-inline-end: 0;
  }
}

.bb-permission-matrix-col-margin {
  @include media-breakpoint-down(xxl) {
    margin-top: $bb-permission-matrix-col-margin-xxl-top;
  }
}

.bb-permission-matrix-form-business-function-name-column__container {
  padding-inline-end: $function-name-column-container-padding;
}

.bb-permission-matrix-form-business-function-name-column__content {
  padding-inline-start: $function-name-column-content-padding;
}

.bb-permission-matrix-form-business-function-name-column__checkbox {
  margin-inline-start: $function-name-column-checkbox-margin;
}
