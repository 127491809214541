// Global annotations
////
/// @group widgets
/// @access private
////

.bb-portfolio-trading__dropdown-error {
  border-radius: $border-radius-sm;
  border: 1px solid #ccc;
  padding: 0.5rem 1rem;
  height: 40px;
}
