// Global annotations
////
/// @group widgets
/// @access private
////
$bb-portfolio-summary-transactions-item-details-px: $spacer * 2.5 !default;
$bb-portfolio-summary-transactions-item-details-py: $spacer !default;
$bb-portfolio-summary-transactions-head-desc-width: 300px !default;
$bb-transactions-filter-search-width: 225px !default;
$bb-transactions-filter-search-active-width: 190px !default;

.bb-portfolio-summary-transactions__table {
  & > tbody > tr > td {
    padding: $spacer;

    &.bb-portfolio-summary-transactions-item__details {
      padding: $bb-portfolio-summary-transactions-item-details-py
        $bb-portfolio-summary-transactions-item-details-px;
    }
  }
}

.bb-portfolio-summary-transactions-item {
  cursor: pointer;
}

.bb-portfolio-summary-transactions-item__head-description {
  max-width: $bb-portfolio-summary-transactions-head-desc-width;
}

.bb-transactions-filter__search {
  @media (max-width: map-get($grid-breakpoints, "lg")) {
    max-width: $bb-transactions-filter-search-width;

    &.bb-transactions-filter__search-active {
      max-width: $bb-transactions-filter-search-active-width;
    }
  }
}

.bb-transaction-details-table {
  .bb-transaction-details-table__item {
    flex: 0 0 31%;
    margin-inline-end: 2%;
  }
}
