// Global annotations
////
/// @group journey-specific
/// @access public
////

bb-lc-applications-mobile-list,
bb-lc-applications-table {
  .lc-beneficiary-and-applicant-table-header {
    white-space: pre-line;
  }

  .lc-applicant-table-entry {
    color: $color-foreground-support;
    font-size: $font-size-table-header;
  }
}

bb-lc-applications-mobile-list {
  font-size: $table-font-size;

  .lc-list-header {
    font-size: $font-size-table-header;
    font-weight: $font-weight-semi-bold;
  }

  .lc-xsm-application-list-grid {
    display: grid;
    gap: $spacer-md $spacer-sm;
    grid-template-columns: auto 1fr;
    grid-template-rows: repeat(6, auto);

    [dir="rtl"] & {
      direction: rtl;
    }
  }

  .lc-xsm-app-list-item {
    align-self: center;

    &:first-child {
      grid-column: 1 / -1;
    }

    &:last-child {
      grid-column: 1 / -1;
    }
  }

  .lc-sm-application-list-grid {
    display: grid;
    grid-gap: $spacer-md;
    grid-template-columns: auto auto 1fr;
    grid-template-rows: repeat(4, auto);

    [dir="rtl"] & {
      direction: rtl;
    }
  }

  .lc-sm-app-list-item {
    align-self: center;

    &:nth-child(5) {
      grid-column: 2 / 4;
    }
    &:nth-child(7) {
      grid-column: 2 / 4;
    }
  }

  .lc-md-application-list-grid {
    display: grid;
    grid-gap: $spacer-md;
    grid-template-columns: auto 30% auto 30%;
    grid-template-rows: repeat(3, auto);

    [dir="rtl"] & {
      direction: rtl;
    }
  }

  .lc-md-app-list-item {
    align-self: center;
  }

  .lc-list-hover {
    .bb-list__item:hover {
      background-color: $color-background-surface-1-hovered;
    }
  }
}

bb-letter-of-credit-application-dashboard-container {
  .bb-subheader--regular {
    color: $color-foreground-support;
  }
}

bb-letter-of-credit-applications-search-panel {
  .dropdown-menu {
    max-height: none;
  }
}
