////
/// Buttons (global)
/// @group 1-variables/components/button
/// @access public
////

/// Styling property: min-height
/// figma equivalent height/button/sm
$height-button-sm: 2rem !default;

/// Styling property: min-height
/// figma equivalent height/button/md
$height-button-md: 2.75rem !default;

/// Styling property: padding-left, padding-right
/// figma equivalent padding-x/button/sm
$padding-x-button-sm: 1rem !default;

/// Styling property: padding-left, padding-right
/// figma equivalent padding-x/button/md
$padding-x-button-md: 1.5rem !default;

/// Styling property: border-radius
/// figma equivalent radius/button/default
$radius-button-default: 6rem !default;

/// Styling property: border-width
/// figma equivalent border-width/button/default
$border-width-button-default: 0.0625rem !default;

/// Styling property: background-color
/// figma equivalent color/button/primary/default/background
$color-button-primary-default-background: $color-background-brand !default;

/// Styling property: color
/// figma equivalent color/button/primary/default/foreground
$color-button-primary-default-foreground: $color-on-background-brand !default;

/// Styling property: border-color
/// figma equivalent color/button/primary/default/border
$color-button-primary-default-border: $color-border-none !default;

/// Styling property: background-color
/// figma equivalent color/button/primary/hovered/background
$color-button-primary-hovered-background: $color-background-brand-hovered !default;

/// Styling property: color
/// figma equivalent color/button/primary/hovered/foreground
$color-button-primary-hovered-foreground: $color-on-background-brand !default;

/// Styling property: border-color
/// figma equivalent color/button/primary/hovered/border
$color-button-primary-hovered-border: $color-border-none !default;

/// Styling property: background-color
/// figma equivalent color/button/primary/pressed/background
$color-button-primary-pressed-background: $color-background-brand-pressed !default;

/// Styling property: color
/// figma equivalent color/button/primary/pressed/foreground
$color-button-primary-pressed-foreground: $color-on-background-brand !default;

/// Styling property: border-color
/// figma equivalent color/button/primary/pressed/border
$color-button-primary-pressed-border: $color-border-none !default;

/// Styling property: background-color
/// figma equivalent color/button/secondary/default/background
$color-button-secondary-default-background: $color-background-none !default;

/// Styling property: color
/// figma equivalent color/button/secondary/default/foreground
$color-button-secondary-default-foreground: $color-foreground-brand !default;

/// Styling property: border-color
/// figma equivalent color/button/secondary/default/border
$color-button-secondary-default-border: $color-border-brand !default;

/// Styling property: background-color
/// figma equivalent color/button/secondary/hovered/background
$color-button-secondary-hovered-background: $color-background-brand-subtle !default;

/// Styling property: color
/// figma equivalent color/button/secondary/hovered/foreground
$color-button-secondary-hovered-foreground: $color-on-background-brand-subtle !default;

/// Styling property: border-color
/// figma equivalent color/button/secondary/hovered/border
$color-button-secondary-hovered-border: $color-on-background-brand-subtle !default;

/// Styling property: background-color
/// figma equivalent color/button/secondary/pressed/background
$color-button-secondary-pressed-background: $color-background-brand-subtle !default;

/// Styling property: color
/// figma equivalent color/button/secondary/pressed/foreground
$color-button-secondary-pressed-foreground: $color-on-background-brand-subtle !default;

/// Styling property: border-color
/// figma equivalent color/button/secondary/pressed/border
$color-button-secondary-pressed-border: $color-on-background-brand-subtle !default;

/// Styling property: background-color
/// figma equivalent color/button/tertiary/default/background
$color-button-tertiary-default-background: $color-background-none !default;

/// Styling property: color
/// figma equivalent color/button/tertiary/default/foreground
$color-button-tertiary-default-foreground: $color-foreground-brand !default;

/// Styling property: border-color
/// figma equivalent color/button/tertiary/default/border
$color-button-tertiary-default-border: $color-border-none !default;

/// Styling property: background-color
/// figma equivalent color/button/tertiary/hovered/background
$color-button-tertiary-hovered-background: $color-background-brand-subtle !default;

/// Styling property: color
/// figma equivalent color/button/tertiary/hovered/foreground
$color-button-tertiary-hovered-foreground: $color-on-background-brand-subtle !default;

/// Styling property: border-color
/// figma equivalent color/button/tertiary/hovered/border
$color-button-tertiary-hovered-border: $color-border-none !default;

/// Styling property: background-color
/// figma equivalent color/button/tertiary/pressed/background
$color-button-tertiary-pressed-background: $color-background-brand-subtle !default;

/// Styling property: color
/// figma equivalent color/button/tertiary/pressed/foreground
$color-button-tertiary-pressed-foreground: $color-on-background-brand-subtle !default;

/// Styling property: border-color
/// figma equivalent color/button/tertiary/pressed/border
$color-button-tertiary-pressed-border: $color-border-none !default;

/// Styling property: background-color
/// figma equivalent color/button/neutral/default/background
$color-button-neutral-default-background: $color-background-surface-1 !default;

/// Styling property: color
/// figma equivalent color/button/neutral/default/foreground
$color-button-neutral-default-foreground: $color-foreground-default !default;

/// Styling property: border-color
/// figma equivalent color/button/neutral/default/border
$color-button-neutral-default-border: $color-border-subtle !default;

/// Styling property: background-color
/// figma equivalent color/button/neutral/hovered/background
$color-button-neutral-hovered-background: $color-background-surface-1-hovered !default;

/// Styling property: color
/// figma equivalent color/button/neutral/hovered/foreground
$color-button-neutral-hovered-foreground: $color-foreground-default !default;

/// Styling property: border-color
/// figma equivalent color/button/neutral/hovered/border
$color-button-neutral-hovered-border: $color-border-subtle !default;

/// Styling property: background-color
/// figma equivalent color/button/neutral/pressed/background
$color-button-neutral-pressed-background: $color-background-surface-1-pressed !default;

/// Styling property: color
/// figma equivalent color/button/neutral/pressed/foreground
$color-button-neutral-pressed-foreground: $color-foreground-default !default;

/// Styling property: border-color
/// figma equivalent color/button/neutral/pressed/border
$color-button-neutral-pressed-border: $color-border-subtle !default;

/// Styling property: background-color
/// figma equivalent color/button/dark/default/background
$color-button-dark-default-background: $color-background-none !default;

/// Styling property: color
/// figma equivalent color/button/dark/default/foreground
$color-button-dark-default-foreground: $color-foreground-default !default;

/// Styling property: border-color
/// figma equivalent color/button/dark/default/border
$color-button-dark-default-border: $color-border-none !default;

/// Styling property: background-color
/// figma equivalent color/button/dark/hovered/background
$color-button-dark-hovered-background: $color-background-surface-1-hovered !default;

/// Styling property: color
/// figma equivalent color/button/dark/hovered/foreground
$color-button-dark-hovered-foreground: $color-foreground-default !default;

/// Styling property: border-color
/// figma equivalent color/button/dark/hovered/border
$color-button-dark-hovered-border: $color-border-none !default;

/// Styling property: background-color
/// figma equivalent color/button/dark/pressed/background
$color-button-dark-pressed-background: $color-background-surface-1-pressed !default;

/// Styling property: color
/// figma equivalent color/button/dark/pressed/foreground
$color-button-dark-pressed-foreground: $color-foreground-default !default;

/// Styling property: border-color
/// figma equivalent color/button/dark/pressed/border
$color-button-dark-pressed-border: $color-border-none !default;

/// Styling property: background-color
/// figma equivalent color/button/success/default/background
$color-button-success-default-background: $color-background-success !default;

/// Styling property: color
/// figma equivalent color/button/success/default/foreground
$color-button-success-default-foreground: $color-on-background-success !default;

/// Styling property: border-color
/// figma equivalent color/button/success/default/border
$color-button-success-default-border: $color-border-none !default;

/// Styling property: background-color
/// figma equivalent color/button/success/hovered/background
$color-button-success-hovered-background: $color-background-success-hovered !default;

/// Styling property: color
/// figma equivalent color/button/success/hovered/foreground
$color-button-success-hovered-foreground: $color-on-background-success !default;

/// Styling property: border-color
/// figma equivalent color/button/success/hovered/border
$color-button-success-hovered-border: $color-border-none !default;

/// Styling property: background-color
/// figma equivalent color/button/success/pressed/background
$color-button-success-pressed-background: $color-background-success-pressed !default;

/// Styling property: color
/// figma equivalent color/button/success/pressed/foreground
$color-button-success-pressed-foreground: $color-on-background-success !default;

/// Styling property: border-color
/// figma equivalent color/button/success/pressed/border
$color-button-success-pressed-border: $color-border-none !default;

/// Styling property: background-color
/// figma equivalent color/button/danger/default/background
$color-button-danger-default-background: $color-background-danger !default;

/// Styling property: color
/// figma equivalent color/button/danger/default/foreground
$color-button-danger-default-foreground: $color-on-background-danger !default;

/// Styling property: border-color
/// figma equivalent color/button/danger/default/border
$color-button-danger-default-border: $color-border-none !default;

/// Styling property: background-color
/// figma equivalent color/button/danger/hovered/background
$color-button-danger-hovered-background: $color-background-danger-hovered !default;

/// Styling property: color
/// figma equivalent color/button/danger/hovered/foreground
$color-button-danger-hovered-foreground: $color-on-background-danger !default;

/// Styling property: border-color
/// figma equivalent color/button/danger/hovered/border
$color-button-danger-hovered-border: $color-border-none !default;

/// Styling property: background-color
/// figma equivalent color/button/danger/pressed/background
$color-button-danger-pressed-background: $color-background-danger-pressed !default;

/// Styling property: color
/// figma equivalent color/button/danger/pressed/foreground
$color-button-danger-pressed-foreground: $color-on-background-danger !default;

/// Styling property: border-color
/// figma equivalent color/button/danger/pressed/border
$color-button-danger-pressed-border: $color-border-none !default;

/// Styling property: background-color
/// figma equivalent color/button/on-color-primary/default/background
$color-button-on-color-primary-default-background: $color-background-on-color !default;

/// Styling property: color
/// figma equivalent color/button/on-color-primary/default/foreground
$color-button-on-color-primary-default-foreground: $color-on-background-on-color !default;

/// Styling property: border-color
/// figma equivalent color/button/on-color-primary/default/border
$color-button-on-color-primary-default-border: $color-border-none !default;

/// Styling property: background-color
/// figma equivalent color/button/on-color-primary/hovered/background
$color-button-on-color-primary-hovered-background: $color-background-on-color-hovered !default;

/// Styling property: color
/// figma equivalent color/button/on-color-primary/hovered/foreground
$color-button-on-color-primary-hovered-foreground: $color-on-background-on-color !default;

/// Styling property: border-color
/// figma equivalent color/button/on-color-primary/hovered/border
$color-button-on-color-primary-hovered-border: $color-border-none !default;

/// Styling property: background-color
/// figma equivalent color/button/on-color-primary/pressed/background
$color-button-on-color-primary-pressed-background: $color-background-on-color-pressed !default;

/// Styling property: color
/// figma equivalent color/button/on-color-primary/pressed/foreground
$color-button-on-color-primary-pressed-foreground: $color-on-background-on-color !default;

/// Styling property: border-color
/// figma equivalent color/button/on-color-primary/pressed/border
$color-button-on-color-primary-pressed-border: $color-border-none !default;

/// Styling property: background-color
/// figma equivalent color/button/on-color-secondary/default/background
$color-button-on-color-secondary-default-background: $color-background-none !default;

/// Styling property: color
/// figma equivalent color/button/on-color-secondary/default/foreground
$color-button-on-color-secondary-default-foreground: $color-foreground-on-color !default;

/// Styling property: border-color
/// figma equivalent color/button/on-color-secondary/default/border
$color-button-on-color-secondary-default-border: $color-border-on-color !default;

/// Styling property: background-color
/// figma equivalent color/button/on-color-secondary/hovered/background
$color-button-on-color-secondary-hovered-background: $color-background-on-color-hovered !default;

/// Styling property: color
/// figma equivalent color/button/on-color-secondary/hovered/foreground
$color-button-on-color-secondary-hovered-foreground: $color-on-background-on-color !default;

/// Styling property: border-color
/// figma equivalent color/button/on-color-secondary/hovered/border
$color-button-on-color-secondary-hovered-border: $color-border-on-color !default;

/// Styling property: background-color
/// figma equivalent color/button/on-color-secondary/pressed/background
$color-button-on-color-secondary-pressed-background: $color-background-on-color-pressed !default;

/// Styling property: color
/// figma equivalent color/button/on-color-secondary/pressed/foreground
$color-button-on-color-secondary-pressed-foreground: $color-on-background-on-color !default;

/// Styling property: border-color
/// figma equivalent color/button/on-color-secondary/pressed/border
$color-button-on-color-secondary-pressed-border: $color-border-on-color !default;

/// Styling property: background-color
/// figma equivalent color/button/on-color-tertiary/default/background
$color-button-on-color-tertiary-default-background: $color-background-none !default;

/// Styling property: color
/// figma equivalent color/button/on-color-tertiary/default/foreground
$color-button-on-color-tertiary-default-foreground: $color-foreground-on-color !default;

/// Styling property: border-color
/// figma equivalent color/button/on-color-tertiary/default/border
$color-button-on-color-tertiary-default-border: $color-border-none !default;

/// Styling property: background-color
/// figma equivalent color/button/on-color-tertiary/hovered/background
$color-button-on-color-tertiary-hovered-background: $color-background-on-color-hovered !default;

/// Styling property: color
/// figma equivalent color/button/on-color-tertiary/hovered/foreground
$color-button-on-color-tertiary-hovered-foreground: $color-on-background-on-color !default;

/// Styling property: border-color
/// figma equivalent color/button/on-color-tertiary/hovered/border
$color-button-on-color-tertiary-hovered-border: $color-border-none !default;

/// Styling property: background-color
/// figma equivalent color/button/on-color-tertiary/pressed/background
$color-button-on-color-tertiary-pressed-background: $color-background-on-color-pressed !default;

/// Styling property: color
/// figma equivalent color/button/on-color-tertiary/pressed/foreground
$color-button-on-color-tertiary-pressed-foreground: $color-on-background-on-color !default;

/// Styling property: border-color
/// figma equivalent color/button/on-color-tertiary/pressed/border
$color-button-on-color-tertiary-pressed-border: $color-border-none !default;

/// Styling property: background-color
/// figma equivalent color/button/disabled/default/background
$color-button-disabled-default-background: $color-background-disabled !default;

/// Styling property: color
/// figma equivalent color/button/disabled/default/foreground
$color-button-disabled-default-foreground: $color-foreground-disabled !default;

/// Styling property: border-color
/// figma equivalent color/button/disabled/default/border
$color-button-disabled-default-border: $color-border-none !default;

/// Styling property: background-color
/// figma equivalent color/button/disabled-on-color/default/background
$color-button-disabled-on-color-default-background: $color-background-disabled-on-color !default;

/// Styling property: color
/// figma equivalent color/button/disabled-on-color/default/foreground
$color-button-disabled-on-color-default-foreground: $color-foreground-disabled-on-color !default;

/// Styling property: border-color
/// figma equivalent color/button/disabled-on-color/default/border
$color-button-disabled-on-color-default-border: $color-border-none !default;
