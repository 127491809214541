// Global annotations
////
/// @group widgets
/// @access private
////

.bb-instrument-list-item {
  padding: 5px;
  border-radius: $border-radius-sm;
  &:hover {
    text-decoration: none;
    background-color: $portfolio-trading-background-color-neutral;
  }
}

.bb-instrument-list-item__performance-percentage {
  min-width: fit-content;
}
