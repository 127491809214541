// Variables provided by Bootstrap
$dropdown-min-width: 200px !default;
$dropdown-max-height: 80vh !default;
$dropdown-padding-y: $sizer-md !default;
$dropdown-spacer: $spacer-xs !default;
$dropdown-font-size: $font-size-base !default;
$dropdown-color: $color-foreground-default !default;
$dropdown-bg: $color-background-surface-1 !default;
$dropdown-border-color: $border-color !default;
$dropdown-border-radius: $border-radius-md !default;
$dropdown-border-width: 0 !default;
$dropdown-inner-border-radius: calc(
  #{$dropdown-border-radius} - #{$dropdown-border-width}
) !default;
$dropdown-divider-bg: $color-border-subtle !default;
$dropdown-divider-margin-y: $spacer-sm !default;
$dropdown-box-shadow: $box-shadow-md !default;
$dropdown-link-color: $color-foreground-default !default;
$dropdown-link-hover-color: $color-foreground-default !default;
$dropdown-link-hover-bg: $color-background-surface-1-hovered !default;
$dropdown-link-active-color: $color-foreground-default !default;
$dropdown-link-active-bg: $color-background-selected !default;
$dropdown-link-disabled-color: $color-foreground-disabled !default;
$dropdown-item-padding-y: $sizer-sm !default;
$dropdown-item-padding-x: $sizer-md !default;
$dropdown-header-color: $color-foreground-support !default;
