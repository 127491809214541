// Global annotations
////
/// @group structure
/// @access private
////

$badge-colors: (
  "primary": (
    "background": $color-background-brand-subtle,
    "color": $color-on-background-brand-subtle,
  ),
  "secondary": (
    "background": $color-background-neutral-subtle,
    "color": $color-on-background-neutral-subtle,
  ),
  "success": (
    "background": $color-background-success-subtle,
    "color": $color-on-background-success-subtle,
  ),
  // @deprecated in ui-ang@12. To be removed in ui-ang@14. No replacement.
  "partial-success":
    (
      "background": $color-background-surface-1,
      "color": $color-foreground-success,
    ),
  "info": (
    "background": $color-background-info-subtle,
    "color": $color-on-background-info-subtle,
  ),
  "warning": (
    "background": $color-background-warning-subtle,
    "color": $color-on-background-warning-subtle,
  ),
  "danger": (
    "background": $color-background-danger-subtle,
    "color": $color-on-background-danger-subtle,
  ),
  // @deprecated in ui-ang@12. To be removed in ui-ang@14. No replacement.
  "neutral-light":
    (
      "background": $color-background-surface-2,
      "color": $color-foreground-disabled,
    ),
) !default;

@import "bootstrap/scss/badge";

// Overrides not possible through bootstrap variables
@each $color, $value in $badge-colors {
  .badge-#{$color} {
    background-color: map-get($value, "background");
    color: map-get($value, "color");
  }
}

// Overrides not possible through bootstrap variables
.badge {
  line-height: $badge-line-height;

  abbr {
    text-decoration: none;
  }
}
