// Global annotations
////
/// @group journey-specific
/// @access private
////

$bb-select-contact-type-option-label-offset-margin: -3rem !default;
$bb-select-contact-type-option-label-left-padding: $sizer-xxl !default;
$bb-select-contact-type-option-border-radius: $border-radius !default;
$bb-select-contact-type-option-active-bg-color: $color-background-selected !default;
$bb-select-contact-type-option-bg-color: $color-background-page !default;

/// Contacts select list
///
/// @example html
/// <div class="bb-select-contact-type-option">
///   <button class="bb-select-contact-type-option__label">Option 1</button>
///   <button class="bb-select-contact-type-option__label">Option 2</button>
///   <button class="bb-select-contact-type-option__label">Option 3</button>
/// </div>

.bb-select-contact-type-option {
  padding: 0 $sizer-md;
}

.bb-select-contact-type-option .bb-input-radio-group__label,
.bb-select-contact-type-option .bb-label {
  margin-left: -#{$spacer-md};
}

.bb-select-contact-type-option .bb-select-contact-type-option__label {
  margin-left: $bb-select-contact-type-option-label-offset-margin;
  padding: $sizer-md;
  padding-left: $bb-select-contact-type-option-label-left-padding;
  background-color: $bb-select-contact-type-option-bg-color;
  border-radius: $bb-select-contact-type-option-border-radius;

  &:not(.btn-unstyled):focus::before {
    border-radius: calc($bb-select-contact-type-option-border-radius + 6px);
  }

  &:focus:not(:hover):not(:active):not(:disabled):not(.disabled) {
    background-color: $bb-select-contact-type-option-active-bg-color;
  }
}

.bb-input-radio-group__radio.selected .bb-select-contact-type-option__label {
  background-color: $bb-select-contact-type-option-active-bg-color;
}

.bb-select-contact-type-option__icon {
  margin: 0 $spacer-sm;
}
