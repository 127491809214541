////
/// @group 5-components/structure/bb-display
/// @access public
////

/* prettier-ignore */
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @each $value in $displays {
/// Sets the display type based on breakpoint (Bootstrap variable `$grid-breakpoints`) and
/// `display` type (Bootstrap variable `$displays`).
/// @name .bb-display
/// @example html
///   <div class="bb-d-flex">
///     Display flex
///   </div>
///   <div class="bb-d-none">
///     Display none
///   </div>
///   <div class="bb-d-md-none">
///     Display none on medium screens and larger
///   </div>
///   <div class="bb-d-none bb-d-md-block">
///     Display block on medium screens and larger and display none on smaller screens
///   </div>
      .bb-d#{$infix}-#{$value} {
        display: $value;
      }
    }
  }
}

// Utilities for toggling `display` in print
@media print {
  @each $value in $displays {
    /// Change the `display:` value using the provided options in `$displays` (Bootstrap variable).
    ///
    /// Available options are:
    /// - none
    /// - inline
    /// - inline-block
    /// - block
    /// - table
    /// - table-row
    /// - table-cell
    /// - flex
    /// - inline-flex
    ///
    /// This statement only applies to "print" styles.
    /// @name .bb-d-print
    /// @example html
    ///   <div class="bb-d-print-flex">
    ///     Display flex
    ///   </div>
    ///   <div class="bb-d-print-none">
    ///     Display none
    ///   </div>
    ///   <div class="bb-d-print-block">
    ///     Display block
    ///   </div>
    .bb-d-print-#{$value} {
      display: $value;
    }
  }
}
