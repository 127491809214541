////
/// @group 5-components/structure/bb-state-container
/// @access public
////

/// @require {variable} sizer-xxl
$state-container-padding-y: $sizer-xxl !default;

/// @require {variable} sizer
$state-container-min-height: 30 * $sizer !default;

/// Positions a state component (like empty or loading state).
///
/// @require {variable} state-container-padding-y
/// @example html - Default
///   <div class="bb-state-container">
///     Loading...
///   </div>
///
/// @example html - With footer
///   <div class="bb-state-container">
///     Empty state
///     <div class="bb-state-container__footer">
///       Optional actions
///     </div>
///   </div>
///
/// @example html - Minimum height container
///   <div class="card"> // any flex column-direction container (IE11 only)
///     <div class="bb-state-container bb-state-container--min-height">
///       Empty state / Loading state
///     </div>
///   </div>
.bb-state-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-block: $state-container-padding-y;
  padding-inline: 0;
}

// Use this modification as a child of flex column direction container (IE11 only)
.bb-state-container--min-height {
  justify-content: center;
  min-height: $state-container-min-height;

  @include media-breakpoint-down(lg) {
    min-height: auto;
  }
}

/// Applies some spacing to the element to act as a footer in the container
///
/// @require {variable} spacer-lg
/// @example html
///   <div class="bb-state-container">
///     Empty state
///     <div class="bb-state-container__footer">
///       Optional action buttons
///     </div>
///   </div>
.bb-state-container__footer {
  margin-block-start: $spacer-lg;
}
