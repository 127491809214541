// Global annotations
////
/// @group journey-specific
/// @access private
////

bb-ds-agreement-review-journey-ang {
  .dot {
    background-color: $color-background-neutral;
    width: $sizer-sm;
    height: $sizer-sm;
    border-radius: $sizer-md;
    margin-top: $spacer-sm * 0.75;
    margin-left: $spacer-sm;
    margin-right: $spacer-sm;
  }
}

.bb-agreement-review-modal-dialog {
  .agreement-content {
    max-height: calc(100vh - 26rem);

    &::-webkit-scrollbar {
      width: 0.4rem;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba($color-background-inverted, $modal-backdrop-opacity);
      border-radius: $spacer-xs;
    }
  }

  @include media-breakpoint-only(sm) {
    max-width: calc(100vw - 4rem);

    .bb-button-bar__button {
      &:first-child {
        margin-inline-end: 0;
      }

      .btn {
        width: 100%;
      }
    }
  }

  @include media-breakpoint-only(xs) {
    margin-top: $spacer-xl;
  }
}
