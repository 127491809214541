// Global annotations
////
/// @group widgets
/// @access private
////

.bb-orders-list__collapsible-body {
  padding: 0 42px 0 72px;
}
.bb-orders-list__collapsible-body-item:nth-of-type(4n) {
  text-align: right;
  margin-inline-start: auto;
  margin-inline-end: 0;
}
.bb-orders-list__collapsible-body-item {
  flex: 0 0 23%;
  margin-inline-end: 2%;
}
