// Global annotations
////
/// @group chip
/// @access private
////
$chip-background-color: $color-background-brand-subtle;
$chip-color-not-active: transparent;
$chip-text-color: $color-on-background-brand-subtle;
$chip-font-size: $font-size-default;
$chip-font-weight: $font-weight-regular;
$chip-border-width: 1px;
$chip-border-color: $color-on-background-brand-subtle;
$chip-border: $chip-border-width solid $chip-border-color;
$chip-tiny-sizer: 0.1rem;

.bb-chip-item {
  position: relative;

  &:focus-visible {
    outline: none;
  }

  &:focus-visible::before {
    position: absolute;
    border: $focus-border-primary;
    border-radius: $border-radius-pill;
    content: "";
  }
}

.bb-chip-item__label {
  color: $chip-text-color;
  font-size: $chip-font-size;
  font-weight: $chip-font-weight;
}

.bb-chip-item__content {
  color: $chip-text-color;
  font-size: $chip-font-size;
  font-weight: $chip-font-weight;
  background-color: $chip-background-color;
  border: $chip-border;
  padding: $chip-tiny-sizer $sizer-sm $chip-tiny-sizer ($sizer-sm + $sizer-xs);
  box-sizing: border-box;
  cursor: default;

  &:active:not(.bb-chip-item__content--non-interactive) {
    background-color: $chip-background-color;
  }

  &:hover {
    background-color: $chip-background-color;
    border: $chip-border;
  }
}

.bb-chip-item__content--non-interactive {
  border-right: $chip-border;
  background-color: $chip-color-not-active;
  padding: $chip-tiny-sizer ($sizer-sm + $sizer-xs);

  &:hover {
    background-color: $chip-color-not-active;
    border-right: $chip-border;
  }

  &:active {
    background-color: $chip-color-not-active;
  }
}

.bb-chip-item__button {
  background-color: $chip-background-color;
  border-left: none;
  border-color: $chip-border-color;
  padding-inline: $sizer-xs;

  [dir="rtl"] & {
    border-left: $chip-border;
    border-right: none;
  }

  &.btn:hover {
    // N.B: !important used here, otherwise clashes with --
    // .btn-primary:not(:disabled):not(.disabled):hover
    // background-color
    background: $chip-background-color !important;
    border-color: $chip-border-color !important;

    & .bb-chip-item__close-icon .bb-icon {
      // N.B: !important used here, otherwise clashes with --
      // .btn-primary:not(:disabled):not(.disabled):hover .bicon,
      // .btn-primary:not(:disabled):not(.disabled):hover
      // .bb-transaction-category, .btn-primary:not(:disabled)
      // :not(.disabled):hover .bb-icon, .btn-primary:not(:disabled)
      // :not(.disabled):hover .table thead th.sortable .th-content [aria-hidden=true]::before,
      // .table thead th.sortable .th-content .btn-primary:not(:disabled):not(.disabled):hover [aria-hidden=true]::before
      // background-color
      color: $chip-text-color !important;
    }

    & .bb-chip-item__close-icon {
      background: $color-background-overlay-hovered;
      border-radius: 100%;
      padding: 1.6px 0.6px;
    }
  }

  &.btn:active {
    // N.B: Using !important here as background clashes with --
    // .btn-primary:not(:disabled):not(.disabled):active
    background: $chip-background-color !important;

    & .bb-chip-item__close-icon .bb-icon {
      color: $chip-text-color;
    }

    & .bb-chip-item__close-icon {
      background-color: $color-background-overlay-pressed;
      border-radius: 100%;
      padding: 1.6px 0.6px;
    }
  }

  &.btn:focus {
    // N.B: !important used here, otherwise clashes with -
    // .btn-primary:focus background-color
    background: $chip-background-color !important;
  }
}

.bb-chip-item__close-icon {
  color: $chip-text-color;
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}
