////
/// Colors (global)
/// @group 1-variables/semantic/color/focus
/// @access public
////

/* stylelint-disable color-no-hex */

/// figma equivalent focus/outline
$color-focus-outline: color-from-semantic("focus/outline") !default;

/// figma equivalent focus/on-color/outline
$color-focus-on-color-outline: color-from-semantic("focus/on-color/outline") !default;
