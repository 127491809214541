// Global annotations
////
/// @group widgets
/// @access private
////

$limit-job-role-margin-right: -1 * $spacer !default;
$limit-job-role-margin-top: -0.75 * $spacer !default;
$limit-pending-icon-padding: 0.25 * $sizer !default;
$limit-pending-view-padding: 1.2 * $sizer !default;

.bb-job-role-limit-icon {
  margin-top: $limit-job-role-margin-top;
  margin-right: $limit-job-role-margin-right;
}

.bb-pending-limit-icon {
  padding-left: $limit-pending-icon-padding;
}

.bb-pending-limit-view {
  padding-right: $limit-pending-view-padding;
}

.bb-limit-header-card {
  background-color: $color-background-neutral-subtle;
  padding: $sizer-sm;
  border-radius: $border-radius-sm;
}
