// Global annotations
////
/// @group widgets
/// @access private
////

.bb-wealth-shared__amount-input {
  width: 60%;
  border: none;
  outline: none;
  color: $color-foreground-support;
  transition: font-size 0.3s;
  font-size: $font-size-highlight;
}

.bb-wealth-shared__amount-input-currency-label {
  width: 40%;
  font-size: $font-size-highlight;
  color: $color-foreground-support;
}
.bb-wealth-shared__amount-input-currency-label--touched {
  color: $color-foreground-default;
}
.bb-wealth-shared__amount-input--touched {
  color: $color-foreground-default;
}

.bb-wealth-shared__amount-input--active {
  color: $color-foreground-default;
  font-size: $font-size-base * 2;
}
