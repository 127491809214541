.bb-letter-block {
  border-radius: $border-radius * 0.25;
  border: $border-width $border-style $color-background-neutral-subtle;

  &__waiting {
    color: $color-on-background-neutral-subtle;
    background-color: $color-background-neutral-subtle;
    border-color: $color-border-subtle;
  }

  &__approve {
    color: $color-foreground-success;
    background-color: $color-background-surface-1;
    border-color: $color-border-success;
  }

  &__reject {
    color: $color-on-background-danger;
    background-color: $color-background-danger;
    border-color: $color-border-danger;
  }

  &__passed {
    color: $color-on-background-success;
    background-color: $color-background-success;
    border-color: $color-border-success;
  }

  &__local {
    color: $color-on-background-brand-subtle;
    background-color: $color-background-brand-subtle;
    border-color: $color-background-brand-subtle;
  }

  &__global {
    color: $color-on-background-category-5-subtle;
    background-color: $color-background-category-5-subtle;
    border-color: $color-background-category-5-subtle;
  }
}

.bb-block-text {
  color: $color-foreground-support;
}

//TODO remove after ANDR-1808 is done, for the moment it needs to stay no to break ApprovalPolicyLetterBlockComponent
.bb-letter-block__active {
  @extend .bb-letter-block__approve;
}

.bb-letter-block__default {
  @extend .bb-letter-block__waiting;
}
