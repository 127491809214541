// Global annotations
////
/// Material Icons
/// @group generic
/// @access private
////

@mixin material-icons() {
  display: inline-block;
  font-weight: normal;
  font-size: $font-icon-size; /* Preferred icon size */
  @if $enable-outlined-icons {
    font-family: "Material Icons Outlined", sans-serif;
  } @else {
    font-family: "Material Icons", sans-serif;
  }
  font-style: normal;
  line-height: ($line-height-base * 0.6);
  letter-spacing: normal;
  white-space: nowrap;
  text-transform: none;
  word-wrap: normal;

  /* Support for IE. */
  font-feature-settings: "liga";

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
}
