// Global annotations
////
/// @group forms
/// @access private
////

$amount-input-height: 3.5rem !default;

@mixin amount-input-symbol {
  position: absolute;
  inset-block-start: 50%;
  transform: translate(0, -50%);
  color: $input-color;
}

.bb-amount-input__symbol {
  @include amount-input-symbol;
  padding-inline-start: $spacer-lg;
  &--swap {
    @include amount-input-symbol;
    padding-inline-end: $sizer-lg;
    right: 0;
  }
}
.bb-amount-input__field {
  position: relative;
  flex: 1 0 auto;

  &--with-currency {
    input {
      padding-inline-start: $input-padding-x * 4;
    }

    input:disabled,
    input.disabled {
      & + .bb-amount-input__symbol {
        color: $color-foreground-support;
      }
    }
    &--swap {
      input {
        padding-inline-end: $input-padding-x * 4;
      }
    }
  }
}
.bb-amount-input__field--large {
  .form-control {
    height: $amount-input-height;
  }
}
.bb-amount-input__text-hint {
  color: $color-foreground-support;
  font-size: $font-size-subtitle;
  padding-top: $sizer-sm;
}
