// Global annotations
////
/// @group widgets
/// @access private
////

$bb-wealth-shared-stepper-layout-header-and-footer-height: 4.3rem !default;
$bb-wealth-shared-stepper-layout-max-width-content: 544px !default;
$bb-wealth-shared-stepper-layout-max-width-content-md: 568px !default;

.bb-wealth-stepper-layout {
  height: calc(100vh - $bb-wealth-shared-stepper-layout-header-and-footer-height);
}

.bb-wealth-stepper-layout .bb-stepper {
  padding: 0;
}

.bb-wealth-stepper-layout .bb-step-heading {
  margin-right: $spacer-md;
  margin-left: $spacer-md;

  @include media-breakpoint-up(sm) {
    margin-right: 0;
    margin-left: 0;
  }
}

.bb-wealth-stepper-layout__sidebar {
  background-size: cover;
  background-position: center;

  @include media-breakpoint-up(xl) {
    width: 25%;
  }
}

.bb-wealth-stepper-layout__content {
  overflow: auto;

  @include media-breakpoint-up(xl) {
    width: 75%;
  }
}

.bb-wealth-stepper-layout__content-wrapper {
  padding-top: $spacer-xl;
}

.bb-wealth-stepper-layout__stepper-wrapper {
  max-width: $bb-wealth-shared-stepper-layout-max-width-content;
  padding: 0 $spacer-md;
  margin: 0 auto;

  @include media-breakpoint-up(sm) {
    padding: 0;
  }

  @include media-breakpoint-up(xl) {
    max-width: $bb-wealth-shared-stepper-layout-max-width-content-md;
    margin: 0 $spacer-xl;
  }
}

.bb-wealth-stepper-layout__inner-content {
  max-width: $bb-wealth-shared-stepper-layout-max-width-content;
  margin: 0 auto;

  @include media-breakpoint-up(xl) {
    max-width: $bb-wealth-shared-stepper-layout-max-width-content-md;
    margin: 0 auto 0 3rem;
  }
}
