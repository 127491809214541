// Global annotations
////
/// @group journeys
/// @access private
////

$cag-chip-border: 1px solid $color-on-background-brand-subtle !default;
$cag-chip-text-color: $color-on-background-brand-subtle !default;
$cag-chip-space: 0.5 * $spacer !default;
$cag-chip-border-radius: $border-radius-xxl !default;
$cag-chip-padding-size: 0.5 * $sizer !default;
$cag-chip-line-height: 1.5 * $spacer !default;
$cag-chip-padding: 0.25 * $sizer 0.5 * $sizer !default;
$cag-chip-max-width: 260px !default;
//Possible types of element
$cag-chip-border-newly-added: 1px solid $color-background-warning !default;
$cag-chip-background-color-newly-added: $color-background-warning-subtle !default;
$cag-chip-border-deleted: 1px solid $color-border-danger !default;
$cag-chip-background-color-deleted: $color-background-danger-subtle !default;
$cag-chip-border-added: 1px solid $color-border-success !default;
$cag-chip-background-color-added: $color-background-success-subtle !default;

.bb-cag-chip__container {
  display: inline-flex;
  align-items: center;
  margin-block-end: $cag-chip-space;
  margin-inline-end: $cag-chip-space;
}

.bb-cag-chip {
  font-size: $font-size-sm;
  font-weight: $font-weight-regular;
  color: $cag-chip-text-color;
  line-height: $cag-chip-line-height;
  position: relative;

  &:focus-visible {
    outline: none;
  }

  &:focus-visible::before {
    position: absolute;
    border: $focus-border-primary;
    border-radius: $border-radius-pill;
    content: "";
  }

  border: $cag-chip-border;
  box-sizing: border-box;
  cursor: default;

  &:hover {
    border: $cag-chip-border;
  }

  display: inline-block;
  align-items: center;
  border-radius: $cag-chip-border-radius;
  margin-inline-end: $cag-chip-space;
  padding-inline-end: $cag-chip-padding-size;
  max-width: 20 * $spacer;
}

.bb-cag-chip__label {
  max-width: $cag-chip-max-width;
  padding: $cag-chip-padding;
  &.newly_added {
    border: $cag-chip-border-newly-added;
    background-color: $cag-chip-background-color-newly-added;
  }
  &.deleted {
    border: $cag-chip-border-deleted;
    background-color: $cag-chip-background-color-deleted;
  }
  &.added {
    border: $cag-chip-border-added;
    background-color: $cag-chip-background-color-added;
  }
  bb-icon-ui {
    cursor: pointer;
  }
}
