// Global annotations
////
/// @group ui-components
/// @access private
////

.bb-cash-management-sweeps-search {
  @include media-breakpoint-up(lg) {
    max-width: 300px;
  }
}
