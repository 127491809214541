// Global annotations
////
/// @group journeys
/// @access private
////

$employees-table-padding-block-start: $sizer-md !default;
$employees-table-padding-block-end: $sizer-md !default;

$employees-cag-table-padding-start: $sizer-md !default;
$employees-cag-table-padding-vertical: $sizer-md !default;
$employees-cag-table-padding-font-size: $subtitle-font-size !default;
$employees-cag-table-name-min-width: $spacer-xl !default;
$employees-cag-table-info-max-width: 80% !default;

.bb-employees-table__heading {
  padding-block-start: $employees-table-padding-block-start;
  padding-block-end: $employees-table-padding-block-end;
}

.bb-employees-table__item {
  padding-inline-start: $employees-cag-table-padding-start;
  padding-block: $employees-cag-table-padding-vertical;
  font-size: $employees-cag-table-padding-font-size;
  line-height: $subtitle-line-height;
  .bb-employees-table__name {
    padding-inline-end: $sizer-sm;
    min-width: $employees-cag-table-name-min-width;
  }
  .bb-employees-table__info {
    max-width: $employees-cag-table-info-max-width;
  }
}
