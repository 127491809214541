// Global annotations
////
/// @group journey-specific
/// @access private
////

$approval-log-remove-item-border: 1px solid $color-border-danger;

.custom-approval-structure {
  row-gap: $spacer-sm;
}

.custom-approval-structure-body {
  position: relative;
  align-items: baseline;
}

.custom-approval-structure-list {
  max-height: $sizer-lg;
  overflow: hidden;
}

.custom-approval-structure-item {
  break-inside: avoid;
}

.structure-tooltip-position {
  position: relative;

  &:hover {
    cursor: pointer;
  }
}

.structure-tooltip-body {
  position: absolute;
  bottom: $sizer-lg;
  width: 20rem;
  right: 0;
  z-index: $zindex-tooltip;
}

.create-approval-level-form {
  display: grid;
  grid-template-columns: 7rem auto 5rem;
}

.create-approval-level-form--rank {
  min-height: 116px;
}

.create-approval-policy-form--name {
  min-height: 100px;
}

.create-approval-policy-form--dropdown {
  min-width: 100px;
}

.create-approval-policy-form--validation-error-item {
  border: $approval-log-remove-item-border;
  box-shadow: none;
}

.custom-approval-name {
  word-break: break-all;
}
