// Global annotations
////
/// @group widgets
/// @access private
$navbar-height: $layout-vertical-topbar-height + 2 * $layout-vertical-lg-topbar-padding !default;
$heading-height: $page-header-padding-block-end !default;
$heading-and-navbar-height: $navbar-height + $heading-height !default;
$table-column-mapping-include-width: 4.5rem !default;
////

@include media-breakpoint-up(lg) {
  .contact-details-container {
    border-inline-start: $border;
  }

  .contact-all-contacts-container {
    max-height: calc(100vh - #{$heading-and-navbar-height});
  }

  .contact-details-container,
  .contact-list-container {
    overflow: auto;
    max-height: inherit;
  }
}

.contacts-upload-container {
  background-color: transparent;
  opacity: 1;
  border: 1px dashed $color-border-subtle;
  border-radius: 10px;
  padding: $sizer-lg $sizer;

  &.drag-over {
    opacity: 0.8;
    background-color: $color-background-neutral-subtle;
  }
}

.contacts-upload-column-mapping__table {
  width: 100%;
  white-space: nowrap;
  table-layout: fixed;
}

.contacts-upload-include-column {
  width: $table-column-mapping-include-width;
}
