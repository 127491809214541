// Global annotations
////
/// @group journeys
/// @access private
////

$portfolio-summary-dashboard-account-margin-top: -$spacer * 2.5 !default;
$portfolio-summary-dashboard-content-padding: $spacer-xl !default;

.portfolio-summary-dashboard__account {
  margin-top: $portfolio-summary-dashboard-account-margin-top;
}
.portfolio-summary-dashboard__asset-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.portfolio-summary-dashboard__content {
  padding-top: $portfolio-summary-dashboard-content-padding;
  padding-bottom: $portfolio-summary-dashboard-content-padding;
}
.portfolio-summary-dashboard__details-divider {
  border-inline-start: $border;
}
.portfolio-summary-dashboard__details-hidden {
  display: none;
}
.bb-portfolio-summary__progress-bar {
  height: $spacer-sm;
  border-radius: $border-radius-xs + 1;
}

// preserves round borders for the button when tooltip is applied
.btn-group.btn-group-toggle.bb-dashboard-btn-group-sorting button:last-of-type {
  transform: scaleY(-1);
  border-start-end-radius: $border-radius-pill;
  border-end-end-radius: $border-radius-pill;
}
