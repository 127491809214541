// Global annotations
////
/// @group journeys
/// @access private
////

$cag-modal-table-checkbox-margin-end: $spacer-lg !default;
$cag-modal-table-border-bottom: 1px solid $color-border-subtle !default;
$cag-modal-le-table-line-height: 1.5 * $spacer !default;
$cag-modal-le-table-font-weight: $font-weight-semi-bold !default;
$cag-modal-le-table-padding-vertical: 0.5 * $sizer !default;

.bb-cag-modal-table-legal-entity__heading {
  line-height: $cag-modal-le-table-line-height;
  font-weight: $cag-modal-le-table-font-weight;
  padding-top: $cag-modal-le-table-padding-vertical;
  padding-bottom: $cag-modal-le-table-padding-vertical;
  border-bottom: $cag-modal-table-border-bottom;
}

.bb-cag-modal-table-legal-entity__item:not(:last-child) {
  border-bottom: $cag-modal-table-border-bottom;
}

.bb-cag-modal-table__checkbox {
  margin-inline-end: $cag-modal-table-checkbox-margin-end;
}
