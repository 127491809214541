.not-found {
  background-color: $color-neutral-darker;
  border-end-end-radius: $border-radius * 11.25;
  padding-inline: $sizer;
  overflow: hidden;

  color: $color-neutral-white;

  @include media-breakpoint-up(md) {
    background-image: url("../../../assets/images/pages/not-found-hero.svg");
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
    padding-inline: unset;
  }

  &__hero {
    padding-block: $sizer * 2.5;
    padding-inline-end: $sizer * 2.5;

    @include media-breakpoint-up(md) {
      padding-block: $sizer * 4.375 $sizer * 6.25;
      padding-inline-end: unset;
    }
  }

  &__hero-title {
    color: $color-neutral-white;
    font-size: $font-size-base * 1.875;
    font-weight: $font-weight-semi-bold;

    display: flex;
    flex-direction: column;
    margin-block-end: $spacer-xl;

    @include media-breakpoint-up(sm) {
      font-size: $font-size-hero;
    }

    &--small {
      color: $color-accent;
      font-size: $font-size-base * 1.875;
      font-weight: $font-weight-semi-bold;
      line-height: 2.375rem;
    }
  }

  &__hero-text {
    line-height: 1.5rem;
    margin-block-end: $spacer-lg;
    max-width: 52ch;
  }

  &__hero-action {
    color: $color-accent;

    &:hover {
      color: $color-accent;

      .bb-icon {
        // override ui-ang specificity
        color: $color-accent;
      }
    }
  }
}
