// Global annotations
////
/// @group widgets
/// @access private
////

.bb-instrument-prices__item {
  flex: 0 0 23%;
  margin-inline-end: 2%;
}
