// Global annotations
////
/// @group journey-specific
/// @access private
////

.bb-message-overview-content {
  p {
    margin-block-end: 0;
  }
}

.bb-conversation-menu {
  padding-inline-start: 0;
  margin-block-end: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
