$amountRatioMarkDiameter: $spacer-md - $spacer-xs;

.bb-ds-loan-calculator-form {
  formly-group {
    formly-field ~ formly-field {
      bb-us-formly-field-wrapper > div {
        margin-top: $spacer-lg;
      }
    }
  }
}

.bb-ds-payment-info {
  bb-progressbar-ui {
    .progress {
      height: $spacer-sm;
    }
  }

  &__amount-ratio-mark {
    width: $amountRatioMarkDiameter;
    height: $amountRatioMarkDiameter;
  }

  @include media-breakpoint-only(xs) {
    .bb-ds-payment-info-item__label,
    .bb-ds-payment-info-item__description,
    .bb-ds-secondary-section {
      font-size: $font-size-subtitle;
    }

    .bb-ds-primary-section {
      font-size: $font-size-h3;
    }
  }
}
