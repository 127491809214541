// Global annotations
////
/// @group journey-specific
/// @access private
////

$custom-policy-name-width: 150px;

.sortable-table-icon-color {
  color: $color-foreground-disabled;
}

.custom-approval-sortable-table-header:hover .sortable-table-icon {
  display: inline-flex;
}

.approvals-table {
  display: grid;
  grid-template-columns: 35% 15% minmax(210px, 15%) min-content auto;
  gap: $spacer;
  align-items: center;
}

.approvals-table--buttons {
  background-color: $color-background-surface-2;
  justify-content: flex-end;
}

.approvals-table--row:hover {
  background-color: $color-background-surface-2-hovered;
}

.approvals-table--business-function-column {
  grid-column-start: span 2;
}

.policy-name {
  max-width: $custom-policy-name-width;
}

.action-buttons-column {
  justify-self: end;
}

.empty-structure {
  grid-column: 3 / span 2;
}

.approval-rule-item {
  display: grid;
  grid-template-columns: repeat(2, minmax(200px, auto)) 3fr;
  gap: $spacer-sm;
}

.ranges-hidden {
  grid-column: 2 / span 2;
}

.mac-icon {
  background-color: $color-background-inverted;
  color: $color-on-background-inverted;
}

.complex-policy-modal-row {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr min-content;
  column-gap: $spacer;
}

.policy-not-select-error {
  grid-column: 3 / span 2;
}

.incorrect-ranges-error {
  grid-column-start: 1;
}
