// Global annotations
////
/// @group journey-specific
/// @access private
////

$bb-general-info-step-grid-template-column: 1fr 1.5fr;
$loan-calculator-info-calculator-grid-template-columns: 1.5fr 1fr;

.application-submission-footer {
  background-color: $color-background-brand;

  &__text {
    color: $color-on-background-brand;
  }
}

.loan-calculator-info-section {
  background-color: $color-background-brand;

  .calculator-status {
    &__section {
      grid-template-columns: $loan-calculator-info-calculator-grid-template-columns;
    }
  }

  &__text {
    color: $color-on-background-brand;
  }
}

bb-general-info-step-item {
  .field-row {
    grid-template-columns: $bb-general-info-step-grid-template-column;
  }
}

bb-general-info-section-step {
  bb-collapsible-card-ui {
    .bb-card__header,
    .bb-card__body {
      padding: 0 !important;
    }
  }
}
