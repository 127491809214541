.bb-credit-card-limit-info {
  .bb-ccli-header__title {
    margin-inline-start: $spacer-sm;
  }

  @include media-breakpoint-only(xs) {
    .bb-limit-details-section {
      font-size: $font-size-subtitle;
    }

    .bb-current-limit-section {
      font-size: $font-size-h3;
    }
  }
}
