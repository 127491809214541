@import "vendor/material-design-icons/iconfont/material-icons";

$link-hover-decoration: none !default;

@import "../../../../../libs/ui-ang/scss/main.scss";

// import variables in css so that it can be used on design token page
@import "../../../../../libs/ui-ang/scss/1-variables/css-variables/semantic/index";

@import "variables";
@import "fonts";
@import "icons";
@import "showcases";
@import "bb-showcase-web";
@import "showcase-ui-ang-examples";
@import "showcase-ds-toolkit";
@import "showcase-storybook";
@import "aside";
@import "dso";
@import "typography";
@import "shortcut";
@import "platform";
@import "component-fixes";
@import "section";
@import "spacing";
@import "web-theme";
@import "pages";
@import "ui";

html,
body {
  font-size: 16px;
  font-family: $font-family;
  background-color: $color-neutral-white;
}

h4 {
  margin-top: 1.2rem;
  margin-bottom: 0.8rem;
}

.mb-10 {
  margin-bottom: $sizer-xxl * 2;
}

.mb-5.container:not(:first-of-type) {
  padding-top: 2rem;
}

img {
  max-width: 100%;
}

a.arrowed-link,
p.coming-soon {
  font-weight: $font-weight-bolder;
  font-size: $font-size-subtitle;
  display: inline-flex;
  align-items: center;

  bb-icon-ui {
    margin-left: $sizer-xs;
  }
}

.card-header {
  @include border-top-radius(1rem);
}

.design-and-code {
  height: 577px;
  background-image: url("../../assets/images/diagram.png");
  background-position: center;
  background-repeat: no-repeat;
  background-color: $color-primary;
}

.example-nav .nav-tabs > .nav-item > .nav-link {
  background-color: transparent;
  color: $color-primary;
  &:hover {
    color: $color-primary-darker;
  }
  &.active:after {
    background-color: $color-primary;
  }
}

// Content Area of <h2>
.sect1,
.row {
  &:first-child {
    margin-top: 0;
  }
  margin: 3em 0;
}
// Content Area of <h3>
.sect2 {
  margin: 2em 0;
}

.imageblock,
.img-fluid {
  img {
    max-width: 100%;
  }
}

/** TYPOGRAPHY **/
.useful-links {
  display: none;
}

//// Overwrites
.bg-primary,
.bg-secondary,
.bg-danger,
.bg-success,
.bg-info {
  code {
    color: $color-neutral-white;
  }
}

.bg-selected,
.bg-warning {
  code {
    color: $color-neutral-black;
  }
}

code {
  color: $color-info;
}

pre code {
  font-size: 0.8rem;
}

.mobile-visuals {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  img {
    display: flex;
    height: fit-content;
    width: fit-content;
  }
}

.right {
  float: right;
}

bb-showcase-scss-tokens .container {
  padding-left: 0;
}
