// Global annotations
////
/// @group journeys
/// @access private
////

$modal-wrapper-header-height: $sizer * 4.3 !default;
$full-screen-modal-logo: $sizer * 7.5 !default;

.bb-modal-wrapper {
  min-height: 100vh;
  padding-block-start: $modal-wrapper-header-height;
}
.bb-full-screen-modal-header {
  z-index: $zindex-dropdown;
  height: $modal-wrapper-header-height;
  position: fixed;
  inset-block-start: 0;
  inset-inline-start: 0;
  display: flex;
}

.bb-full-screen-modal-header-logo {
  z-index: 1;
}

.bb-wealth-full-screen-modal-logo .bb-logo:not(.bb-logo--emblem) {
  width: $full-screen-modal-logo;
}
