// Global annotations
////
/// @group journeys
/// @access private
////

$wealth-table-sortable-margin-right: $spacer !default;

.bb-wealth-table-sortable {
  position: relative;

  [aria-hidden="true"]::before {
    @extend .bb-icon;
    text-align: center;
    position: absolute;
    vertical-align: middle;
  }
}

.asc .bb-wealth-table-sortable-th-content [aria-hidden="true"]::before {
  @extend .bb-icon-caret-up;
}

.asc .bb-wealth-table-sortable-th-content,
.desc .bb-wealth-table-sortable-th-content {
  margin-right: $wealth-table-sortable-margin-right;
}

.desc .bb-wealth-table-sortable-th-content [aria-hidden="true"]::before {
  @extend .bb-icon-caret-down;
}

.bb-wealth-table-sortable-th-button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  padding: 0;
  color: inherit;
  font-weight: $font-weight-regular;
  text-align: start;
  background: transparent;
  border: 0;
  border-radius: 0;
}
