// Global annotations
////
/// @group elements
/// @access private
////

html,
body {
  width: 100%;
  height: 100%;
}
