// Global annotations
////
/// @group typography
/// @access private
////

$h3-font-size: $font-size-h3 !default; // 20px
$h3-line-height: $line-height-heading !default;
$h3-font-weight: $font-weight-bold !default;

/// Will set the following css properties on an HTML element:
/// - font-weight,
/// - font-size,
/// - line-height
///
/// @name bb-heading-3
///
/// @example html
///   <div class="bb-heading-3">Heading-3 text here</div>

.bb-heading-3 {
  font-weight: $h3-font-weight;
  font-size: $h3-font-size;
  line-height: $h3-line-height;
}
