// only be used to provide styles to docs markdown files
// styles are imported into .dso__content-docs

.bordered-image:not(bb-showcase-web *) {
  border: 2px $border-style $color-neutral-grey;
  border-radius: $border-radius * 0.5;
}

.rounded-image:not(bb-showcase-web *) {
  border-radius: $border-radius * 0.5;
}

.sub-section:not(bb-showcase-web *) {
  margin-block-start: $spacer-lg;
}

.alert--blockquote:not(bb-showcase-web *) {
  border-radius: $alert-border-radius;
  background-color: $color-primary-lightest;
  padding: $sizer * 0.75 $sizer * 1.25;
  margin-bottom: 0;
  box-shadow: 0 13px 13px -16px rgba(0, 0, 0, 50%);
  margin-block: $spacer;
  position: relative;
  overflow: hidden;

  font-size: $font-size-base;

  .alert--icon {
    fill: $color-primary;
    padding-inline-end: $sizer-sm;
  }

  &:before {
    content: " ";
    background: $color-primary;
    width: 0.5rem;

    position: absolute;
    bottom: 0;
    left: 0;
    top: 0;
  }
}

.image-caption:not(bb-showcase-web *) {
  margin-block-start: $spacer-sm;
  font-size: $font-size-sm;
  text-align: center;
}

.row {
  // Resetting Quill overrides
  margin: 0 calc(-0.5 * var(--bs-gutter-x));
}

table:not(bb-showcase-web *) th {
  // Resetting Quill overrides
  font-size: $font-size-base !important;
}

p:not(bb-showcase-web *) {
  margin-bottom: $spacer * 1.25;
}

ul:not(bb-showcase-web *),
ol:not(bb-showcase-web *) {
  li {
    margin-block-end: $spacer * 0.45;
  }

  li:last-child {
    margin-block-end: 0;
  }
}

code:not(bb-showcase *) {
  color: $color-neutral-greyest;
  font-size: $font-size-sm;

  background: none;
  background-color: $color-neutral-lighter;
  border-radius: $border-radius * 0.25;
  padding: $sizer-xs $sizer-sm;
}
