// Global annotations
////
/// @group journey-specific
/// @access private
////

.bb-bcre-task {
  background-color: $color-background-surface-1;

  bb-collapsible-card-ui {
    .bb-card__header,
    .bb-card__body {
      padding: 0 !important;
    }
  }

  .experian-logo {
    width: $sizer-xxxl;
    height: $sizer-md;
  }
}
