.dso-navigation {
  padding-block: $sizer-lg;
  padding-inline-start: $sizer-lg;

  @include media-breakpoint-up(xl) {
    width: $sidebar-inline-offset-xl;
    padding: 0;
    position: fixed;
  }

  @include media-breakpoint-up(xxl) {
    width: $sidebar-inline-offset-xxl;
  }

  &__title {
    padding-block: $sizer-sm;

    @include media-breakpoint-up(xl) {
      background-color: #ebf0f5;
      height: 66px;
      padding: $sizer-lg $sizer-xxl;

      line-height: 1.125rem;
    }
  }

  &__title-link {
    @extend .subheader;
    color: $color-neutral-dark;

    margin-block-end: 0;
  }

  &__search {
    padding-block: $sizer-lg;

    @include media-breakpoint-up(xl) {
      display: none;
    }
  }

  &__tree {
    max-height: 65vh;
    overflow-x: hidden;

    scrollbar-color: $color-neutral-grey-light transparent;
    scrollbar-width: thin;

    @include media-breakpoint-up(xl) {
      max-height: calc(80vh - $footer-offset);
      padding-inline-start: calc($sizer * 3.75); // ~60px
    }

    &::-webkit-scrollbar {
      width: $sizer-xs;
      height: $sizer-xs;
    }

    &::-webkit-scrollbar-thumb {
      background: $color-neutral-grey-light;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  }

  &__tree-list {
    @extend .list-unstyled;
  }

  &__tree-item {
    $self: &;

    padding-inline-end: $sizer-lg;

    #{$self} & {
      padding-inline: $sizer 0;
    }

    .bb-icon {
      font-size: $font-size-default;
    }
  }

  &__tree-link {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding-block: $sizer;

    color: $color-neutral-dark;
    font-size: $font-size-highlight;
    font-weight: $font-weight-regular;
    line-height: 1.6875rem;
    text-transform: capitalize;

    @include media-breakpoint-up(xl) {
      padding-block: $sizer-sm;

      font-size: $font-size-subtitle;
      line-height: 1.5rem;
    }

    &--expanded {
      font-weight: $font-weight-semi-bold;
    }

    &--active {
      $self: &;

      color: $color-primary;
      font-weight: $font-weight-semi-bold;

      // selector is not supported by Firefox only
      &:has(+ ul > li > #{$self}) {
        color: unset;
      }
    }
  }
}
