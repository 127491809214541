// Global annotations
////
/// @group ui-components
/// @access private
////

.bb-ellipsis {
  display: block;
}

.bb-ellipsis--responsive-container {
  overflow: hidden;
}

.bb-ellipsis--single-line {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: break-word;
  word-break: break-all; // IE does not support break-word :(
}

.bb-ellipsis--line-clamp {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
}
