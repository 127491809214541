// Variables provided by Bootstrap
$enable-rounded: $border-radius-enabled !default;

$grid-gutter-width: 2 * $spacer !default;

$border-radius-lg: $border-radius-lg !default;

$box-shadow: $box-shadow-md !default;
// $box-shadow-sm: $box-shadow-sm !default;
$box-shadow-lg: $box-shadow-lg !default;

$component-active-bg: rgba($color-background-brand, 0.1) !default;

$link-color: $color-link-default !default;
$link-hover-color: $color-link-hovered !default;
$link-decoration: none !default;
