bb-input-divided-date {
  .ng-invalid {
    bb-input-text-ui {
      input {
        border-color: $color-border-danger;
      }
    }
  }

  .day,
  .month {
    width: 30%;
    input {
      padding-right: $sizer * 0.8;
    }
  }
  .year {
    width: 40%;
  }
}
