// Global annotations
////
/// @group journey-specific
/// @access private
////

.bb-business-walkthrough-modal-dialog {
  @include media-breakpoint-only(sm) {
    max-width: calc(100vw - 1rem);
  }

  @include media-breakpoint-down(xs) {
    margin-top: $spacer-xl;
  }
}
