// Global annotations
////
/// @group widgets
/// @access private
////

.bb-wealth-shared__progress-bar {
  width: 100%;
  height: 6px;
  background: $color-background-page;
  border-radius: 1.25rem;
}

.bb-wealth-shared__progress-bar-fill {
  background: $color-background-info;
  border-radius: 1.25rem;
  width: 6px;
  height: 6px;
  transition: margin 700ms;
}
