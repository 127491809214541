.footer {
  position: absolute;
  z-index: $zindex-sticky;
  width: 100%;
  padding: $sizer-xl $sizer-lg;
  background-color: $color-neutral-lighter; // $color-neutral-lighter

  display: flex;
  justify-content: center;

  &__content-wrapper {
    width: $responsive-xl;

    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;

    @include media-breakpoint-up(xl) {
      align-items: center;
      flex-direction: unset;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: $spacer-sm;

    @include media-breakpoint-up(xl) {
      align-items: center;
      flex-direction: unset;
      gap: 0;
    }
  }

  &__content-logo .bb-logo {
    max-height: 1rem;
    width: calc($sizer * 6.25); // 100px
  }

  &__content-text {
    font-size: $font-size-subtitle;
    line-height: 1.5rem;
  }

  &__policy {
    display: flex;
    gap: $spacer;
  }
}
