// Global annotations
////
/// @group journey-specific
/// @access private
////

$company-share-size: 110px;
$bb-stepper-wrapper-max-width: 330px;
$bb-stepper-wrapper-max-width-smaller-screens: 210px;

.business-relations-modal-dialog {
  margin-top: 3.5rem;

  @include media-breakpoint-only(sm) {
    width: 360px;

    .bb-button-bar__button {
      width: 100%;
      margin-block-end: $spacer-md;
      margin-inline-end: 0;

      &:last-child {
        margin-block-end: 0;
      }

      .btn {
        width: 100%;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .modal-body {
      padding: $sizer-md;
    }

    .modal-header {
      padding: $sizer-md;
    }
  }

  @include media-breakpoint-only(md) {
    .modal-header,
    .modal-body {
      padding-inline: $sizer-lg;
    }
  }

  .company-share-wrapper {
    .form-control {
      width: $company-share-size;
    }
  }

  .bb-stepper-wrapper {
    max-width: $bb-stepper-wrapper-max-width;

    @include media-breakpoint-down(md) {
      max-width: $bb-stepper-wrapper-max-width-smaller-screens;

      .bb-step:not(.bb-step--active),
      &.first-step-checked .bb-step:first-child {
        .bb-step__label {
          display: none;
        }
      }
    }
  }
}
