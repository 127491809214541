.product-card__background {
  height: 160px;
  background-size: cover;
  background-position: center;
}

.product-card__image {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 144px;
  width: 144px;
}
