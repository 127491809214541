$bb-self-serve-appointment-external-provider-iframe-offset: 280px !default;

.bb-self-serve-appointment-external-provider-iframe {
  inline-size: 100%;
  display: block;
  height: 125vh;

  @include media-breakpoint-up(lg) {
    block-size: calc(100vh - $bb-self-serve-appointment-external-provider-iframe-offset);
  }
}
