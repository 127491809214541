// Global annotations
////
/// @group widgets
/// @access private
////

.key-statistics__field {
  flex: 0 0 23%;
  margin-inline-end: 2%;
}
