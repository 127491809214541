// Global annotations
////
/// @group journey-specific
/// @access private
////

$business-profile-font-family: "Material Icons Outlined", sans-serif;
$business-profile-fon-feature-settings: "liga";

bb-business-profile-industry-select {
  .ng-select {
    .ng-select-container {
      align-items: center;
      min-height: calc(1.5em + 1rem);
      border-radius: $border-radius-sm;
      border-color: $color-border-default;
    }

    .ng-clear {
      display: none !important;
    }

    .ng-clear-wrapper {
      width: auto;
      margin-right: $spacer;
      color: $color-foreground-support;
      font-size: $font-size-base;
      font-family: $business-profile-font-family;
      font-feature-settings: $business-profile-fon-feature-settings;

      &:after {
        content: "cancel";
      }
    }

    .ng-arrow {
      display: none !important;
    }

    .ng-arrow-wrapper {
      width: auto;
      color: $color-foreground-default;
      font-size: $font-size-base;
      font-family: $business-profile-font-family;
      font-feature-settings: $business-profile-fon-feature-settings;

      &:after {
        content: "expand_less";
      }
    }
  }

  .ng-select.ng-select-multiple {
    .ng-select-container {
      .ng-value-container {
        .ng-value {
          display: flex;
          align-items: center;
          background-color: $color-background-info-subtle;
          color: $color-on-background-brand-subtle;
          border-radius: $border-radius-xs;
        }

        .ng-value-label {
          white-space: break-spaces;
          font-size: $font-size-subtitle;
          line-height: $line-height-base;
        }

        .ng-placeholder {
          color: $color-foreground-support;
          position: initial;
        }
      }
    }

    .ng-dropdown-panel {
      border-color: $color-border-default;
    }
  }

  .ng-select.ng-select-opened {
    .ng-arrow-wrapper:after {
      content: "expand_more";
    }
  }

  ng-select.ng-select-focused:not(.ng-select-opened) {
    > .ng-select-container {
      outline: 3px solid $color-focus-outline;
      outline-offset: 4px;
      border-color: $color-border-default;
      box-shadow: none;
    }
  }

  ng-select.ng-touched:not(.ng-valid):not(.ng-select-focused) {
    > .ng-select-container:not(.ng-has-value) {
      border-color: $color-border-danger;
    }
  }
}
