// Global annotations
////
/// @group forms
/// @access private
////

.bb-input-password {
  .btn {
    &:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(
        .invalid-tooltip
      ):not(.invalid-feedback) {
      &:focus::before {
        border-radius: inherit;
      }
    }
  }

  .bb-input-password__input {
    &[type="password"]:not(:placeholder-shown) {
      font-family: Verdana, sans-serif;
      letter-spacing: 0.125rem;
      font-size-adjust: 0.4;
    }

    &::-ms-reveal,
    &::-ms-clear {
      display: none;
    }
  }

  // Bootstrap 5 overrides
  // These overrides styles in https://github.com/twbs/bootstrap/blob/v5.3.0-alpha1/scss/forms/_input-group.scss#L92
  // which causes rounded corner mid-input box for RTL
  input:not(:last-child):not(.dropdown-menu):not(.dropdown-item):not(.valid-tooltip):not(
      .valid-feedback
    ):not(.invalid-tooltip):not(.invalid-feedback) {
    @include border-inline-start-radius($input-border-radius);
    @include border-inline-end-radius(0);
  }
}
