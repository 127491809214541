// Global annotations
////
/// @group widgets
/// @access private
////

.bb-news-item {
  color: $color-neutral-black !important;
  padding: $sizer 0;
  &:hover {
    text-decoration: none;
    background-color: $portfolio-trading-background-color-neutral;
  }
}

.bb-news-item__media {
  width: 164px;
}
