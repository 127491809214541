.bb-logo-ui-showcase {
  .bb-logo {
    .bb-logo-emblem {
      background-image: url("../../assets/images/components/logo-emblem.svg");
    }
  }
}

bb-avatar-ui-showcase {
  .bb-avatar__image {
    background-image: url("../../assets/images/components/avatar.jpg") !important;
  }
}

bb-collapsible-ui-showcase {
  .collapse {
    padding: 1rem 1rem 0;
  }
}

bb-collapsible-accordion-ui-showcase {
  bb-collapsible-card-ui {
    bb-collapsible-ui.card {
      border-radius: 0;
    }

    &:first-child {
      bb-collapsible-ui.card {
        border-top-right-radius: 1rem;
        border-top-left-radius: 1rem;
      }
    }

    &:last-child {
      bb-collapsible-ui.card {
        border-bottom-right-radius: 1rem;
        border-bottom-left-radius: 1rem;

        .card-header {
          border-radius: 0;
          border-bottom-right-radius: 1rem;
          border-bottom-left-radius: 1rem;
        }
      }
    }
  }
}

bb-dropdown-multi-select-ui-showcase {
  .bb-clear-items.btn-link {
    background: none;
    outline: 0;
  }
}

bb-currency-input-ui-showcase {
  bb-currency-input-ui {
    .currency-selector {
      width: 5.5rem;
    }
  }
}

bb-input-phone-ui-showcase {
  td {
    &:last-child {
      white-space: nowrap;
    }
  }
}

bb-input-radio-group-ui-showcase {
  section {
    &:first-child {
      margin-bottom: 1rem;
    }
  }

  .bb-input-radio-label {
    .text-support {
      margin-bottom: 0;
    }
  }
}

bb-input-range-ui-showcase {
  .form-group {
    &:last-child {
      .row {
        margin-top: 1.5rem;
        align-items: flex-end;
      }
    }
  }
}

bb-payment-card-ui-showcase {
  .bb-payment-card {
    background: linear-gradient(to right, #62b3e9, #0276c4);
    border-radius: 1rem;
    .bb-logo.bb-logo-emblem.bb-logo-inverse {
      background-image: url("../../assets/images/components/logo-emblem.svg");
    }
  }
}

bb-period-selector-ui-showcase {
  .bicon {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .current-period {
    white-space: nowrap;
  }
}

bb-product-item-basic-account-ui-showcase,
bb-stepper-ui-showcase {
  .showcase__section {
    margin-bottom: 1.5rem;

    > h4 {
      padding-bottom: 1rem;
    }
  }
}

bb-rich-text-editor-ui-showcase {
  .rich-text-editor-actionbar {
    .btn-link {
      padding-left: 0.5rem;
      padding-right: 0.5rem;

      @include media-breakpoint-down(md) {
        padding-left: 0.1rem;
        padding-right: 0.1rem;
      }
    }
  }

  .bb-rich-text-editor-ui {
    height: auto;
  }
}

bb-tooltip-ui-showcase {
  .bs-tooltip-left .arrow::before,
  .bs-tooltip-auto[x-placement^="left"] .arrow::before {
    left: -2px;
  }

  .bs-tooltip-right .arrow::before,
  .bs-tooltip-auto[x-placement^="right"] .arrow::before {
    right: 2px;
  }
}
