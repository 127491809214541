// Global annotations
////
/// @group widgets
/// @access private
////
$bb-wealth-shared-instrument-list_search-dropdown_background: $color-background-surface-1 !default;

.bb-wealth-shared-instrument__search {
  ngb-typeahead-window {
    width: 100%;
    transform: translate(0, 50px) !important;
    .dropdown-item.active {
      background-color: $bb-wealth-shared-instrument-list_search-dropdown_background;
    }
  }
}
