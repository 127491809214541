$divided-range-slider-thumb-diameter: $spacer-lg;
$divided-range-slider-thumb-radius: calc($divided-range-slider-thumb-diameter / 2);
$divided-range-slider-bottom-shift: calc($divided-range-slider-thumb-radius / 2);
$divided-range-slider-container-height: $spacer-xl + $spacer-md + $spacer-xs;
$divided-range-slider-container-width: calc(100% + #{$divided-range-slider-thumb-diameter});
$divided-range-slider-input-bottom-position: $divided-range-slider-thumb-radius;
$divided-range-slider-track-width: calc(100% - #{$divided-range-slider-thumb-diameter});
$divided-range-slider-track-height: $spacer-sm;
$divided-range-slider-label-width: $spacer-xl;
$divided-range-slider-label-height: $spacer;
$divided-range-slider-divider-width: map-get($border-widths, 2);
$divided-range-slider-top-position: $divided-range-slider-container-height -
  $divided-range-slider-track-height - $divided-range-slider-bottom-shift;
$divided-range-slider-thumb-extreme-shift: map-get($border-widths, 5) * 2;
$divided-range-slider-focus-border-width: calc(100% - #{$spacer-md});
$divided-range-slider-focus-border-height: calc(100% + #{$spacer-xs});
$divided-range-slider-track-zIndex: 1;
$divided-range-slider-used-track-zIndex: 2;

@mixin slider-thumb {
  cursor: pointer;
  width: $divided-range-slider-thumb-diameter;
  height: $divided-range-slider-thumb-diameter;
  border-radius: $border-radius;
  background-color: $color-on-background-brand-subtle;
}

@mixin disabled-slider-thumb {
  background: $color-background-disabled;
  cursor: not-allowed;
}

.bb-divided-range-slider__wrapper {
  height: $divided-range-slider-container-height;
  width: $divided-range-slider-container-width;
  inset-inline-start: -#{$divided-range-slider-thumb-radius};
}

.bb-divided-range-slider__wrapper--focused {
  &:before {
    content: "";
    position: absolute;
    width: $divided-range-slider-focus-border-width;
    height: $divided-range-slider-focus-border-height;
    border-radius: $border-radius-sm;
    outline: $form-focus-border-primary;
    outline-color: $color-focus-outline;
    box-shadow: none;
  }
}

.bb-divided-range-slider__input {
  height: 0;
  z-index: 3;
  outline: none;
  bottom: $divided-range-slider-input-bottom-position;
  -webkit-appearance: none;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
  }

  &::-webkit-slider-thumb {
    @include slider-thumb();
  }

  &::-moz-range-thumb {
    @include slider-thumb();
  }

  &:disabled {
    &::-webkit-slider-thumb {
      @include disabled-slider-thumb();
    }

    &::-moz-range-thumb {
      @include disabled-slider-thumb();
    }
  }
}

.bb-divided-range-slider__input--min-selected {
  &::-webkit-slider-thumb {
    transform: translateX($divided-range-slider-thumb-extreme-shift);
  }

  &::-moz-range-thumb {
    transform: translateX($divided-range-slider-thumb-extreme-shift);
  }

  &[dir="rtl"] {
    &::-webkit-slider-thumb {
      transform: translateX(calc(#{$divided-range-slider-thumb-extreme-shift} * -1));
    }

    &::-moz-range-thumb {
      transform: translateX(calc(#{$divided-range-slider-thumb-extreme-shift} * -1));
    }
  }
}

.bb-divided-range-slider__input--max-selected {
  &::-webkit-slider-thumb {
    transform: translateX(calc(#{$divided-range-slider-thumb-extreme-shift} * -1));
  }

  &::-moz-range-thumb {
    transform: translateX(calc(#{$divided-range-slider-thumb-extreme-shift} * -1));
  }

  &[dir="rtl"] {
    &::-webkit-slider-thumb {
      transform: translateX($divided-range-slider-thumb-extreme-shift);
    }

    &::-moz-range-thumb {
      transform: translateX($divided-range-slider-thumb-extreme-shift);
    }
  }
}

.bb-divided-range-slider__track,
.bb-divided-range-slider__used-track {
  height: $divided-range-slider-track-height;
}

.bb-divided-range-slider__track {
  width: $divided-range-slider-track-width;
  bottom: $divided-range-slider-bottom-shift;
  z-index: $divided-range-slider-track-zIndex;
  background-color: $color-background-neutral-subtle;
}

.bb-divided-range-slider__used-track {
  z-index: $divided-range-slider-used-track-zIndex;
  bottom: 0;
  background-color: $color-background-brand;
}

.bb-divided-range-slider__track--disabled,
.bb-divided-range-slider__used-track--disabled {
  background-color: $color-background-disabled;
}

.bb-divided-range-slider__labels {
  width: 101%;

  .bb-divided-range-slider-label-item {
    width: $divided-range-slider-label-width;
    height: $divided-range-slider-label-height;

    &:not(:first-child):not(:last-child) {
      &:after {
        content: "";
        display: block;
        height: $divided-range-slider-track-height;
        width: $divided-range-slider-divider-width;
        background: $color-background-surface-1;
        position: absolute;
        top: $divided-range-slider-top-position;
        z-index: 2;
      }
    }

    &:first-child {
      .bb-divided-range-slider-label-item__text {
        inset-inline-start: 45%;
      }
    }

    &:last-child {
      .bb-divided-range-slider-label-item__text {
        inset-inline-end: 45%;
      }
    }
  }
}
