// Global annotations
////
/// @group structure
/// @access private
////

@import "bootstrap/scss/modal";
$modal-content-highlight-bg-color: $color-background-surface-2 !default;
$modal-body-padding-vertical: $sizer-md !default;
$modal-fullscreen-content-bg-color: $color-background-surface-2 !default;

// need for fully cover background on mobile resolution with wide body
.modal-backdrop {
  min-width: 100%;
  min-height: 100%;
}

.modal-dialog {
  @include media-breakpoint-down(sm) {
    margin: $modal-dialog-margin-y-sm-up $modal-dialog-margin;
  }
}

.modal-content {
  overflow: hidden;

  .modal-overflow & {
    overflow: visible;
  }
}

.modal-content--highlight {
  margin-right: -$sizer-xl;
  margin-left: -$sizer-xl;
  background-color: $modal-content-highlight-bg-color;
}

.modal-money-protection {
  padding: $sizer-lg;
  padding-inline: $sizer-md;

  border-bottom: $border-width $border-style $color-border-subtle;
}

.modal-header,
.modal-footer {
  padding: $sizer-md;
}

.modal-header {
  padding-bottom: 0;
  align-items: flex-start;
  justify-content: space-between;

  // override reboot styles
  h2 {
    margin-bottom: 0;
  }

  button {
    margin-inline: $spacer-xs;
  }

  .modal-header-btn-close {
    flex-shrink: 0;
  }
}

.modal-body {
  overflow-wrap: break-word;
  padding-block: $sizer-lg;
  padding-inline: $sizer-md;
}

.modal-footer {
  padding-top: 0;
}

.modal-dialog-scrollable bb-modal-body-ui {
  overflow: auto;
}

.modal-dialog-scrollable .modal-content {
  height: 100%;
}

.modal-content-container {
  display: flex;
  flex-direction: column;
  max-height: 100%;
}

@include media-breakpoint-up(sm) {
  .modal-header {
    padding-block: $sizer-md 0;
    padding-inline: $sizer-lg;
  }
  .modal-body {
    padding-inline: $sizer-lg;
  }
  .modal-footer {
    padding-inline: $sizer-lg;
    padding-block: 0 $sizer-md;
  }
}

@include media-breakpoint-up(md) {
  .modal-money-protection {
    padding-inline: $sizer-xl;
  }

  .modal-money-protection--full-screen {
    padding-block: $sizer-sm;
    background-color: $color-background-surface-1;
    box-shadow: $box-shadow-sm;
  }

  .modal-header,
  .modal-footer {
    padding: $sizer-xl;
  }
  .modal-header {
    padding-block-end: 0;
  }
  .modal-body {
    padding-inline: $sizer-xl;
  }
  .modal-footer {
    padding-block-start: 0;
  }
}

@media print {
  .modal-open {
    height: auto;
    visibility: hidden;

    .modal {
      .modal-header,
      .modal-body {
        visibility: visible;
      }
    }
  }
}

.modal-fullscreen {
  max-width: 100%;
  height: 100%;
  margin: 0;

  .modal-content {
    height: auto;
    min-height: 100%;
    background-color: $modal-fullscreen-content-bg-color;
    border-radius: unset;
  }

  .modal-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .modal-body {
    padding: 0;
  }
}
