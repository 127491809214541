// Global annotations
////
/// @group journeys
/// @access private
////

$cag-table-margin-block-end: 2 * $spacer !default;
$cag-infinite-scroll-container-margin-block: 0.5 * $spacer !default;
$cag-infinite-scroll-container-height: 43rem !default;
$cag-details-container-padding-inline: 2 * $sizer !default;
$cag-list-padding-inline: 0.75 * $sizer !default;
$cag-list-padding-block: $sizer !default;
$cag-list-background-color-hover: $color-background-overlay-hovered !default;
$cag-list-background-color-active: $color-background-selected !default;
$cag-name-font-weight: $font-weight-bold !default;
$cag-name-for-le-font-size: $font-size-base !default;
$cag-name-font-size: $font-size-lg !default;
$cag-name-line-height: $line-height-headings !default;

.bb-cag-list__item {
  padding-inline: $cag-list-padding-inline;
  padding-block: $cag-list-padding-block;
  cursor: pointer;

  &:hover {
    background-color: $cag-list-background-color-hover;
  }

  &.active {
    background-color: $cag-list-background-color-active;
    .bb-cag-list__item-name {
      width: 70%;
    }
  }

  .bb-cag-list__item-name {
    max-width: 85%;
  }
}

.bb-cag-name__legal-entity {
  width: 100%;
  .bb-cag-name__heading {
    font-weight: $cag-name-font-weight;
    font-size: $cag-name-for-le-font-size;
    line-height: $cag-name-line-height;
  }
}

.bb-cag-name__details {
  width: 85%;
  .bb-cag-name__heading {
    font-weight: $cag-name-font-weight;
    font-size: $cag-name-font-size;
    line-height: $cag-name-line-height;
  }
}

.bb-cag-table__container {
  margin-block-end: $cag-table-margin-block-end;
  .bb-cag-table__switch {
    width: 100%;
  }
}

.bb-cag-table__inner-content {
  padding-right: 0.5 * $sizer;
}

.bb-cag-validation-message {
  position: absolute;
}

.bb-cag-details_tab-container {
  padding-inline: $cag-details-container-padding-inline;
}

.bb-cag-infinite-scroll-container {
  margin-block: $cag-infinite-scroll-container-margin-block;
  height: $cag-infinite-scroll-container-height;
  overflow-y: scroll;
}
